import SubType from "../Models/SubType";

const crypto = window.crypto as any || {
  getRandomValues: (array: Array<any>) => {
    for (let i = 0, l = array.length; i < l; i++) {
      array[i] = Math.floor(Math.random() * 256);
    }
    return array;
  }
};

export function uuidV4() {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >> (c / 4)).toString(16)
  );
}

export function getType(type: string) {
  switch (SubType[type]) {
    case SubType.EMAIL:
      return "email";

    case SubType.PASSWORD:
      return "password";

    case SubType.NUMBER:
      return "number";

    case SubType.TIME:
      return "time";

    default:
      return "text"
  }
}

export function stopPropagation(event: any) {
  event.stopPropagation();
}

// export function preventDefault(event: any) {
//   event.preventDefault();
// }

export function megabyteToByte(value?: number) {
  return 1024 * 1024 * (value || 10);
}

export const checkMail = (mail: string) => {
  return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export function cleanValue(value: string) {
  return value.replace(" ", "")
    .replace("(", "")
    .replace(")", "")
    .replace("-", "")
}

export function checkValue(type: SubType, value: string) {
  switch (type) {
    case SubType.EMAIL:
      return !checkMail(value);

    case SubType.PASSWORD:
      return !(value.length >= 8);

    default:
      return !value;
  }
}
