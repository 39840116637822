import React, {CSSProperties} from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";

interface IProps {
  id: string,
  name: string,
  value: string,
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: string) => void
}

const PhoneComponent = (props: IProps) => {
  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    disabled
  } = props;

  return <NumberFormat id={id}
                       required={required}
                       name={name}
                       format="+57 ### #######"
                       style={style}
                       disabled={disabled}
                       className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder}
                       value={value}
                       allowNegative={false}
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(values.value) : null}
  />;
};

export default PhoneComponent;
