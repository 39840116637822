import * as React from "react";

interface props {
	className?: string;
	style?: any;
}

const SpinnerComponent = ({className, style}:props) => {
	return(
			<div className={className + ' spinner-border'} style={style}>
				<span className="sr-only">Loading...</span>
			</div>
	);
};

export default SpinnerComponent;
