import React, {CSSProperties} from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";

interface IProps {
  id: string,
  name: string,
  value: number,
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: number) => void
}

const CurrencyComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder
  } = props;

  return <NumberFormat id={id}
                       required={required}
                       name={name}
                       style={style}
                       className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder}
                       value={value}
                       allowNegative={false}
                       thousandSeparator="."
                       prefix="$"
                       decimalSeparator=","
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(parseInt(values.value)) : null}
  />;
};

export default CurrencyComponent;
