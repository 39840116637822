import IField from './IField';

export default class Form {
  id!: number;
  domain!: number;
  name!: string;
  fields: IField[] = [];
  label!: string;
}

