import React from 'react';
import Select, {OptionsType, ValueType} from "react-select";
import ValidateFieldRequired from "../Shared/ValidateFieldRequired";

interface IProps {
  id: string;
  required?: boolean;
  name: string;
  value: ValueType<any>;
  options: OptionsType<any>;
  invalid?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  placeholder?: string;
  className?: string;
  onChange?: (value: ValueType<any>) => void
}

const SelectComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    onChange,
    className,
    options,
    placeholder,
    required,
    disabled
  } = props;

  return <ValidateFieldRequired
    value={value}
    required={required}
    disabled={disabled}>
    <>
      <Select isMulti={false}
              classNamePrefix="react-select"
              placeholder={placeholder}
              className={className}
              id={id}
              isDisabled={disabled}
              name={name}
              value={value}
              onChange={(value: ValueType<any>) => onChange ? onChange(value) : null}
              options={options}
      />
    </>
  </ValidateFieldRequired>
};

export default SelectComponent;
