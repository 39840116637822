import mixpanel, {Callback, RequestOptions} from 'mixpanel-browser';
import env from "../config/environment";

mixpanel.init(env.mixPanelToken);

type Types = "Inicio de sesion" | "Registro de usuario" | string
type Response = RequestOptions | Callback;

const MixPanelActions = ({
  ...mixpanel,
  setAuthUser($name: string, $email: string, $role: string, id: string, $type: Types) {
    mixpanel.people.set({$name, $role, $email, $type});
    mixpanel.identify(id);
    mixpanel.track($type);
  },
  setDocument($name: string, callback?: Response) {
    mixpanel.track("Finalización del documento",
      {$name}, callback)
  },
  downloadDocument($name: string, callback?: Response) {
    mixpanel.track("Descarga del documento",
      {$name}, callback)
  },
  createDocument($name: string, callback?: Response) {
    mixpanel.track("Crear nuevo documento",
      {$name}, callback);
  },
  setErrors(message: string) {
    // mixpanel.register({"Error": message})
    mixpanel.track("Error catch => " + message, {$error: message})
  }
});

export default MixPanelActions;
