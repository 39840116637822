import { compose, createStore, applyMiddleware } from "redux";
import createSagaMiddleware from "redux-saga";
import authSaga from './Auth/Saga';
import documentSaga from './Document/Saga';
import categorySaga from './Category/Saga';
import FormExecutionSaga from './FormExecution/Saga';
import ProfileSaga from './Profile/Saga'
import TrackJSLogger from "../Store/Middleware/TrackJSLogger";
import RootReducer from './Reducers/';
const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sagaMiddleware = createSagaMiddleware();

const Store = createStore(RootReducer, composeEnhancers(applyMiddleware(sagaMiddleware, TrackJSLogger)));
sagaMiddleware.run(authSaga);
sagaMiddleware.run(documentSaga);
sagaMiddleware.run(categorySaga);
sagaMiddleware.run(FormExecutionSaga);
sagaMiddleware.run(ProfileSaga);
export default Store;
