import React, {useCallback, useEffect} from "react";

declare let document: Document;

const items = [
  {label: "General", id: "general"},
  {label: "Datos de usuario", id: "user_info"},
  {label: " Cambiar contraseña", id: "change_password"}
]

export default () => {

  const init = useCallback((e) => scrollToSection(e), []);

  useEffect(() => {
    init("general");
  }, [init])


  const scrollToSection = (id: string) => {
    const element = document.getElementById(id);
    const card = document.getElementById(id + "_card");
    if (element && card) {
      card.scrollIntoView({behavior: "smooth"});
      const array = document.getElementsByClassName("list-item-custom");
      for (let i = 0; i < array.length; i++) {
        array[i].className = array[i].className.replace("active-item", "")
      }
      element.className += " active-item"
    }
  }

  return (
    <div style={{zIndex: 10}} className="sticky-top">
      <ul className="list-group">
        {items.map((l, i) => {
          return (
            <li id={l.id} key={i} onClick={() => scrollToSection(l.id)}
                className="list-item-custom list-group-item pointer list-group-item-action">
              {l.label}
            </li>
          )
        })}
      </ul>
    </div>
  )
}
