import {Expose} from "class-transformer";

export class User {
  @Expose()
  name!: string;
  @Expose()
  email!: string;
  @Expose()
  last_name!: string;
}
