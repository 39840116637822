import React from "react";

const TermComponent = () => {
  return (
    <div className="container py-5">
      <h3><b>TÉRMINOS Y CONDICIONES</b></h3>
      <p className="text-justify">
        TÉRMINOS Y CONDICIONES Simple Legal S.A.S (en adelante “Simple Legal”) establece los siguientes “Términos y
        Condiciones” (en adelante “Términos”), los cuales rigen el uso que usted le dé a cualquiera de los contenidos y
        servicios (en adelante “productos”) disponibles a través de su sitio web <a target="blank"
        href="http://simplelegalapp.co">simplelegalapp.co</a> (en adelante
        “el Sitio o la Plataforma”).
      </p>

      <p className="text-justify">
        Le invitamos a leer detenidamente los Términos antes de acceder o adquirir los productos ofrecidos, toda vez
        que, se entenderán aceptados por usted mediante el uso o el acceso al Sitio y se obliga de acuerdo con lo
        establecido en su contenido. De lo contrario, si usted no está de acuerdo con estos Términos, no puede tener
        acceso al Sitio ni usarlo de ninguna otra manera.
      </p>

      <p className="text-justify">
        Simple Legal puede modificar estos Términos en cualquier momento, sin previa notificación, por lo cual se
        recomienda realizar constante verificación de su contenido, debido a que los mismos son obligatorios para Usted.
        Le avisaremos sobre cualquier cambio actualizando la fecha de "Última actualización" de estos Términos, por
        tanto es su responsabilidad revisarlos periódicamente.
      </p>

      <p className="text-justify">
        Al aceptar estos términos, usted declara que tiene capacidad jurídica y las facultades necesarias para obligarse
        de acuerdo con el contenido de los mismos.
      </p>

      <p className="text-justify">
        En caso de que solicite los Productos de Simple Legal a nombre de una persona jurídica o de un tercero, usted
        garantiza que cuenta con la autorización de dicha persona para hacerlo. En caso de no estar facultado, usted
        responderá personalmente por las obligaciones contraídas de acuerdo con los presentes Términos.
      </p>

      <h3><b>1. REGISTRO</b></h3>
      <p className="text-justify">
        Todos los visitantes pueden registrarse en la Plataforma con el fin de convertirse en usuarios. Se considera
        visitante todo aquel que navegue a través de la Plataforma, sin registrarse.
      </p>

      <p className="text-justify">
        Es obligación del Visitante diligenciar el formulario de inscripción en todos sus campos, con datos válidos,
        para poder considerarse Usuario y utilizar los servicios que presta El Sitio. En todo caso, se podrá acceder a
        algunos servicios ofrecidos por la Plataforma, sin que sea necesaria la inscripción.
      </p>

      <h3><b>1.1 Cuenta y contraseña</b></h3>
      <p className="text-justify">
        Para realizar el registro, usted podrá hacerlo proporcionando manualmente un correo electrónico y contraseña.
        Sin embargo, si usted tiene cuentas en las redes sociales Facebook y Google+, podrá crear su cuenta de usuario
        con la utilización de las credenciales allí definidas.
      </p>

      <p className="text-justify">
        El usuario es el único responsable de la confidencialidad y seguridad de su cuenta y contraseña.
      </p>

      <p className="text-justify">
        En caso de olvido de los datos de validación o de usurpación de éstos, el usuario acuerda notificar a Simple
        Legal, tan pronto tenga conocimiento del hecho a través de la opción “Olvidó su contraseña” o a través de
        comunicación enviada el correo electrónico <a
        href="mailto: soporte@simplelegalapp.com.">soporte@simplelegalapp.com.</a>
      </p>

      <h3><b>1.2 Perfil del usuario y contraseña</b></h3>
      <p className="text-justify">
        Los usuarios podrán, además de la información obligatoria requerida, bien sea correo electrónico, contraseña o
        la vinculación de su cuenta de Facebook o Google+, suministrar voluntariamente datos adicionales relacionados
        con su individualización al momento de crear su propio perfil dentro del sitio, seleccionando la opción “Editar
        perfil”.
      </p>

      <p className="text-justify">
        El Usuario se obliga a mantener la confidencialidad de su clave de seguridad, y a tomar las medidas de seguridad
        necesarias, para que no ocurra ningún incidente de seguridad respecto del manejo de su cuenta y la respectiva
        clave de seguridad.
      </p>

      <h3><b>1.3 Información del usuario</b></h3>

      <p className="text-justify">
        El usuario deberá completar el o los formularios pertinentes con su información personal de manera exacta,
        precisa y verdadera, y asume el compromiso de actualizar sus datos personales siempre que sea necesario. Para
        dichos efectos, y previo al recaudo de los datos personales, los Usuarios deberán leer y conocer la Política de
        tratamiento de datos personales (en adelante la “Política”) y autorizar el tratamiento de sus datos de
        conformidad con dicha Política.
      </p>

      <p className="text-justify">
        El usuario se compromete a garantizar la veracidad de la información facilitada para su registro el momento de
        realizarlo y durante el término de vigencia de su cuenta, y asume la plena responsabilidad de las consecuencias
        que se deriven directa o indirectamente de haber facilitado información errónea.
      </p>

      <h3><b>1.4 Uso de la cuenta</b></h3>
      <p className="text-justify">
        Al momento de convertirse en usuario, usted podrá tener acceso a todas las guías legales cargadas en la
        Plataforma e iniciar la generación de documentos, sin requerir una suscripción para realizar la descarga de los
        mismos. Durante la etapa de lanzamiento, el usuario podrá acceder de manera ilimitada al catálogo de documentos
        disponibles en el Sitio, sin embargo, a futuro este deberá realizar una suscripción a su elección.
      </p>

      <p className="text-justify">
        El uso de las cuentas es personal e intransferible, por lo cual los usuarios no se encuentran facultados para
        ceder los datos de validación para el acceso al sitio ni el uso de su cuenta a ningún tipo de terceros.
      </p>

      <p className="text-justify">
        Simple Legal se reserva el derecho a suprimir las cuentas de aquellos usuarios que incumplan lo anterior,
        especialmente si se facilita información errónea en el momento del registro.
      </p>

      <h3><b>1.5 Cancelación de la cuenta </b></h3>

      <p className="text-justify">
        El usuario, luego de crear su cuenta de acceso al sitio, podrá cancelar en cualquier momento la misma.
      </p>

      <h3><b>2. CONTENIDO DEL SITIO </b></h3>

      <p className="text-justify">
        El sitio tiene por finalidad brindar al usuario diversidad de información y documentación relacionada con temas
        del derecho laboral, civil, comercial, inmobiliario, societario, entre otros. En ningún caso esta información
        deberá considerarse como exhaustiva, completa o que de cualquier forma satisfaga todas las necesidades del
        usuario.
      </p>

      <h3><b>2.1 Categorías </b></h3>

      <p className="text-justify">La Plataforma se encuentra dividida en cinco categorías principales, que son:</p>

      <ul>
        <li>
          <p className="text-justify">
            <b>Laboral:</b> Vinculación y terminación laboral; Solicitudes de permisos, Licencias y Certificaciones al
            empleador; Sanciones disciplinarias y poderes.
          </p>
        </li>
        {/*---------*/}
        <li>
          <p className="text-justify">
            <b>Negocios:</b> Principales contratos y acuerdos para la celebración de actividades comerciales.
          </p>
        </li>
        {/*---------*/}
        <li>
          <p className="text-justify">
            <b>Sociedades:</b> Constitución, transformación, disolución y liquidación de sociedades; Reformas
            estatutarias;
            Actas de asamblea y junta directiva; Poderes muy útiles en el mundo societario.
          </p>
        </li>
        {/*---------*/}
        <li>
          <p className="text-justify">
            <b>Bienes raíces:</b> Celebración, terminación y modificación de contratos de arrendamiento y compraventa de
            bienes inmuebles; Poderes y Documentos relacionados con propiedad horizontal.
          </p>
        </li>
        {/*---------*/}
        <li>
          <p className="text-justify">
            <b>Peticiones:</b> Peticiones, quejas, reclamos y solicitudes ante E.P.S, entidades prestadores de servicios
            públicos, entidades financieras y educativas.
          </p>
        </li>
      </ul>

      <p className="text-justify">
        En cada una de las categorías se encontrará un listado de documentos relacionados con los temas que le
        conciernen. El Usuario será responsable de verificar la fecha de actualización de los formatos que se encuentren
        cargados en la Plataforma antes de descargar los mismos.
      </p>

      <h3><b>2.2 Productos </b></h3>

      <p className="text-justify">
        Se entiende por productos, todo el contenido y servicios ofrecidos a través de este sitio, los cuales son los
        siguientes:
      </p>

      <h3><b>2.2.1 Guías legales</b></h3>

      <p className="text-justify">
        En la Plataforma se encontrarán colgadas diversas guías legales sobre los temas de interés más comunes en el
        ámbito jurídico. A través de estas guías se busca exponer tópicos de las áreas manejadas por Simple Legal y a
        través de preguntas y respuestas ayudar a los visitantes a resolver interrogantes, orientarlos en el
        desenvolvimiento de cualquier actividad diaria que implique asesoría legal y estar al día en temas legales de la
        actualidad.
      </p>

      <p className="text-justify">
        Este producto se encuentra completamente abierto al público, por lo tanto, cualquier visitante de la Plataforma
        tendrá acceso a él de manera gratuita.
      </p>

      <h3><b>2.2.2 Creación de documentos</b></h3>

      <p className="text-justify">
        Los visitantes podrán generar diversos documentos relacionados con los temas ofrecidos en el contenido del sitio
        y ubicados en las diferentes categorías. Podrán crear desde un contrato de trabajo, un contrato de compraventa,
        una tutela, hasta estatutos de sociedades y derechos de petición ante entidades públicas o privadas.
      </p>

      <p className="text-justify"> La creación de sus documentos se realizará a través de preguntas/respuestas
        preestablecidas y podrán
        personalizarse de acuerdo a sus necesidades. Una vez finalizado el diligenciamiento de la información requerida,
        el documento estará disponible para ser descargado a condición de que el usuario se encuentre registrado.
      </p>

      <p className="text-justify">
        Los usuarios pueden guardar los documentos pre-generados en sus cuentas personales, y podrán descargarlos
        ilimitadamente durante el tiempo de 15 días desde la generación.
      </p>

      <p className="text-justify">
        Los miembros del equipo de Simple Legal diseñan productos que brindan un nivel de protección adecuado a los
        negocios adelantados por sus usuarios. Sin embargo, debido a las limitaciones que implica la prestación del
        servicio vía web y sin contacto personal con el abogado, Simple Legal no presta un servicio personalizado, usted
        es responsable del contenido final del Documento, su correcta adecuación al negocio que pretende regular, su
        legalidad y, en general, todas las características finales de los Documentos obtenidos por Usted a través del
        Sitio.
      </p>

      <h3><b>2.3 Links a otros sitios web</b></h3>

      <p className="text-justify">
        El sitio puede tener enlaces a otros links o vínculos de interés localizados en otras páginas web de propiedad
        de otras entidades, personas u organizaciones diferentes a Simple Legal, quien no asume responsabilidad alguna
        sobre su contenido. Estos links o vínculos se suministran exclusivamente para la conveniencia del usuario.
      </p>

      <p className="text-justify">
        Al momento que el usuario acceda a otro sitio web o a un documento individual localizado en otra página, a
        través de un link o un vínculo establecido en este sitio, lo hace bajo su propio riesgo y deberá someterse a las
        condiciones de uso y a la política de privacidad de la página web a la que envía el link.
      </p>

      <p className="text-justify">
        La información que el usuario intercambie con estos terceros, sus acuerdos o negocios con los mismos, son de
        exclusiva responsabilidad del usuario.
      </p>

      <p className="text-justify">
        Simple Legal no será responsable de cualquier pérdida o daño en que el usuario incurra como resultado de su
        relación con tales terceros.
      </p>

      <h3><b>3. ETAPA DE LANZAMIENTO</b></h3>

      <p className="text-justify">
        Durante la etapa de lanzamiento del Sitio, los usuarios podrán tener acceso a todos de los documentos
        disponibles en todas las categorías que maneja la Plataforma, sin necesidad de suscribirse a un plan. Por tanto,
        para descargar el documento, no deberán realizar ningún pago.
      </p>

      <p className="text-justify">
        Los usuarios tendrán derecho a que su documento finalizado permanezca en la Plataforma por el término de 15 días
        a partir de su generación, pasado dicho tiempo, el documento será eliminado de su cuenta.
      </p>

      <p className="text-justify">
        Una vez culmine la etapa de lanzamiento, Simple legal contará con un sinnúmero de Planes a efectos de acceder de
        manera ilimitada a los productos ofrecidos por este Sitio al momento de su lanzamiento y nuevos productos que
        serán presentados al público, proporcionando un método válido de pago.
      </p>

      <h3><b>4. USO DEL SITIO WEB</b></h3>

      <p className="text-justify">
        El acceso a este sitio significa que usted ha aceptado que el uso que hará del contenido del mismo tendrá
        propósitos legítimos y legales.
      </p>

      <p className="text-justify">
        Simple Legal autoriza a los usuarios a consultar, revisar y usar el material que se encuentra en este sitio,
        exclusivamente para su uso personal y no comercial.
      </p>

      <h3><b>4.1 Usos prohibidos</b></h3>

      <p className="text-justify">
        Se prohíbe a los usuarios el uso del sitio con el fin de:
      </p>

      <ul>
        <li>
          <p className="text-justify">
            Distribuir su contenido con fines comerciales, de tal manera que viole las leyes sobre derechos de autor,
            propiedad industrial o cualquier otro derecho de propiedad intelectual.
          </p>
        </li>
        {/*-------*/}
        <li>
          <p className="text-justify">
            Eliminar su contenido sin la debida autorización.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Acceder a información que no esté dirigida o autorizada a dicho usuario o acceder a servidores o cuentas a
            los cuales no está autorizado su acceso.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Utilizar la identidad o la información personal de personas (naturales o jurídicas) mencionadas en él,
            para cualquier propósito o finalidad.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Intentar probar la vulnerabilidad de su sistema o red, sin la debida autorización o violando las medidas
            de seguridad o autenticación.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Usar cualquier medio para irrumpir o intentar irrumpir en su funcionamiento adecuado.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Intentar descompilar cualquier software que, de cualquier manera, se encuentre comprendido o haga parte de
            él.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Difundir información no autorizada, a través de cualquier medio físico o electrónico, en el que se
            comuniquen promociones o publicidad de sus productos, bien sea verdadera o falsa.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Incluir en él información falsa, incompleta, engañosa.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Violar cualquier sistema o red de seguridad..
          </p>
        </li>
        <li>
          <p className="text-justify">
            Infringir, de cualquier manera, los derechos de propiedad intelectual de Simple Legal.
          </p>
        </li>
        <li>
          <p className="text-justify">
            • Violar o incumplir las normas de privacidad y protección de datos.
          </p>
        </li>
      </ul>

      <p className="text-justify">
        Simple Legal investigará la ocurrencia de hechos que pueden constituir violaciones a lo anterior y cooperará con
        cualquier autoridad competente que lo requiera.
      </p>

      <h3><b>4.2 Responsabilidad por el uso del sitio</b></h3>

      <p className="text-justify">
        Simple Legal ha utilizado todos los medios a su alcance para asegurar que todo el contenido y la información
        disponible en su sitio sean correctos, sin embargo, no es posible garantizar que toda la información en el sitio
        se encuentre completamente actualizada.
      </p>

      <p className="text-justify">
        Lo anterior se debe a la naturaleza cambiante de las fuentes utilizadas para crear los contenidos, al tratarse
        de asuntos de interés jurídico basados en un ordenamiento jurídico cambiante, por lo que la información puede no
        estar totalmente actualizada o encontrarse incompleta en un momento determinado.
      </p>

      <p className="text-justify">
        En ese sentido, el sitio y su contenido se ponen a disposición de los usuarios en el estado en que se
        encuentren. Simple Legal no garantiza que el sitio o cualquier contenido disponible en él, funcionarán
        ininterrumpidamente o libre de errores, ni que los errores o defectos serán corregidos, ni que se encuentren
        libres de virus u otros mecanismos dañinos.
      </p>

      <h3><b>4.2.1 Responsabilidad del usuario </b></h3>

      <p className="text-justify">
        El acceso y uso del sitio, de cualquier producto ofrecido, al igual que las decisiones que el usuario adopte con
        base en la información contenida a través de él, se hacen bajo su propio y exclusivo riesgo. El usuario será el
        único responsable de los daños que se puedan ocasionar a los sistemas de información y comunicación que utilice
        para acceder al mismo, incluyendo los daños por virus informático.
      </p>

      <h3><b>4.2.2 Exoneración de responsabilidad de Simple Legal </b></h3>

      <p className="text-justify">
        En ningún caso Simple Legal será responsable por daños de cualquier naturaleza, resultantes del uso o la
        imposibilidad de usar el sitio o los productos ofrecidos en el mismo.
      </p>

      <p className="text-justify">
        Si del uso del sitio resulta la necesidad para el usuario de contratar un servicio de reparación o mantenimiento
        a sus equipos o información o de reemplazar sus equipos o información, Simple Legal no será responsable por los
        costos que ello implique.
      </p>

      <p className="text-justify">
        Simple Legal tampoco responderá en caso de fallas temporales, retrasos o intermitencias en el servicio de la
        página web, ni garantiza que la plataforma de tecnología será compatible con todos los ordenadores y tecnologías
        de telefonía móvil o dispositivos. Tampoco es responsable por los servicios de terceros a través de los cuales
        ponga a disposición del público sus servicios, como Google Inc., Microsoft Corporation, Apple Inc., Blackberry
        Limited o similares.
      </p>

      <p className="text-justify">
        No responderá, tampoco, por el uso ilegal o indebido que usuarios o terceras personas hagan de los productos
        adquiridos y del sitio, ni por la información o publicaciones que hagan terceros sin autorización de Simple
        Legal.No responderá, tampoco, por el uso ilegal o indebido que usuarios o terceras personas hagan de los
        productos adquiridos y del sitio, ni por la información o publicaciones que hagan terceros sin autorización de
        Simple Legal.
      </p>

      <h3><b>5. PROPIEDAD INTELECTUAL</b></h3>

      <p className="text-justify">
        Este sitio es propiedad de Simple Legal, quien autoriza a los usuarios a consultar, revisar y usar el material
        que se encuentra en este sitio, exclusivamente para su uso personal y no comercial. Su contenido está protegido
        por las leyes de derechos de autor, leyes de propiedad industrial y otras leyes aplicables.
      </p>

      <p className="text-justify">
        Simple Legal es el único propietario de los derechos de autor, conexos, marcas, patentes, diseños industriales y
        demás derechos derivados de la propiedad intelectual que se genere en el uso y operación del Sitio.
      </p>

      <p className="text-justify">
        En consecuencia, el usuario se compromete a no vender ni modificar el material de ninguna manera, ni
        distribuirlo para propósitos comerciales, tampoco podrá licenciar, copiar, publicar, exhibir, transmitir, o de
        otro modo utilizar los materiales en forma distinta a la autorizada expresamente en los Términos, so pena de
        indemnizar por los daños acaecidos a Simple Legal.
      </p>

      <p className="text-justify">
        El uso no autorizado del material puede constituir violación de las leyes colombianas o extranjeras sobre
        derechos de autor, leyes de propiedad industrial u otras leyes.
      </p>

      <h3><b>6. TRATAMIENTO DE DATOS PERSONALES</b></h3>

      <p className="text-justify">
        Con la aceptación de los presentes Términos, Simple Legal queda autorizado para la recopilación, uso y
        tratamiento de los datos personales contenidos en él y aquellos que se llegaren a suministrar o recopilar en el
        futuro, para las finalidades y en los términos descritos en la Política.
      </p>

      <h3><b>9. LEGISLACIÓN APLICABLE </b></h3>

      <p className="text-justify">
        Al presente documento de Términos y Condiciones, se le hará aplicable las leyes de la República de Colombia.
      </p>

    </div>
  )
}

export default TermComponent;
