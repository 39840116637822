import {sbxCoreService, sbxSessionService} from '../Network';
import env from '../config/environment';
import history from "../Utils/History";

export const validateToken = function (token = localStorage.getItem('token') || '',) {
  return sbxCoreService.run(env.cloudScripts.validateToken, {token});
};

export function loginService(user: string, password: string): Promise<any> {
  return sbxCoreService.run(env.cloudScripts.login, {user, password}).then(res => {
    return res;
  });
}

export function signUpService(name: string, email: string, password: string, user: string): Promise<any> {
  return sbxCoreService.run(env.cloudScripts.signUp, {user, password, email, name, role: env.role});
}

export function signUpTheGuestService(name: string, email: string, password: string, token: string, key: string): Promise<any> {
  return sbxCoreService.run(env.cloudScripts.signUpNextToGuest, {
    password,
    email,
    name,
    last_name: "",
    token,
    _KEY: key
  });
}

export function clearStorage(config?: { reload?: boolean, pathHistory?: string, clearAll?: boolean }) {
  if (config?.clearAll) {
    localStorage.clear();
  } else {
    localStorage.setItem("token", "");
    localStorage.setItem("access_token", "");
  }
  sessionStorage.clear();
  sbxSessionService.logout();
  if (config?.pathHistory) {
    history.push(`/${config.pathHistory}`);
  }
  if (config?.reload) {
    window.location.reload();
  }

}

export function forgotPassword(email: string) {
  return sbxCoreService.sendPasswordRequest(email, 'Recuperar la contraseña', env.templates.forgotPassword);
}

export function recoverService(id: string, code: string, password: string) {
  return sbxCoreService.requestChangePassword(id, code, password);
}

export async function restorePasswordService(user_id: string, code: string, password: string, email: string): Promise<any> {
  const validate = await loginService(email, password);
  if (!validate.success) {
    const res = await recoverService(user_id, code, password);
    if (res.success) {
      return res;
    } else if (res.error) {
      return res;
    }
  } else if (validate.success) {
    return {success: false, error: 'Estas contraseña es antigua'};
  }
  return {success: false, error: 'No se  pudo cambiar la contraseña'};
}

export async function loginAsGuestService() {
  const res = await sbxCoreService.run(env.cloudScripts.loginAsGuest, {});
  if (res.success) {
    return res
  } else if (res.error) {
    return res;
  } else {
    return {success: false, error: "No se puede acceder en este momento."}
  }
}

