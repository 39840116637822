import {get, httpProcess, sbxCoreService} from '../Network';
import env from '../config/environment';
import Section from "../Models/Section";
import IField from "../Models/IField";
import {uploadFile} from "./FolderService";
import {UserSignature} from "../Models/UserSignature";
import Document from "../Models/Document";
import {DocumentUser} from "../Models/DocumentUser";
import {getProcessModel} from "../Execution/Services/ProcessModelService";
import Axios from 'axios';
import {capitalize, removeNullAndUndefinedFromName} from "../Utils";

export const getDocumentPreview = (documentKey: string) => {
  return sbxCoreService.run('36d38f65-aeec-4ad4-8736-6f85a901e670', {doc: documentKey})
};

export const getDocuments = async (docSearch: string) => {
  const res = await sbxSearchKeys(docSearch, "document");
  return res.results.length ? sbxCoreService.run('89b98981-906e-4b43-8b1a-8c9a37aca7f7', {
    keys: res.results,
  }) : ({success: true, items: []});
};

export const getDocumentSections = async (key: string) => {
  return sbxCoreService.with('section')
    .andWhereIsEqualTo('document', key)
    .fetchModels(['document'])
    .find()
    .catch(err => console.log(err));
};

export const getSortDocumentService = async (key: string): Promise<any> => {
  const resDoc = await getDocumentSections(key);
  if (resDoc.success) {
    const doc: Document = resDoc.fetched_results.document[key];
    if (doc) {
      const resProcess = await getProcessModel(doc.workflow_id);
      if (resProcess.success) {
        const {item: {sequences, events}} = resProcess;
        return Promise.resolve({
          success: true,
          results: sortSectionBySequences(sequences, events, resDoc.results)
        });
      }
    }
  }
  return Promise.resolve({success: false, results: []})
};


function sortSectionBySequences(sequences: any[], events: any[], array: Section[] | any[]) {

  const startEvent = events.find((e: any) => e.event_type === "START");
  const startSequence: any = sequences
    .find((e: any) => e.from_item_id === startEvent.id);

  const getSeq = (id: number) => sequences
    .find(s => s.from_item_id === id && s.name !== "BACK");

  let newList: Section[] | any[] = [];

  function setDataToList(seq: any) {
    if (newList.length !== array.length) {
      const item = array
        .find((s: Section | any) => (s.task_id === seq.to_item_id || s.id === seq.to_item_id));
      if (item) {
        newList.push(item);
        setDataToList(getSeq(seq.to_item_id))
      }
    }
  }

  setDataToList(startSequence);
  return newList;
}


export const getSection = async (key: string) => {
  return sbxCoreService.with('section')
    .whereWithKeys([key])
    .fetchModels(['document'])
    .find()
    .then(res => res)
    .catch(err => console.log(err));
}


export const getDocumentsByCategory = () => {
  return sbxCoreService.with('section')
    .fetchModels(['document.category'])
    .find()
    .then(res => ({...res, results: Object.values(res.fetched_results.document)}))
    .catch(err => err);
}

export const getCategoryDocs = (page: number, size: number | "all", keys: string[], search: string, documentsKeys: Array<string> | null) => {
  return sbxCoreService.run(env.cloudScripts.docsByCategories, {page, size, keys, search, documentsKeys})
};


export async function setOptionsToFieldService(fields: IField[]) {
  return await Promise.all(fields.map(async (f) => {
    if (!f.sub_type)
      f.sub_type = undefined;
    const {item: {options}}: any = (f.list_provider_id && await getOptionsByIdService(f.list_provider_id)) || {item: {options: []}}
    f.options = options.map((o: any) => ({label: o.label, value: o.value, data: o}));
    return f
  }));
}

export const getForm = async (formId: string | number) => {
  const res = await get(`/api/v2/${env.domain}/wf/form/${formId}`);
  if (res && res.success) {
    res.item.fields = await setOptionsToFieldService(res.item.fields);
    for await (let field of res.item.fields) {
      if (field.detail_form_id) {
        const result = await getForm(field.detail_form_id);
        if (result && result.success) {
          field.detail_form = result.item;
        }
      }
    }
    return Promise.resolve({success: true, item: res.item});
  }
  return Promise.reject();
}


export function getOptionsByIdService(id: string) {
  return get(`/api/v2/${env.domain}/wf/dl/${id}/options`)
    .then(res => {
      if (!res.success) return {success: false, item: {options: []}}
      return res;
    })
    .catch(res => {
    res.success = false;
    res.item = {options: []};
    return res
  })
}

export function getPropertiesService(process_id: number, execution_id: number) {
  return httpProcess(`${env.domain}/wf/process/${process_id}/execution/${execution_id}`).then(e => e.data);
}

export function sbxSearchKeys(search: string, model: "document" | string): Promise<{ success: boolean, results: Array<string> }> {
  return httpProcess.get(`${env.sbxSearch}/api/search/v1.5/${model}@${env.domain}/${search || ""}`)
    .then((res: any) => {
      if (res && res.data) {
        return res.data;
      } else {
        return {success: true, results: []}
      }
    });
}


export function getPreview(processId: number, executionId: number, documentKey: string) {
  return sbxCoreService.run(env.cloudScripts.getPreview, {
    processId,
    executionId,
    key: documentKey,
    access_token: localStorage.getItem("access_token")
  })
}

export async function createDocumentUser(data: DocumentUser): Promise<{ success: boolean }> {
  const res = await sbxCoreService.insert("document_user", data);
  if (res.success) {
    return Promise.resolve(res);
  } else {
    return Promise.resolve({success: false});
  }
}

export async function incrementDownload(key: string, increment: number): Promise<{ success: boolean }> {
  const docRes = await sbxCoreService.with("document").whereWithKeys([key]).find();
  if (docRes.success) {
    let document = docRes.results[0];
    delete document._META;
    document.downloads += increment;
    const res = await sbxCoreService.update("document", document);
    if (!res.success) {
      return {success: false}
    }
  }
  return {success: true}
}

export async function verifyDocumentUser(workflowId: string, email: string): Promise<{ success: boolean, item: any }> {
  const res = await sbxCoreService.run(env.cloudScripts.userSignature, {
    "workflow_id": workflowId,
    "email": email
  })
  if (res.success) {
    return Promise.resolve(res)
  }
  return Promise.resolve({success: false, item: null})
}

export async function getDocumentUsersByUser(user_key: string, page: number, pageSize: number, folder_key: string) {
  return sbxCoreService.run(env.cloudScripts.docsPagination, {user_key, page, page_size: pageSize, folder_key})
}

export function setUserSignature(data: UserSignature | UserSignature[]) {
  return sbxCoreService.insert("user_signature", data);
}

export function updateUserSignature(data: UserSignature | UserSignature[]) {
  return sbxCoreService.update("user_signature", data);
}

export function updateDocumentUser(data: DocumentUser) {
  return sbxCoreService.update("document_user", data);
}

export function verifyStateDocumentUser(document_user_key: string) {
  return sbxCoreService.with("user_signature")
    .andWhereIsEqualTo("document_user", document_user_key)
    .find()
}

export async function finishAndSaveDocService(
  userSignature: UserSignature,
  documentUser: DocumentUser,
  file: File,
  folder_key: string,
  remaining: number,
  signature: File,
  users: UserSignature[]
):
  Promise<{ success: boolean }> {
  const resUpdateFile = await uploadFile(folder_key, file);
  const resSignature = await uploadFile(env.folders.signature, signature)
  if (resUpdateFile.success && resSignature) {
    const resSig = await updateUserSignature({...userSignature, state: true, image_key: resSignature.item.key_id});
    const stateDoc = !remaining;
    const resDoc = await updateDocumentUser({
      ...documentUser, state: stateDoc, document_key: resUpdateFile.item.key_id
    });

    const nameDocument = `${file.name.split("_").shift()}`;

    if (resSig.success && resDoc.success) {
      if (stateDoc) {
        for await (let u of users) {
          await sendEmailToDocumentFinished({
            to: u.email,
            key: resUpdateFile.item.key_id,
            nameDocument
          })
        }

      }
      return Promise.resolve({success: true});
    }
  }
  return Promise.resolve({success: false})
}


export function convertStringToPdfService(text: string) {
  return Axios.post(`${env.baseUrl}/api/v1/generate/pdf`,
    {
      html: text
    },
    {responseType: "blob"})
    .then(res => res.data)
}


export function sendEmailService(data: {
  to: string | string[],
  subject: string,
  template_key: string,
  data: any
}) {
  return sbxCoreService.run(env.cloudScripts.sendEmail, data);
}

export function sendEmailToDocumentFinished(data: { key: string, to: string, nameDocument: string }) {
  return sendEmailService({
    subject: `Documento ${capitalize(data.nameDocument)} finalizado`,
    template_key: env.templates.document_finished,
    to: data.to,
    data: {
      link: `${env.baseUrl}/#/download/${data.key}`,
      description: "Todas las partes han firmado el documento."
    }
  })
}

export function sendEmailToUserSignature(
  {
    to,
    name,
    documentName,
    workflowId,
    currentEmail,
    description,
    note,
  }: {
    to: string,
    name: string,
    documentName: string,
    workflowId: number,
    currentEmail: string,
    description?: string,
    note?: string
  }) {
  return sendEmailService({
    subject: `${removeNullAndUndefinedFromName(capitalize(name))} requiere tu firma en el documento ${capitalize(documentName)}`,
    data: {
      name: removeNullAndUndefinedFromName(capitalize(name)),
      email: currentEmail,
      description: description || "",
      note: note || "",
      link: `${env.baseUrl}/#/document/detail/${workflowId}/${to}`
    },
    template_key: env.templates.signature,
    to
  })
}

