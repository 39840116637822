import React, {useState} from 'react';
import {
  CheckboxComponent,
  CurrencyComponent,
  DateComponent,
  DateRangeComponent,
  DocumentComponent,
  EmailComponent,
  FormGroupComponent,
  LabelComponent,
  LargeTextComponent,
  MultiSelectComponent,
  NumberComponent,
  PasswordComponent,
  PhoneComponent,
  RadioButtonComponent,
  SelectComponent,
  SmallTextComponent,
  TableComponent,
  TimeComponent
} from 'sbx-ui-input-library';


import {useSelector} from 'react-redux';
import {InfoFieldPop} from "../../Shared/PopoversComponent";
import IField from "../../Models/IField";
import {FieldType} from "../../Models/FieldTypes";
import {filterFields, mobileCheck, scrollToView} from "../../Utils";
import {RootState} from "../../Store/Reducers";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEdit, faTrash} from "@fortawesome/free-solid-svg-icons";

type Props = {
  valuesFields: { [key: string]: any };
  formFields: Array<IField>;
  getValues?: (data: any) => void;
};

const CaseInput = ({
                     type,
                     field,
                     formValues,
                     setFormValues,
                     onChangeCurrentRow,
                     getCurrentRow,
                     header, valueNewRow
                   }: {
  type: string;
  field: IField;
  setFormValues: (data: any) => void;
  formValues: any;
  onChangeCurrentRow?: (value: any) => void;
  getCurrentRow?: (row: string) => void;
  valueNewRow: any;
  header?: any[]
}) => {
  const hint = field.hint;
  const fields = field.detail_form?.fields.map(f => ({
    ...f,
    startDatePlaceholderText: "Fecha inicial",
    endDatePlaceholderText: "Fecha final",
    labelProps: {
      icon: f.hint ? <InfoFieldPop
        className="text-dark"
        text={<small>{f.hint}</small>}
      /> : <></>
    }
  })) || [];

  switch (type) {
    case FieldType.smallText:
      return subtypeSmallText({field, setFormValues, formValues});
    case FieldType.largeText:
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <LargeTextComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      );
    case FieldType.date:
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <DateComponent
            orientation={mobileCheck() ? "vertical" : "horizontal"}
            id={`date_${field.id.toString()}`}
            required={field.required}
            isOutsideRange={() => false}
            onFocusedChange={e => {
              if (e) {
                scrollToView(field.id.toString());
              }
            }}
            value={formValues[field.id] || null}
            onChange={(e: any) => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      );

    case FieldType.dateRange:
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <DateRangeComponent
            id={`range_${field.id.toString()}`}
            orientation={mobileCheck() ? "vertical" : "horizontal"}
            required={field.required}
            isOutsideRange={() => false}
            placeholderEnd="Fecha final"
            placeholderStart="Fecha inicial"
            onFocusedChange={e => {
              if (e) {
                scrollToView("start_date_id_" + field.id);
              }
            }}
            value={formValues[field.id] || {startDate: null, endDate: null}}
            onChange={(e: any) => setFormValues({...formValues, [field.id]: e})}
          />
        </>
      );


    case FieldType.table:
      const subType = field.sub_type ? "LIST" : null;
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <TableComponent id={field.id + ''}
                          subType={subType}
                          valueNewRow={valueNewRow}
                          onChangeCurrentRow={onChangeCurrentRow}
                          header={header}
                          iconEdit={<FontAwesomeIcon className="text-white" icon={faEdit}/>}
                          iconDelete={<FontAwesomeIcon className="text-white" icon={faTrash}/>}
                          labelButtonAddNew={"Agregar"}
                          labelRowList={"Encabezado"}
                          labelButtonSave={"Guardar"}
                          getCurrentRow={getCurrentRow}
                          required={field.required}
                          format_rules_definition={field.format_rules_definition || undefined}
                          name={(field.name ? field.name : field.id) + ''}
                          value={formValues[field.id] || {}}
                          fields={fields.map((field: IField) => {
                            switch (field.field_type) {
                              case FieldType.date:
                              case FieldType.dateRange:
                                field.isOutsideRange = () => false;
                                break;
                            }
                            return field;
                          })}
                          onChange={e => setFormValues({...formValues, [field.id]: e})}/>
        </>
      );

    case FieldType.formGroup:

      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <FormGroupComponent
            count={field.size}
            onChange={(e: any) => setFormValues({...formValues, [field.id]: e})}
            fields={fields.map((field: IField) => {
              switch (field.field_type) {
                case FieldType.date:
                case FieldType.dateRange:
                  field.isOutsideRange = () => false;
                  break;
              }
              return field;
            })}
            value={formValues[field.id] || {}}/>
        </>

      )

    case FieldType.select:
      const OptionComponent: any = field.single_value ? SelectComponent : MultiSelectComponent;
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <OptionComponent
            id={field.id + ''}
            required={field.required}
            name={(field.name ? field.name : field.id) + ''}
            value={formValues[field.id] || null}
            onChange={(e: any) => setFormValues({...formValues, [field.id]: e})}
            options={field.options}
          />
        </>
      )

    case FieldType.toggle:
      const ToggleComponent: any = field.single_value ? RadioButtonComponent : CheckboxComponent;
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              hint ? <InfoFieldPop
                className="text-dark"
                text={<small>{hint}</small>}
              /> : <></>
            }
          />
          <ToggleComponent id={field.id + ''}
                           name={(field.name ? field.name : field.id) + ''}
                           value={formValues[field.id] || null}
                           onChange={(e: any) => setFormValues({...formValues, [field.id]: e})}
                           options={field.options}/>
        </>
      )

    default:
      return null;
  }
};

const subtypeSmallText = ({
                            field,
                            formValues,
                            setFormValues,
                          }: {
  field: IField;
  setFormValues: (data: any) => void;
  formValues: any;
}) => {
  switch (field.sub_type) {
    case 'DOCUMENT':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <DocumentComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      );
    case 'NUMBER':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <NumberComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      );
    case 'CURRENCY':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <CurrencyComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      )
    case 'EMAIL':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <EmailComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      )
    case 'PASSWORD':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <PasswordComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      )
    case 'PHONE':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <PhoneComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      )
    case 'TIME':
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : (
                <></>
              )
            }
          />
          <TimeComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      )
    default:
      return (
        <>
          <LabelComponent
            className="section-inputs-label"
            children={field.label}
            icon={
              field.hint ? (
                <InfoFieldPop
                  className="text-dark"
                  text={<small>{field.hint}</small>}
                />
              ) : null
            }
          />
          <SmallTextComponent
            id={field.id + ''}
            name={(field.name ? field.name : field.id) + ''}
            required={field.required}
            value={formValues[field.id] || ""}
            onChange={e => setFormValues({...formValues, [field.id]: e})}
            placeholder={field.placeholder}
          />
        </>
      );
  }
};

const FieldsComponent: React.FC<Props> = ({
                                            formFields,
                                            getValues,
                                            valuesFields,
                                          }) => {
  const {
    formExecutionReducer: {properties},
  } = useSelector((state1: RootState) => state1);

  const [formValues, setFormValues] = useState<any>({});
  const [rowValue, setRowValue] = useState<any>({});
  const [currentRow, setCurrentRow] = useState<any>({});
  const [valueCurrentRow, setValueCurrentRow] = useState({});
  const getType = (field: IField) => {
    switch (field.field_type) {
      case FieldType.options:
        return field.sub_type;

      default:
        return field.field_type;
    }
  };

  function NIField(field: IField, nValues: any, rv: any) {
    let detail_form = field.detail_form;
    if (detail_form?.fields.length) {
      let val: any = {};
      detail_form.fields.forEach(f => {
        switch (field.field_type) {
          case "TABLE":
            if (rv[field.id] && rv[field.id][currentRow[field.id]] && rv[field.id][currentRow[field.id]][f.id + ""]) {
              val[`${f.id}`] = {value: rv[field.id][currentRow[field.id]][f.id + ""].value, field: f};
            }
            break;
          default:
            if (nValues[`${field.id}`]?.value && nValues[`${field.id}`].value[`${f.id}`]) {
              val[`${f.id}`] = {value: nValues[`${field.id}`].value[`${f.id}`], field: f}
            }
            break;
        }
      });

      const newDetail_form = {
        ...detail_form,
        fields: filterFields(detail_form.fields, val, properties)
      }
      return {...field, detail_form: newDetail_form}
    }
    return {...field, detail_form}
  }

  function validateValues(field: IField) {
    const cr = currentRow[field.id];
    const rv = rowValue[field.id]
    if (cr && rv && rv[cr] && field.detail_form) {
      const deleteFields = Object.keys(rv[cr])
        .filter(e => !field.detail_form?.fields
          .find(f => f.id.toString() === e));

      const _nov = Object.assign({}, rv);

      if (deleteFields.length) {
        deleteFields.forEach(e => {
          delete _nov[cr][e];
        });
        const _t = Object.keys(_nov[cr])
          .reduce((e: any, a) => {
            e[a] = _nov[cr][a].value;
            return e;
          }, {})
        setValueCurrentRow(_t || {});
      }
    }
  }


  return (
    <>
      {filterFields(formFields, valuesFields, properties).map(
        (field: IField) => {
          const newField = NIField(field, valuesFields, rowValue);
          validateValues(newField);
          return (
            <div className="form-group" key={field.id}>
              <CaseInput
                valueNewRow={valueCurrentRow}
                onChangeCurrentRow={e => {
                  const cvr = rowValue[newField.id] ? rowValue[newField.id][currentRow[newField.id]] : {}
                  setRowValue({
                    ...rowValue,
                    [newField.id]: {
                      ...rowValue[newField.id],
                      [currentRow[newField.id]]: {
                        ...cvr,
                        [e.field.id]: e
                      }
                    }
                  })
                }
                }
                getCurrentRow={e => setCurrentRow((e2: any) => ({...e2, [field.id]: e}))}
                type={getType(field) || ''}
                field={newField}
                header={field.detail_form?.fields}
                formValues={formValues}
                setFormValues={e => {
                  setFormValues(e);
                  getValues &&
                  getValues({[field.id]: {value: e[field.id], field}});
                }}
              />
            </div>
          )
        }
      )}
    </>
  );
};

export default FieldsComponent;
