import React from 'react';

type Props = {
  icon: string;
  classname?: string;
}

const IconComponent: React.FC<Props> =({icon, classname}) =>{
  return <span className={classname}><img src={icon} alt=""/></span>
};
export default IconComponent;

