import React, {useRef} from "react";
import {Button} from "reactstrap";
import SignatureCanvas from "react-signature-canvas";

const SignatureComponent = ({getImage}: {
  getImage: (img: string) => void
}) => {

  const refCanvas = useRef<any>(null);

  function clearSignature() {
    if (refCanvas.current) {
      refCanvas.current.clear();
    }
  }

  function addSignatureCreated() {
    if (refCanvas.current) {
      const url = refCanvas.current.getTrimmedCanvas().toDataURL('image/png');
      getImage(url);
    }
  }

  return (
    <div>
      <SignatureCanvas penColor='black'
                       ref={refCanvas}
                       canvasProps={{className: 'sigCanvas'}}/>

      <div>
        <Button color="light" className="mr-2" onClick={clearSignature}>Borrar</Button>
        <Button color="primary" className="mr-2" onClick={addSignatureCreated}>Guardar</Button>
      </div>
    </div>
  )
}

export default SignatureComponent;
