import React, {useEffect, useRef, useState} from 'react';
import {Category} from '../../Models/Category';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {setIcon} from '../../Utils';
import {useLocation} from "react-router-dom";
import queryString from "querystring";

type Props = {
  categories: Array<Category>;
}

const CategorySidebar: React.FC<Props> = ({categories}) => {
  const {search: searchFilter} = useLocation();
  const [linkActive, setLinkActive] = useState<string>('');
  const loadParam = useRef(false);
  const checkActive = (category: Category) => {
    return linkActive === category.name;
  };

  const handleActiveAndScroll = (category: Category) => {
    setLinkActive(category.name);
    category && scrollTo(category._KEY);
  };

  const scrollTo = (id: string = '') => {
    let element: HTMLElement | null = document.getElementById(id);
    if (element) {
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      });
    }
  };

  useEffect(() => {
    const {
      category: categoryParam
    } = queryString.parse(searchFilter.replace("?", ""));
    if (categories.length && !loadParam.current && categoryParam) {
      const active = categories.find(c => c._KEY === categoryParam.toString());
      setLinkActive(`${active?.name}`);
      setTimeout(() => {
        scrollTo(categoryParam.toString());
        loadParam.current = true;
      }, 200)
    }
  }, [searchFilter, categories])
  return <>
    {(categories.length > 0) && categories
      .filter((category: Category) => category.documents.length)
      .map((category: Category) => (
        <span key={category._KEY}
              className={`mb-4 sidebar-links pointer ${category && checkActive(category) ? 'active' : ''}`}
              onClick={() => category && handleActiveAndScroll(category)}>
               <FontAwesomeIcon icon={setIcon(category.name)} className="mr-2"/> {category && category.name}

      </span>
      ))}
  </>;
};

export default CategorySidebar;
