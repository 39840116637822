import {Button, Popover} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import React from "react";

const InfoFieldPop = ({text, className}: { text: React.ReactNode, className?:string }) => {
  return <Popover
    content={text}>
    <Button className={"btn-float-left " + className} type="link"><FontAwesomeIcon color={"383EB3"} icon={faInfoCircle}/></Button>
  </Popover>
};


export {InfoFieldPop}
