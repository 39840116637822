import React, {useCallback} from "react";
import ModalComponent from "./ModalComponent/ModalComponent";
import {actionsModal} from "../../../Store/Modals/Slice";
import {typeModal} from "../../../Store/Modals/Types";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {State} from "../../../Store/FormExecution/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFilePdf, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {convertStringToPdf, lineSignature, replaceDefaultSignature} from "../../../Utils";
import {incrementDownload} from "../../../Services";

export default () => {
  const dispatch = useDispatch();
  const {
    formExecutionReducer: {previewItem, state},
    documentReducer: {currentDocument: {name, _KEY}},
  } = useSelector((state: RootState) => state)
  const closeModal = useCallback(() => {
    dispatch(actionsModal.closeModal({type: typeModal.PREVIEW_ITEM_MODAL}))
  }, [dispatch]);


  async function downloadDocument(prev: string) {
    const {text} = replaceDefaultSignature(prev,lineSignature);
    convertStringToPdf(text,
      {name, download: true},
      () => {
        incrementDownload(_KEY, 1);
      })
  }


  return <ModalComponent title="Vista previa de secciones"
                         size="lg"
                         isOpen={true}
                         toggle={closeModal}
                         noFooter
  >
    {state === State.PENDING ? (
      <div className="text-center">
        <FontAwesomeIcon icon={faSpinner} spin size="3x"/>
        <h6 className="mt-2">Creando vista previa...</h6>

      </div>
    ) : (
      <>
        <div className="shadow rounded p-5">
          <div id="preview_pdf" className="container__preview_doc" style={{color: 'black'}}
               dangerouslySetInnerHTML={{__html: replaceDefaultSignature(previewItem).text}}/>
        </div>
        <div className="text-right mt-2">
          <button onClick={() => downloadDocument(previewItem)}
                  className="btn btn-primary">
            <FontAwesomeIcon className="mr-2" icon={faFilePdf}/>
            Descargar
          </button>
        </div>
      </>
    )}
  </ModalComponent>
}
