import React from 'react';
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

export interface ModalProps {
  isOpen: boolean;
  zIndex?: number;
  toggle: () => void;
  onSuccess?: () => void;
  disabled?: boolean;
  isLoading?: boolean;
  noFooter?: boolean;
  header?: React.ReactNode;
  labelSuccess?: string;
  labelCancel?: string;
  size?: "sm" | "md" | "lg" | "xl";
  title?: string;
  footer?: React.ReactElement;
  onCancel?: () => void;
}

const ModalComponent: React.FC<ModalProps> = ({
                                                children,
                                                noFooter,
                                                isOpen,
                                                toggle,
                                                onSuccess,
                                                disabled,
                                                isLoading,
                                                header,
                                                footer,
                                                size = 'md',
                                                title,
                                                onCancel,
                                                labelCancel,
                                                labelSuccess,
                                                zIndex,
                                              }) => {
  return (
    <Modal zIndex={zIndex || 2000} toggle={toggle} size={size} isOpen={isOpen}>
      {header || title ? <ModalHeader>{header || title}</ModalHeader> : null}
      <ModalBody>{children}</ModalBody>
      {!noFooter ? (
        <ModalFooter>
          {footer ? (
            footer
          ) : (
            <>
              <Button disabled={isLoading} color="light" onClick={toggle}>
                {labelCancel || "Cancel"}
              </Button>
              <Button
                disabled={disabled || isLoading}
                color="primary"
                onClick={onSuccess || toggle}>
                {(labelSuccess || 'Save') + " "}
                {isLoading ? <FontAwesomeIcon icon={faSpinner} spin/> : null}
              </Button>
            </>
          )}
        </ModalFooter>
      ) : null}
    </Modal>
  );
};

export default ModalComponent;
