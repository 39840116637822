import React from "react";
import useRequired from "../Hooks/useRequired";
import TextProps from "../Models/TextProps";

const LargeTextComponent: React.FC<TextProps> = ({
  id,
  name,
  value,
  invalid,
  required,
  readonly,
  disabled,
  style,
  placeholder,
  className,
  onChange
}) => {

  const error = useRequired(value, Boolean(required));

  return (
    <textarea id={id}
              name={name}
              value={value}
              required={required}
              readOnly={readonly}
              disabled={disabled}
              style={style}
              placeholder={placeholder}
              className={`form-control ${className || ''} ${invalid || error ? 'invalid' : ''}`}
              onChange={e => onChange ? onChange(e.target.value) : null}/>
  );
}

export default LargeTextComponent;
