import React, {useEffect, useState} from 'react';
import {Auth} from '../../Models/Auth';
import {useDispatch, useSelector} from 'react-redux';
import history from '../../Utils/History';
// import Input from 'sbx-form-components';
// import {ChildType, FieldType} from '../../Models/FieldTypes';
// import {InfoFieldPop} from '../../Shared/PopoversComponent';
import FooterComponent from '../FooterComponent';
import {actionsAuth} from '../../Store/Auth/Slice';
import {RootState} from '../../Store/Reducers';
import {State} from "../../Store/Auth/Types";
import {LabelComponent, PasswordComponent, SmallTextComponent} from "sbx-ui-input-library";
import {InfoFieldPop} from "../../Shared/PopoversComponent";
import {faExclamationTriangle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

const initialCred: Auth = {user: '', password: ''};
const LogInComponent = ({
                          modal,
                          signUp,
                          warning,
                          reload = true
                        }: { modal?: boolean, signUp?: () => void, warning?: boolean, reload?: boolean }) => {

  const {state: stateAuth, user: {role}} = useSelector((state: RootState) => state.authReducer);

  const [cred, setCred] = useState(initialCred);
  // const [error, setError] = useState({user: false, password: false});
  const dispatch = useDispatch();

  function logIn(event: any) {
    event.preventDefault()
    dispatch(actionsAuth.authLogin(cred));
  }

  const loading = stateAuth === State.PENDING;

  useEffect(() => {
    if (stateAuth === State.RESOLVED && !modal) {
      history.push('/documents');
    } else if (stateAuth === State.RESOLVED && modal && reload) {
      window.location.reload();
    }
  }, [stateAuth, modal, reload]);

  function goToLogin() {
    history.push("/login");
    window.location.reload();
  }

  return (
    <>
      <div className={modal ? "container-fluid pt-5" : "vh-90 container-fluid pt-5"}>
        <div style={{height: '100%'}} className="row d-flex justify-content-center align-items-center">
          {modal && warning &&
          <p><FontAwesomeIcon icon={faExclamationTriangle} className="mr-2 text-warning"/> La sesión ha expirado inicie
            sesion para
            continuar.</p>}
          <div className={modal ? "col-md-8 col-12" : "col-lg-4 col-md-8 col-12"}>
            <form onSubmit={e => logIn(e)} className="w-100 pt-3">
              <div className="form-group">
                <LabelComponent inputId="cred_user"
                                className="text-dark"
                                children="Correo electrónico o nombre de usuario "
                                icon={<InfoFieldPop
                                  text={<small>Aquí debes poner el nombre de usuario o email</small>}/>}
                />
                <SmallTextComponent id={"cred_user"}
                                    name="cred_user"
                                    required
                                    placeholder="Nombre de usuario/Email"
                                    value={cred.user}
                                    onChange={e => setCred({...cred, user: e})}/>
              </div>

              <div className="form-group">
                <LabelComponent inputId="cred_password"
                                children="Contraseña"
                                className="text-dark"
                                icon={<InfoFieldPop
                                  text={<small>Aquí tu contraseña</small>}/>}
                />
                <PasswordComponent id="cred_password"
                                   value={cred.password}
                                   name="cred_password"
                                   onChange={e => setCred({...cred, password: e})}
                                   placeholder="Tu contraseña"/>
              </div>

              <div className="form-group text-center mt-4">
                <button disabled={loading}
                        className="btn btn-primary btn-block">
                  {loading && <FontAwesomeIcon icon={faSpinner} spin className="mr-2"/>}
                  INICIAR SESIÓN
                </button>
              </div>
            </form>

            {modal && !role &&
            <div className="form-group  justify-content-between text-center mt-4">
              <button onClick={() => dispatch(actionsAuth.loginAsGuest())} type="button" disabled={loading}
                      className="btn  btn-link">
                <small>Iniciar como invitado</small>
              </button>
              <button onClick={goToLogin} type="button" disabled={loading} className="btn btn-link text-danger">
                <small>Salir</small>
              </button>
            </div>}

            <div className="text-center pb-3 pt-3">
              <small>¿No tienes una cuenta? <a href="/" className="text-primary" onClick={e => {
                e.preventDefault();
                if (modal) {
                  if (signUp) signUp();
                } else {
                  history.push('/sign_up');
                }
              }}>Registrate</a></small>
            </div>

            <div className="text-center">
              <small><a href="/" className="text-primary" onClick={e => {
                e.preventDefault();
                history.push('/forgot_password');
              }}>¿Olvidaste tu contraseña?</a></small>
            </div>
          </div>
          {/*<div*/}
          {/*     className="bg-light px-md-7 col-md-6 d-flex justify-content-center align-items-center br-17">*/}
          {/*  <div className="w-100 text-center">*/}
          {/*    <h6 className="py-2">O ingresa con tu red favorita</h6>*/}
          {/*    <button*/}
          {/*      className="btn btn-light btn-block br-30 mb-4 border-info d-flex align-items-center text-primary justify-content-center">*/}
          {/*      <i className="fa fa-facebook-square  fa-2x mr-2"/> Iniciar con facebook*/}
          {/*    </button>*/}

          {/*    <button*/}
          {/*      className="btn btn-light btn-block br-30 mb-4 border-danger d-flex align-items-center text-danger justify-content-center">*/}
          {/*      <i className="fa fa-google-plus-circle  fa-2x mr-2"/> Iniciar con google*/}
          {/*    </button>*/}
          {/*  </div>*/}
          {/*</div>*/}
        </div>

      </div>
      {!modal && <FooterComponent/>}
    </>
  );
};


export default LogInComponent;
