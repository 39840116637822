declare var process: {
  env: {
    [key: string]: string;
  };
};

const keys: any = {
  login: {
    250: "4a5f5d90-c30b-4c19-ad43-68daa71f7a63"
  },
  sigUp: {
    250: "1c41abe8-88a7-4dc0-b907-92b673f553d1"
  },
  forgotPassword: {
    250: "1310fee3-e888-4beb-9a35-cabb9f053e07"
  },
  validatePassword: {
    250: "ad196f11-3eed-4b26-92e5-5d6e944f603e"
  },
  validateToken: {
    250: "b95499c3-55a4-4774-b46a-5c64089d829c"
  },
  signUpNextToGuest: {
    250: "48b5df70-77f0-48ba-a717-62de6cf71d05"
  },
  loginAsGuest: {
    250: "68a81878-420e-42d8-a1ec-fa1e116623c0"
  },
  docsByCategories: {
    250: "a719260c-edb5-4e3f-940d-7c6eae68f735"
  },
  getPreview: {
    250: "680931e9-911c-4244-9456-bc3f64269255"
  },
  docsPagination: {
    250: "f5336274-04db-47cb-90ad-6ec1aba3f5c0"
  },
  signatureTemplate: {
    250: "6cd8a111-9ffb-4377-8295-85389f04b1ca"
  },
  document_finished: {
    250: "c5b4817c-f1ee-4796-a144-4f13fdd61158"
  },
  folderSignature: {
    250: "669b9a84-81cf-4462-ba9d-b1db789e88bb"
  },
  userSignature: {
    250: "7ff9092f-78cc-422a-bf11-6e68d50df5ff"
  },
  sendEmail: {
    250: "55cf78aa-c285-40a7-9bed-8915081f604a"
  }
};

const env = {
  role: "CUSTOMER",
  sendFrom: process.env.REACT_APP_SEND_EMAIL || "",
  mixPanelToken: process.env.REACT_APP_MIX_PANEL_TOKEN || "",
  formUrl: process.env.REACT_APP_FORM_URL,
  processUrl: process.env.REACT_APP_PROCESS_URL,
  production: false,
  domain: process.env.REACT_APP_DOMAIN || 0,
  baseUrl: process.env.REACT_APP_BASE_URL,
  appKey: process.env.REACT_APP_APP_KEY,
  token: process.env.REACT_APP_TOKEN_PROCESS,
  sbxSearch: process.env.REACT_APP_SEARCH_URL,
  cloudScripts: {
    validatePassword: keys.validatePassword[process.env.REACT_APP_DOMAIN],
    login: keys.login[process.env.REACT_APP_DOMAIN],
    signUp: keys.sigUp[process.env.REACT_APP_DOMAIN],
    signUpNextToGuest: keys.signUpNextToGuest[process.env.REACT_APP_DOMAIN],
    validateToken: keys.validateToken[process.env.REACT_APP_DOMAIN],
    loginAsGuest: keys.loginAsGuest[process.env.REACT_APP_DOMAIN],
    docsByCategories: keys.docsByCategories[process.env.REACT_APP_DOMAIN],
    getPreview: keys.getPreview[process.env.REACT_APP_DOMAIN],
    docsPagination: keys.docsPagination[process.env.REACT_APP_DOMAIN],
    userSignature: keys.userSignature[process.env.REACT_APP_DOMAIN],
    sendEmail: keys.sendEmail[process.env.REACT_APP_DOMAIN]
  },
  templates: {
    forgotPassword: keys.forgotPassword[process.env.REACT_APP_DOMAIN],
    signature: keys.signatureTemplate[process.env.REACT_APP_DOMAIN],
    document_finished: keys.document_finished[process.env.REACT_APP_DOMAIN]
  },
  folders: {
    signature: keys.folderSignature[process.env.REACT_APP_DOMAIN]
  }
};
export default env;
