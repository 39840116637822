/* eslint no-eval: 0 */
import {httpProcess} from '../../Network';
import Response from '../../Models/Response';
import ProcessExecutionTask, {ProcessData,} from '../Models/ProcessExecutionTask';
import Form from '../Models/Form';
import {getTaskDetail} from './ProcessModelService';
import {CONDITIONS} from '../../Utils/Constants';
import {FieldValue} from '../../Models/IField';
import {getForm} from "../../Services";

export function launchProcess(processId: number, initialValue: any = {}) {
  return httpProcess
    .post(
      `https://sbx-svc-process.nubesocobox.com/api/v2/250/wf/process/launch/${processId}`,
      initialValue,
    )
    .then(value => value.data);
}

export function saveTaskService(taskId: number, fields: FieldValue[]) {
  return httpProcess
    .post(
      `https://sbx-svc-process.nubesocobox.com/api/v2/250/wf/task/${taskId}/save`,
      {fields},
    )
    .then(value => value.data);
}

export function finishTask(
  processExecId: number,
  actionId: number,
  formId: number,
  fields: any = {},
) {
  return httpProcess
    .post(
      `https://sbx-svc-process.nubesocobox.com/api/v2/250/wf/task/${processExecId}/finish/${actionId}`,
      {
        form_id: formId,
        action_id: actionId,
        fields,
      },
    )
    .then(value => value.data);
}

const replaceValuesInRule = (rule: string, data: ProcessData) => {
  const defaultValues: string[] = [];
  if (rule) {
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => defaultValues.push(a));
    });
  }

  const sp = defaultValues
    .map(a => {
      const condition = CONDITIONS.find(c => a.includes(c));
      if (condition) {
        const d = a.split(condition);
        const f = d[0].split('_');
        const field: string = d[0].replace(`${f[0]}_${f[1]}_`, '');
        return {
          formId: f[0],
          fieldId: f[1],
          value: d[1],
          field,
        };
      }
      return null;
    })
    .filter(i => i !== null);

  sp.forEach(field => {
    if (field?.field) {
      rule = rule.replace(
        `${field?.formId}_${field?.fieldId}_${field?.field}`,
        `"${data[field?.field] ? data[field?.field].value : ''}"`,
      );
    }
  });
  console.log('replaceValuesInRule -> rule', rule);
  return rule;
};

export async function getTaskExecDetail(
  taskAssignmentId: number,
  processId: number,
): Promise<Response<ProcessExecutionTask>> {
  const res: Response<ProcessExecutionTask> = await httpProcess
    .get(
      `https://sbx-svc-process.nubesocobox.com/api/v2/250/wf/task/${taskAssignmentId}`,
    )
    .then(value => value.data);
  if (res.success) {
    const attachmentToShow = res.item.attachments.find(i =>
      eval(replaceValuesInRule(i.rule, res.item.process_data)),
    );
    if (attachmentToShow) {
      let resAttach = await getFormByAttachmentId(
        attachmentToShow.attachment_id,
      );
      if (resAttach.success) {
        attachmentToShow.form = resAttach.item;
        for (let field of attachmentToShow.form.fields) {
          field.options = [];
          if (field.list_provider_id) {
            const resFieldOptions = await getProviderList(
              field.list_provider_id,
            );

            if (resFieldOptions.data.success) {
              field.options = resFieldOptions.data.item.options;
            }
          }
          if (field.detail_form_id) {
            const result = await getForm(field.detail_form_id);
            if (result && result.success) {
              field.detail_form = result.item;
            }
          }

        }
      }
      res.item.attachments = [attachmentToShow];
    }
    const resTaskDetail = await getTaskDetail(
      res.item.task_ref.task_id,
      processId,
    );
    if (resTaskDetail.success) {
      res.item.task_ref.task = resTaskDetail.item;
    }
    return Promise.resolve(res);
  }
  return Promise.resolve(new Response());
}

export function getFormByAttachmentId(
  attachmentId: number,
): Promise<Response<Form>> {
  return httpProcess
    .get(
      `https://sbx-svc-form.nubesocobox.com/api/v2/250/wf/form/${attachmentId}`,
    )
    .then(value => {
      return value.data;
    });
}

function getProviderList(providerId: number) {
  return httpProcess.get(
    `https://sbx-svc-form.nubesocobox.com/api/v2/250/wf/dl/${providerId}/options`,
  );
}
