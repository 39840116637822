import {sbxCoreService} from "../Network";
import {User} from "../Models/User";

export function changePassword(currentPassword: string, newPassword: string) {
  return sbxCoreService.changeMyPassword(currentPassword, newPassword);
}


export function updateUser(user: User) {
  return sbxCoreService.update("user", user);
}
