import {changePassword, updateUser} from '../../Services';
import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {toast} from '../../Utils';
import {CHANGE_PASSWORD, UPDATE_USER} from './Types';
import {actionsProfile} from './Slice';
import {PayloadAction} from "@reduxjs/toolkit";
import {State} from "../Category/Types";
import {User} from "../../Models/User";
import {RootState} from "../Reducers";
import {actionsAuth} from "../Auth/Slice";
import MixPanelActions from "../MixPanelActions";

function* changePasswordSaga({
                               payload: {
                                 currentPassword,
                                 newPassword
                               }
                             }: PayloadAction<{ currentPassword: string, newPassword: string }>) {
  try {
    const res = yield call(changePassword, currentPassword, newPassword);
    if (res.success) {
      toast("Contraseña actualizada")
      yield put(actionsProfile.setState(State.RESOLVED));
    } else {
      const message = "No se pudo cambiar la contraseña.";
      toast(message, "error");
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield  put(actionsProfile.setState(State.REJECTED))
  }
}

function* updateUserSaga({payload}: PayloadAction<User>) {
  try {
    const res = yield call(updateUser, payload);
    if (res.success) {
      toast("Datos actualizados")
      yield put(actionsProfile.setState(State.RESOLVED));
      const {user} = yield  select((state: RootState) => state.authReducer);
      yield put(actionsAuth.authLogged({
        data: {
          user: {...user, name: payload.name, last_name: payload.last_name},
          logged: true
        }
      }))
    } else {
      const message = "No se pudo actualizar los datos";
      toast(message, "error")
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield  put(actionsProfile.setState(State.REJECTED))
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(CHANGE_PASSWORD, changePasswordSaga),
    takeEvery(UPDATE_USER, updateUserSaga)
  ]);
}
