import React, {useCallback, useEffect, useState} from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal} from "../../../../Store/Modals/Slice";
import {typeModal} from "../../../../Store/Modals/Types";
import SignUpComponent from "../../../AuthComponent/SignUpComponent";
import {RootState} from "../../../../Store/Reducers";
import {Roles} from "../../../../Models/roles";
import LogInComponent from "../../../AuthComponent/LogInComponent";

export default (props: { data: null }) => {
  const dispatch = useDispatch();
  const {user: {role}} = useSelector((state: RootState) => state.authReducer);
  const [type, setType] = useState<"signUp" | "logIn">("signUp");

  const closeModal = useCallback(() => {
    dispatch(actionsModal.closeModal({type: typeModal.SIGN_UP_MODAL}));
  }, [dispatch]);

  useEffect(() => {
    if (role !== Roles.GUEST) {
      closeModal();
    }
  }, [role, closeModal]);
  return (
    <ModalComponent title={`Para continuar debes ${type === "signUp" ? "registrarte" : "iniciar sesión"}`}
                    size="lg"
                    isOpen={true}
                    toggle={() => null}
                    noFooter>
      {type === "signUp" ? (
        <SignUpComponent modal logIn={() => setType("logIn")}/>
      ) : (
        <LogInComponent
          reload={false}
          warning={false}
          modal
          signUp={() => setType("signUp")}/>
      )}
    </ModalComponent>
  )
}
