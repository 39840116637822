import {FinishTaskPayload, FormExecutionState, State} from './Types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import ProcessExecutionTask from '../../Execution/Models/ProcessExecutionTask';
import {FieldValue} from '../../Models/IField';
import Form from "../../Models/Form";

const initialState: FormExecutionState = {
  state: State.IDLE,
  previewItem: '',
  properties: {},
};

const slice = createSlice({
  name: 'form_execution',
  initialState,
  reducers: {
    getLaunchProcess(state, action: PayloadAction<{ processId: number }>) {
      state.state = State.INITIALIZING;
      state.form = new Form();
    },
    setCurrentTask(state, action: PayloadAction<ProcessExecutionTask>) {
      state.state = State.RESOLVED;
      state.task = action.payload;
    },
    getFinishTask(state, action: PayloadAction<FinishTaskPayload>) {
      state.state = State.PENDING;
    },
    setFinishTask(state, action: PayloadAction<ProcessExecutionTask>) {
      state.state = State.RESOLVED;
      state.task = action.payload;
    },
    rejectAction(state) {
      state.state = State.REJECTED;
    },
    getPreviewItem(
      state,
      action: PayloadAction<{
        processId: number;
        executionId: number;
        documentKey: string;
      }>,
    ) {
      state.state = State.PENDING;
    },
    setPreviewItem(state, action: PayloadAction<string>) {
      state.state = State.RESOLVED;
      state.previewItem = action.payload;
    },
    getProperties(
      state,
      action: PayloadAction<{ process_id: number; execution_id: number }>,
    ) {
      state.state = State.PENDING;
    },
    setProperties(state, action: PayloadAction<{ [key: string]: any }>) {
      state.state = State.RESOLVED;
      state.properties = action.payload;
    }, getSaveTask(
      state,
      action: PayloadAction<{ taskId: number; fields: FieldValue[] }>,
    ) {
      state.state = State.PENDING;
    },
    setSaveTask(state, action: PayloadAction) {
      state.state = State.PROCESS_FINISHED;
    },
    finishProcess: (state, action: PayloadAction) => ({
      ...state,
      state: State.PROCESS_FINISHED,
    }),
    resetState: (state, action: PayloadAction<State>) => {
      state.state = action.payload
    }
  },
});

export const actions = slice.actions;
export default slice.reducer;
