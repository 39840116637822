import React, {useEffect, useState} from "react";
import Emitter from "../../Services/Emitter";
import history from "../../Utils/History";
import {clearStorage} from "../../Services";
import {SessionType} from "../../Models/Auth";
import {existTokens, toast} from "../../Utils";

interface IProps {
  children: JSX.Element | JSX.Element[];
}

export const AuthorityComponent = (props: IProps) => {

  const [location, setLocation] = useState(history.location.pathname);
  const [sessionEvent, setSessionEvent] = useState<SessionType>("idle");

  useEffect(() => {
    switch (sessionEvent) {
      case "expired":
        const reload = location.includes("/document/detail/") || location.includes("/download/");
        if (reload) {
          clearStorage({reload, clearAll: true});
        } else {
          if (existTokens()) {
            toast("Sesión expirada", "warning");
          }
          setSessionEvent("idle");
          clearStorage({clearAll: true, pathHistory: "login"});
        }
        break;
    }
  }, [sessionEvent, location]);

  history.listen(location => {
    setLocation(location.pathname.replace('/', ''));
  });

  Emitter.on("SESSION", (event: SessionType) => {
    if (event !== sessionEvent) {
      setSessionEvent(event);
    }
  });


  return (
    <>
      {props.children}
    </>
  )
}
