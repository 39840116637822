import React from "react";
import "./ProfileComponent.scss";
import SideBar from "./SideBarProfileComponent";
import CardPasswordComponent from "./CardPasswordComponent";
import CardInformationComponent from "./CardInformationComponent";

export default () => {

  return (
    <>
      <div className="vh-90 overflow-auto py-5">
        <div className="container position-relative mb-4">
          <div className="row">
            <div className="col-lg-3">
              <SideBar/>
            </div>
            {/*  */}
            <div className="col-lg-9">
              <div id="general_card" className="pt-4 pt-lg-0 ">
                <h3>Perfil</h3>
                <p className="text-gray">Tu información personal</p>
              </div>

              <div id="user_info_card" className="pt-4">
                <CardInformationComponent/>
              </div>

              <div id="change_password_card" className="pt-4">
                <CardPasswordComponent/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
