import React from "react";
import ModalComponent from "../ModalComponent/ModalComponent";
import SignatureComponent from "./SignatureComponent";

interface Props {
  open: boolean;
  setOpen: (e: boolean) => void;
  getImageUrl: (imageUrl: string) => void
}

const SignatureModal = ({open, setOpen, getImageUrl}: Props) => {
  return (
    <ModalComponent isOpen={open}
                    size="lg"
                    noFooter
                    toggle={() => setOpen(false)}>
      <SignatureComponent getImage={getImageUrl}/>
    </ModalComponent>
  )
}

export default SignatureModal;
