import React, {useEffect, useRef, useState} from "react";
import {Button, Card, CardBody, CardFooter, CardHeader, Collapse, FormGroup, Input, Label, Row} from "reactstrap";

import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faCaretDown,
  faCaretUp,
  faCheckSquare,
  faCog,
  faEdit,
  faEye,
  faFileDownload,
  faFilePdf,
  faGripHorizontal,
  faList,
  faSpinner,
  faTimesCircle
} from "@fortawesome/free-solid-svg-icons";
import {CDocument} from "../../../Models/CDocument";
import PaginationComponent from "../../Shared/PaginationComponent";
import {capitalize, checkMail, getFieldSignature, toast} from "../../../Utils";
import ModalComponent from "../../Shared/Modals/ModalComponent/ModalComponent";
import {UserSignature} from "../../../Models/UserSignature";
import {DocumentUser} from "../../../Models/DocumentUser";
import {useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {sendEmailToUserSignature, updateUserSignature} from "../../../Services";

export type TypeButton = "danger" | "warning" | "primary" | "success" | "info" | "light";
export type Icon = "download" | "edit" | "detail" | "file" | React.ReactNode;

interface Event {
  type: TypeButton;
  event: (data: any) => void;
  label?: string;
  icon?: Icon
}

interface Props {
  list: CDocument[];
  events: Event[];
  loadingItem: boolean;
  loadingDocuments: boolean;
  onChangePage: (page: number) => void;
  count: number;
  pageSize: number;
}

const getIcon = (type: Icon) => {
  switch (type) {
    case "detail":
      return faEye;
    case "download":
      return faFileDownload;
    case "edit":
      return faEdit
    case "file":
      return faFilePdf;
    default:
      return null;
  }
}


const DocumentsListComponent = ({
                                  list,
                                  loadingItem,
                                  loadingDocuments,
                                  events,
                                  onChangePage,
                                  count,
                                  pageSize
                                }: Props) => {

  const [typeCol, setTypeCol] = useState("col-12");
  const [page, setPage] = useState(0);
  const init = useRef<boolean>(false);
  const [email, setEmail] = useState("");
  const [loadingEmail, setLoadingEmail] = useState(false);
  const {authReducer: {user: {name, last_name, email: currentEmail}}} = useSelector((state: RootState) => state)

  const [openModal, setOpenModal] = useState<{
    userSignature: UserSignature | null,
    open: boolean,
    documentUser: DocumentUser | null,
    documentName: string
  }>({
    userSignature: null,
    open: false,
    documentUser: null,
    documentName: ""
  })
  const [visibleUser, setVisibleUser] = useState<{ [key: string]: boolean }>({})

  useEffect(() => {
    if (page && init.current) {
      onChangePage(page)
    } else {
      init.current = true;
    }
  }, [page, onChangePage]);

  useEffect(() => {
    setEmail(openModal.userSignature?.email || "");
  }, [openModal])

  async function onSendEmail(e: any) {
    e.preventDefault();
    if (checkMail(email) && openModal.userSignature && openModal.documentUser && openModal.documentName) {
      setLoadingEmail(true);
      const nameUser = name + " " + last_name;
      const res = await sendEmailToUserSignature({
        to: email,
        name: nameUser,
        currentEmail,
        workflowId: openModal.documentUser.workflow_id,
        documentName: openModal.documentName
      })

      if (res.success && email !== openModal.userSignature.email) {
        const resDoc = await updateUserSignature({...openModal.userSignature, email});
        if (resDoc.success) {
          closeModal();
          onChangePage(page);
          setLoadingEmail(false);
          toast("Informacion actualizada");
        } else {
          toast("Error al actualizar los datos de correo / intenta mas tarde.", "error");
        }
      } else if (res.success) {
        toast("Informacion enviada");
      } else {
        toast("No se pudo enviar la información a esta direccion de correo.", "error");
      }

      setLoadingEmail(false)
    }
  }

  const toggle = (i: number) => setVisibleUser(e => ({...e, [i]: !e[i]}));
  const closeModal = () => setOpenModal({userSignature: null, open: false, documentUser: null, documentName: ""});
  return (
    <>
      {loadingDocuments && !page && (
        <div className="text-center">
          <FontAwesomeIcon size="3x" icon={faSpinner} spin/><br/>
          Cargando documentos...
        </div>
      )}
      {list.length ? (
        <div className="pb-5">
          <div className="py-2 text-right">
            <button className={"btn btn-light btn-sm mr-1 " + (typeCol === "col-12" && "active")}
                    onClick={() => setTypeCol("col-12")}>
              <FontAwesomeIcon icon={faList}/>
            </button>
            <button className={"btn btn-light btn-sm " + (typeCol !== "col-12" && "active")}
                    onClick={() => setTypeCol("col-lg-4 col-md-6 col-12")}>
              <FontAwesomeIcon icon={faGripHorizontal}/>
            </button>
          </div>
          <Row className="mb-2">
            {list.map((b, index) => {
              return <div key={index} className={typeCol + " my-2"}>
                <div style={{minHeight: "100px"}} className="shadow-sm bg-light border border-light rounded">
                  <div className="d-flex justify-content-between h-100">
                    <div className="d-flex align-items-center p-2">
                      <FontAwesomeIcon icon={faFilePdf} size="4x"
                                       className={"mr-2 " + (b.state ? "text-success" : "text-danger")}/>
                      <div>
                        <strong>{capitalize(b.name)}</strong><br/>
                        <span>Creado: {new Date(b.time).toLocaleString()}</span><br/>
                        <span
                          className={(b.state ? "text-success" : "text-danger")}>{(b.state ? "Finalizado" : "Pendiente")}</span>
                      </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-end">
                      {events.map((e, index) => {
                        const iconGenerated = getIcon(e.icon);
                        return <button key={index} disabled={loadingItem} className={`btn btn-${e.type} mr-2`}
                                       onClick={() => e.event(b)}>
                          {iconGenerated ? <FontAwesomeIcon spin={loadingItem} className={e.label && "mr-2"}
                                                            icon={loadingItem ? faSpinner : iconGenerated}/> : e.icon}
                          {e.label}</button>
                      })}
                    </div>

                  </div>
                  {!!b.user_signature.length && <div className="text-center">
                    <FontAwesomeIcon className="pointer" color="#383EB3" onClick={() => toggle(index)}
                                     icon={visibleUser[index] ? faCaretUp : faCaretDown}/>
                    <Collapse className="text-left px-2" isOpen={visibleUser[index]}>
                      <span className="font-weight-bolder">Estado de los usuarios</span>
                      {b.user_signature.map(e => (
                        <div key={e._KEY} className="p-1 m-0">
                          <span className={e.state ? "text-success" : "text-danger"}>
                            <b className="text-dark">{getFieldSignature(e.field_signature)}: </b>
                            <FontAwesomeIcon className="ml-2 "
                                             icon={e.state ? faCheckSquare : faTimesCircle}/> {e.email}
                            {!e.state && (
                              <span onClick={() => setOpenModal({
                                userSignature: e,
                                open: true,
                                documentUser: b.document_user,
                                documentName: b.name
                              })}
                                    className="ml-2 btn-link pointer text-primary">
                                <FontAwesomeIcon title="Configurar este correo" icon={faCog}/> Configurar
                              </span>
                            )}
                          </span>

                        </div>
                      ))}
                    </Collapse>
                  </div>}
                </div>
              </div>
            })}
          </Row>
          <PaginationComponent
            count={count}
            pageSize={pageSize}
            position="center"
            className="m-auto"
            onChange={setPage}/>
        </div>
      ) : (
        <h5 className="text-center p-5 mt-5">{!loadingDocuments && "No hay documentos"}</h5>
      )}

      <ModalComponent
        isOpen={openModal.open}
        noFooter
        toggle={closeModal}>
        {openModal.userSignature && (
          <form onSubmit={onSendEmail}>
            <Card>
              <CardHeader>
                <h5> {getFieldSignature(openModal.userSignature.field_signature)}</h5>
              </CardHeader>
              <CardBody>
                <FormGroup>
                  <Label>
                    <strong>Correo electrónico</strong>
                  </Label>
                  <Input required type="email" value={email} onChange={e => setEmail(e.target.value)}/>
                </FormGroup>
              </CardBody>
              <CardFooter className="text-right">
                <Button disabled={loadingEmail} color="primary">
                  {loadingEmail && <FontAwesomeIcon icon={faSpinner} spin className="mr-2"/>}Reenviar correo
                </Button>
              </CardFooter>
            </Card>
          </form>
        )}
      </ModalComponent>
    </>
  )
}

export default DocumentsListComponent;
