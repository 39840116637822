export const AUTH_LOG_IN = "auth/authLogin";
export const AUTH_LOGGED = "auth/authLogged";
export const AUTH_SIGN_UP = "auth/authSignUp";
export const VALIDATE_TOKEN = "auth/validateToken";
export const FORGOT_PASSWORD = "auth/forgotPassword";
export const SENDED_FORGOT_PASSWORD = "auth/sendedForgotPassword";
export const RESTORE_PASSWORD = "auth/restorePassword";
export const PASSWORD_RESTORED = "auth/passwordRestored";
export const RESTORE_VALUES = "auth/restoreValues";
export const LOGIN_AS_GUEST = "auth/loginAsGuest";
export const SIGN_UP_THE_GUEST = "auth/signUpTheGuest";

export enum State {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
  IDLE = 'IDLE'
}
