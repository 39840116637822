import React, {useEffect} from 'react';
import history from "../../Utils/History";

const LandingComponent = () => {
  useEffect(() => history.push("/login"), []);
  return <div data-testid="searchInput"> </div>;
};


export default LandingComponent;
