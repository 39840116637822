export default class Document {
  folder_key!: string;
  name!: string;
  category!: string;
  workflow_id!: number;
  description!: string;
  large_description!: string;
  pages!: number;
  downloads!: number;
  last_updated!: string;
  _KEY!: string;
  document_key!: string;
}

