import React, {useState} from 'react';
import history from '../../Utils/History';
import {InfoFieldPop} from '../../Shared/PopoversComponent';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import {actionsAuth} from '../../Store/Auth/Slice';
import {RootState} from '../../Store/Reducers';
import {LabelComponent, EmailComponent} from "sbx-ui-input-library";

export default () => {
  const dispatch = useDispatch();
  const {sendingEmail, EmailSended} = useSelector(
    (state: RootState) => state.authReducer);
  const [email, setEmail] = useState('');

  function sendEmail(e: any) {
    e.preventDefault();
    dispatch(actionsAuth.forgotPassword({email}));
  }

  return (
    <div className="container-fluid d-flex justify-content-center align-items-center vh-90">
      {EmailSended ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success fa-4x mb-2"
          />
          <p>
            <b>
              Se ha enviado un enlace a tu correo para recuperar tu contraseña
            </b>
          </p>
          <a
            href="/"
            onClick={e => {
              e.preventDefault();
              dispatch(actionsAuth.restoreValues());
            }}>
            <small>Volvel a enviar</small>
          </a>
        </div>
      ) : (
        <form onSubmit={sendEmail} className="col-lg-4 col-md-8 col-12 ">
          <div className="form-group">
            <div className="form-group">
              <LabelComponent children="Correo electrónico"
                              icon={<InfoFieldPop
                                text={
                                  <small>
                                    Correo electrónico con el que te registraste para
                                    recuperar tu contraseña
                                  </small>
                                }
                              />}
              />
              <EmailComponent id="forgot_password"
                                  required
                                  placeholder="Digita tu correo electrónico"
                                  name="forgot_password"
                                  value={email}
                                  disabled={sendingEmail}
                                  onChange={setEmail}

              />

              {/*<Input*/}
              {/*  required*/}
              {/*  inputProps={{disabled: sendingEmail}}*/}
              {/*  value={email}*/}
              {/*  type={FieldType.smallText}*/}
              {/*  childType={ChildType.email}*/}
              {/*  label="Correo electrónico"*/}
              {/*  className={error ? 'is-invalid' : 'is-valid'}*/}
              {/*  errorDescription={*/}
              {/*    <small className="text-danger">Correo no valido</small>*/}
              {/*  }*/}
              {/*  labelClassName="css-label"*/}
              {/*  onError={e => setError(e)}*/}
              {/*  iconToolTip={*/}
              {/*    <InfoFieldPop*/}
              {/*      text={*/}
              {/*        <small>*/}
              {/*          Correo electrónico con el que te registraste para*/}
              {/*          recuperar tu contraseña*/}
              {/*        </small>*/}
              {/*      }*/}
              {/*    />*/}
              {/*  }*/}
              {/*  placeholder="Digita tu correo electronico"*/}
              {/*  onChange={e => setEmail(e.value)}*/}
              {/*/>*/}
            </div>
          </div>

          <div className="form-group">
            <button
              disabled={sendingEmail}
              className="btn-block btn-primary btn">
              {sendingEmail && <FontAwesomeIcon icon={faSpinner} spin/>} enviar
            </button>
          </div>

          <div className="text-center ">
            <small>
              <a
                className="text-primary"
                href="/"
                onClick={e => {
                  e.preventDefault();
                  history.push('login');
                }}>
                Volver para iniciar sesión
              </a>
            </small>
          </div>
        </form>
      )}
    </div>
  );
};
