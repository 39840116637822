import React from "react";

interface Props {
  className?: string,
  loading: boolean
}

const SpinnerComponent = ({className, loading}: Props) => {
  return (
    <>
      {loading ?
        <div className={className}>
          <div className="text-center w-100">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        : null}
    </>

  )
};

export default SpinnerComponent;
