import Document from '../../Models/Document';
import Section from '../../Models/Section';
import Form from '../../Models/Form';
import {CDocument} from "../../Models/CDocument";

export const GET_DOCUMENTS = 'document/getDocuments';
export const GET_DOCUMENT = 'document/getDocument';
export const GET_SECTIONS = 'document/getSections';
export const GET_CURRENT_FORM = 'document/getCurrentForm';
export const GET_OPTIONS = "document/getOptionsById";
export const GET_DOCUMENTS_CREATED = "document/getDocumentsCreated";
export const GET_FILE = "document/getFile";

export enum State {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
  IDLE = 'IDLE'
}

export interface DocumentState {
  documents: Document[];
  currentFile: File | null;
  state: State;
  sections: Section[];
  currentDocument: Document;
  activeSection?: Section;
  currentForm?: Form;
  documentsCreated: { rows: CDocument[], row_count: number }
}
