enum SubType {
  CURRENCY = "CURRENCY",
  EMAIL = "EMAIL",
  PHONE = "PHONE",
  PASSWORD = "PASSWORD",
  NUMBER = "NUMBER",
  TIME = "TIME",
  SELECT = "SELECT",
  CHECKBOX = "CHECKBOX"
}

export default SubType;
