import React from 'react';
import {useDropzone} from 'react-dropzone';
import {Button, Collapse, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from "reactstrap";
import {megabyteToByte, stopPropagation, uuidV4} from "../Utils";
import SpinnerComponent from "../Shared/SpinnerComponent";
import {useState} from "react";
import {eachLimit} from 'async';

interface IProps {
  maxSize?: number;
  readOnly?: boolean;
  value: File | null;
  invalid?: boolean;
  onChange: (e: File | null) => void;
  onDropRejected: (file: File) => void;
  onDownloadFile?: (file: File) => Promise<any>;
  onUploadFile?: (formData: FormData) => Promise<any>;
}

const SingleFileComponent: React.FC<IProps> = (props: IProps) => {
  const {
    maxSize, readOnly, value, invalid, onChange,
    onDropRejected, onDownloadFile, onUploadFile
  } = props;
  const key = uuidV4();
	const message = 'Haga click o arrastre para subir un documento anexo.';

	const [showFile, setShowFile] = useState(false);
	const [file, setFile] = useState<File | null>(value);
  const [uploadingFile, setUploadingFile] = useState(false);
	const [downloadingFile, setDownloadingFile] = useState(false);
  const [isInvalid, setIsInvalid] = useState(invalid);

	const {getRootProps, getInputProps} = useDropzone({
		disabled: readOnly || uploadingFile,
		maxSize: megabyteToByte(maxSize),
		multiple: false,
		onDropRejected: rejectedFiles => {
		  onDropRejected(rejectedFiles[0]);
    },
		onDropAccepted: acceptedFiles => {
      if (!acceptedFiles.length) {
        return;
      }
      setUploadingFile(true);
      setIsInvalid(false);
      eachLimit(acceptedFiles, 3, (file, callback) => {
        if (onUploadFile) {
          const formData = new FormData();
          formData.append('file', file);
          onUploadFile(formData).then(_ => callback(null));
        } else {
          callback(null)
        }
      }, (_) => {
        const newValue = acceptedFiles[0];
        setFile(newValue);
        onChange(newValue);
        setUploadingFile(false);
      });
    }
	});

	const downloadFile = async (event: any, file: File) => {
		event.stopPropagation();
		if (onDownloadFile) {
      setDownloadingFile(true);
      await onDownloadFile(file);
      setDownloadingFile(false);
    }
	};

	const toggle = function (event: any) {
		event.stopPropagation();
		if (!file) {
			return;
		}
		setShowFile(!showFile);
	};

	const removeFile = function (event: any) {
		event.stopPropagation();
		setFile(null);
    onChange(null);
    setShowFile(false);
	};

	return (
		<div {...getRootProps({className: `dropzone position-relative ${isInvalid ? 'invalid' : ''}`})}>
			<input {...getInputProps()} />
			{
				uploadingFile ? <SpinnerComponent className="" style={{marginBottom: 12}}/> : <p> {message} </p>
			}
			<aside className="dropzone-files-container px-3" onClick={stopPropagation}>
				<div className={`bg-white px-2 ${showFile ? '' : 'border-top'}`}>
					<div className="d-flex justify-content-between">
						<h6 className="mb-0">Archivo</h6>
						<i className={`${file ? 'cursor-pointer' : 'cursor-not-allowed'} fa-1x fa ${showFile ? 'fa-angle-up' : 'fa-angle-down'} `}
							 onClick={toggle}/>
					</div>
					<Collapse isOpen={showFile}>
						<div className="row">
							{
							  file && <InputGroup size="sm" className="col-4 mb-1" key={key}>
                  <Input value={file.name} readOnly={true} className="p-1 h-100" id={`FileNameInput-${key}`}/>
                  <UncontrolledTooltip placement="top" target={`FileNameInput-${key}`}>
                    {file.name}
                  </UncontrolledTooltip>
                  <InputGroupAddon addonType="append">
                    <Button color="info"
                            onClick={(event: any) => downloadFile(event, file)}
                            className="h-100"
                            style={{lineHeight: 1}}
                            disabled={downloadingFile}>
                      <i className={`cursor-pointer fa ${downloadingFile ? 'fa-circle-o-notch fa-spin' : 'fa-download' }`}/>
                    </Button>
                    <Button color="danger" onClick={(event: any) => removeFile(event)} className="h-100" style={{lineHeight: 1}}>
                      <i className="cursor-pointer fa fa-trash"/>
                    </Button>
                  </InputGroupAddon>
                </InputGroup>
							}
						</div>
					</Collapse>
				</div>
			</aside>
		</div>
	);
};

export default SingleFileComponent;
