import {all, call, put, select, takeEvery} from 'redux-saga/effects';
import {
  FINISH_TASK,
  FinishTaskPayload,
  GET_PREVIEW_ITEM,
  GET_PROPERTIES,
  LAUNCH_PROCESS,
  SAVE_TASK,
  State
} from './Types';
import * as Services from '../../Services/index';
import {
  createDocumentUser,
  getPropertiesService,
  incrementDownload,
  sendEmailToDocumentFinished,
  setUserSignature,
  uploadFile
} from '../../Services/index';
import {toast} from '../../Utils';
import {RootState} from '../Reducers';
import {PayloadAction} from '@reduxjs/toolkit';
import Response from '../../Models/Response';
import ProcessExecutionTask from '../../Execution/Models/ProcessExecutionTask';
import {actions} from './Slice';
import history from "../../Utils/History";
import env from "../../config/environment";
import MixPanelActions from "../MixPanelActions";

export const document = (state: RootState) => state.documentReducer;

function* launchProcess({payload}: PayloadAction<{ processId: number }>) {
  try {
    const data = yield call(Services.launchProcess, payload.processId);
    if (data.success) {
      const processLaunch = data.item;
      const currentAssignment = processLaunch.current_assignments[0];
      if (currentAssignment?.id) {
        const res: Response<ProcessExecutionTask> = yield call(
          Services.getTaskExecDetail,
          currentAssignment.id,
          processLaunch.process_id,
        );
        if (res.success) {
          const execTask = res.item;
          MixPanelActions.createDocument(document.name);
          yield put(actions.setCurrentTask(execTask));
        }
      }
    }
  } catch (e) {
    console.error(e);
  }
}

function* finishTask({payload}: PayloadAction<FinishTaskPayload>) {
  try {
    const {
      authReducer: {user},
      documentReducer: {currentDocument: {name, _KEY: document_key}}
    } = yield select((state: RootState) => state)

    const data = yield call(
      Services.finishTask,
      payload.processExecId,
      payload.actionId,
      payload.formId,
      payload.fields,
    );
    if (data.success) {
      const nextTask = data.next_items[0];
      if (nextTask) {
        const res: Response<ProcessExecutionTask> = yield call(
          Services.getTaskExecDetail,
          nextTask.id,
          data.item.process_id,
        );
        if (res.success) {
          const execTask = res.item;
          yield put(actions.setCurrentTask(execTask));
        }
      } else {
        if (payload.document_user) {
          const file = payload.document_user.file;
          const resFile = yield call(uploadFile, payload.document_user.folder_key, file);
          let resSignature: any = {};
          if (payload.document_user.signature) {
            resSignature = yield call(uploadFile, env.folders.signature, payload.document_user.signature.file);
          } else {
            resSignature.success = true;
          }
          if (resFile.success && resSignature.success) {

            const stateDoc = !payload.document_user.emails.length;
            const resDocUser = yield  call(createDocumentUser, {
              data: data.item.data,
              data_file: payload.document_user.dataFile,
              user: payload.document_user.user,
              document_key: resFile.item.key_id,
              workflow_id: payload.document_user.workflowId,
              document: document_key,
              state: stateDoc
            });

            if (resDocUser.success) {
              if (payload.document_user.emails.length) {
                let users = payload.document_user.emails.map(e => ({
                  email: e.email,
                  field_signature: e.field_signature,
                  state: false,
                  document_user: resDocUser.keys[0],
                  image_key: "",
                }));
                if (payload.document_user.signature) {
                  users = [...users, {
                    email: user.email,
                    document_user: resDocUser.keys[0],
                    image_key: resSignature.item.key_id,
                    state: true,
                    field_signature: payload.document_user.signature.field_signature
                  }]
                }
                yield call(setUserSignature, users);
              }
              if (stateDoc) {
                const nameDocument = `${file.name.split("_").shift()}`;
                yield call(sendEmailToDocumentFinished, {
                  to: user.email,
                  key: resFile.item.key_id,
                  nameDocument
                })
              }
              yield call(incrementDownload, document_key, 1);
              MixPanelActions.setDocument(name);
              yield put(actions.resetState(State.IDLE));
              history.push("/documents");
            }
          }

        } else {
          yield put(actions.finishProcess())
        }
      }
    }
  } catch (e) {
    // toast(e.message, 'error');
    MixPanelActions.setErrors(e.message);
  }
}

function* getPreviewSaga({
                           payload: {processId, executionId, documentKey},
                         }: PayloadAction<{
  processId: number;
  executionId: number;
  documentKey: string;
}>) {
  try {
    const res = yield call(
      Services.getPreview,
      processId,
      executionId,
      documentKey,
    );
    if (res.success) {
      yield put(actions.setPreviewItem(res.item));
    } else {
      const message = 'No se pudo obtener la vista previa.';
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actions.rejectAction());
  }
}

function* getPropertiesSaga({
                              payload: {process_id, execution_id},
                            }: PayloadAction<{ process_id: number; execution_id: number }>) {
  try {
    const res = yield call(getPropertiesService, process_id, execution_id);

    if (res.success) {
      yield put(actions.setProperties(res.item.properties));
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    MixPanelActions.setErrors(e.message);
  }
}

function* saveTaskSaga({
                         payload: {taskId, fields},
                       }: PayloadAction<{ taskId: number; fields: any }>) {
  try {
    const res = yield call(Services.saveTaskService, taskId, fields);
    if (res.success) {
      yield put(actions.setSaveTask());
    } else throw Error;
  } catch (e) {
    yield put(actions.rejectAction());
    MixPanelActions.setErrors(e.message);
  }
}

export default function* () {
  yield all([
    //Auth sagas
    takeEvery(LAUNCH_PROCESS, launchProcess),
    takeEvery(FINISH_TASK, finishTask),
    takeEvery(SAVE_TASK, saveTaskSaga),
    takeEvery(GET_PREVIEW_ITEM, getPreviewSaga),
    takeEvery(GET_PROPERTIES, getPropertiesSaga),
  ]);
}
