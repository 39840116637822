import React from "react";
import {typeModal} from "../../../Store/Modals/Types";
import SignUpComponentModal from "./SignUpComponentModal/SignUpComponentModal";
import {useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import PreviewItemModal from "./PreviewItemModal";
import SendEmailModal from "./SendEmailModal";

export default () => {
  const {modals} = useSelector(
    (state: RootState) => state.modalReducer);

  const modalsArray: Array<{ type: typeModal, component: any }> = [
    {type: typeModal.SIGN_UP_MODAL, component: SignUpComponentModal},
    {type: typeModal.PREVIEW_ITEM_MODAL, component: PreviewItemModal},
    {type: typeModal.SEND_EMAIL_MODAL, component: SendEmailModal}
  ];

  return (
    <>
      {modalsArray.map((a, i) => {
        const {open, data} = modals[a.type];
        if (open) {
          return <a.component key={i} data={data}/>
        } else {
          return null
        }
      })}
    </>
  )
}
