import React, {CSSProperties} from "react";


interface Opt {
  label: string,
  value: any,
  data?: any
}

interface IProps {
  id: string,
  name: string,
  value: null | Opt,
  options: Opt[],
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: null | Opt) => void
}


const RadioButtonComponent = (props: IProps) => {

  const {id, value, required, onChange, className, options, name, disabled} = props;

  return (
    <div id={id} className="sbx__checkbox">
      {options.map(op => {
        return (
          <div key={op.value} className="my-1">
            <label
              htmlFor={op.value + id + "check"}
              className={className || "d-flex align-items-center w-100"}>
              <input
                id={op.value + id + "check"}
                disabled={disabled}
                checked={value ? value.value === op.value : false}
                name={name}
                required={required}
                onChange={() => onChange && onChange(op)}
                type="radio"/> <span className="ml-2">{op.label}</span>
            </label>
          </div>
        )
      })}
    </div>
  )
}
export default RadioButtonComponent;
