import React, {useCallback, useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {actionsAuth} from "../../../Store/Auth/Slice";
import SignatureTemplateComponent from "./SignatureTemplateComponent";
import {useHistory, useParams} from "react-router-dom";
import {verifyDocumentUser, verifyStateDocumentUser} from "../../../Services";
import {DocumentUser} from "../../../Models/DocumentUser";
import {UserSignature} from "../../../Models/UserSignature";
import {AuthToken} from "../../../Utils";
import {sbxSessionService} from "../../../Network";

const SignatureDetailComponent = () => {
  const {workflowId, email} = useParams();
  const {authReducer: {user: {_KEY: userState}}} = useSelector((state: RootState) => state);
  const dispatch = useDispatch();
  const history = useHistory()
  const [userData, setDataUser] = useState<{
    document_user: DocumentUser,
    user_signature: UserSignature, folder_key: string,
    users: UserSignature[],
    remaining: UserSignature[]
  } | null>(null)

  useEffect(() => {
    if (!AuthToken) {
      console.log("Starting...")
      dispatch(actionsAuth.loginAsGuest())
    }
  }, [dispatch]);


  const callBack = useCallback(async () => {
    if (workflowId && email && userState) {
      console.log("Checking...")
      const res = await verifyDocumentUser(workflowId, email);
      if (res.success && res.item) {
        const resV = await verifyStateDocumentUser(res.item.document_user._KEY);
        if (resV.success) {
          setDataUser({
            ...res.item,
            users: resV.results,
            remaining: resV.results.filter((u: UserSignature) => u._KEY !== res.item.user_signature._KEY && !u.state)
          });
        }
      } else {
        sbxSessionService.logout();
        localStorage.clear();
        dispatch(actionsAuth.restoreValues());
        history.push('/');
      }
    }
  }, [dispatch, workflowId, email, history, userState])

  useEffect(() => {
    callBack();
  }, [callBack])

  return (
    <>
      {userData && <SignatureTemplateComponent {...userData}/>}
    </>
  )
}

export default SignatureDetailComponent;
