import {all, call, put, takeEvery} from "redux-saga/effects";
import {GET_CATEGORIES, GET_CATEGORIES_DOCS} from './Types';
import * as Services from '../../Services/index';
import {addImageToCategory, sortByKey} from '../../Utils';
import {actionsCategory} from './Slice';
import Response from "../../Models/Response";
import {Category} from "../../Models/Category";
import {PayloadAction} from "@reduxjs/toolkit";
import {compareDesc} from "date-fns";
import {actionsDocument} from "../Document/Slice";
import MixPanelActions from "../MixPanelActions";

function* getCategoriesSaga() {
  try {
    const res = yield call(Services.getDocumentsByCategory)
    if (res.success) {
      const categories = sortByKey('name', Object.values(res.fetched_results.category))
      yield put(actionsCategory.setCategories(categories));
    }
  } catch (e) {
    // toast(e.message, "error");
    yield put(actionsCategory.rejectAction());
    MixPanelActions.setErrors(e.message);
  }
}

function* getCategoryDocsSaga({
                                payload: {
                                  page,
                                  size,
                                  keys,
                                  search,
                                  documentsKeys
                                }
                              }: PayloadAction<{ page: number, size: number | "all", keys: string[], search: string, documentsKeys: Array<string> | null }>) {
  try {
    const res: Response<Category> = yield call(Services.getCategoryDocs, page, size, keys, search, documentsKeys);
    if (res.success) {
      yield put(actionsDocument.resetState());
      res.items = res.items.map((a) => {
        a.documents = a.documents.sort((a, b) => compareDesc(new Date(a.last_updated), new Date(b.last_updated)));
        return a
      }).sort((a, b) => a.name.localeCompare(b.name));
      res.items = addImageToCategory(res.items, "png");
      yield put(actionsCategory.setCategoriesDocs(res.items));
    } else {
      throw Error;
    }
  } catch (e) {
    console.error(e)
    yield put(actionsCategory.rejectAction());
    MixPanelActions.setErrors(e.message);
    // toast(e.message, 'error');
  }
}

export default function* AllSagas() {
  yield all([
    takeEvery(GET_CATEGORIES, getCategoriesSaga),
    takeEvery(GET_CATEGORIES_DOCS, getCategoryDocsSaga)
  ])
}
