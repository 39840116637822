import React, {CSSProperties, useState} from "react";

interface Props {
  src: string,
  errorSrc: string,
  className?: string
  style?: CSSProperties;
  width?: string | number;
  height?: string | number;
  alt?: string
}

export default ({
                  src,
                  errorSrc,
                  className,
                  style,
                  width,
                  height,
                  alt
                }: Props) => {

  const [image, setImage] = useState(src)

  return (
    <img className={className}
         style={style}
         src={image}
         width={width}
         height={height}
         onError={() => {
           setImage(errorSrc);
         }} alt={alt}/>
  )
}
