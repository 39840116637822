import React from "react";
import Section from "../../Models/Section";
import StepComponent from "./StepComponent";
import {capitalize} from "../../Utils";

interface Iprops {
  sections: Section[],
  selected?: Section,
  size: number;
  title: string;
}

const SectionsListComponent = ({sections, selected, size, title}: Iprops) => {
  return (
    <div style={{height: size + "%"}} className="pt-5 px-3 px-xl-5 overflow-auto">
      <h2 className="section-title mb-4">{capitalize(title)}</h2>
      <ul className="list-group list-unstyled list-group-flush pb-lg-5">
        {sections.map((section: Section, index: number) => (
          <li key={section._KEY} className="section-bg">
            <div className="d-flex">
              <StepComponent
                name={section.name}
                isActive={section._KEY === selected?._KEY}
                index={index}
              />
            </div>
            <hr
              className={`section-underline ${
                index === sections.length - 1 ? 'd-none' : 'd-block'
              }`}
            />
          </li>
        ))}
      </ul>
    </div>
  )
}

export default SectionsListComponent;
