import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from './Types';
import {User} from "../../Models/User";

const initialState = {
  state: State.IDLE
};

const slice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    updateUser(state, action: PayloadAction<User>) {
      state.state = State.PENDING;
    },
    changePassword(state, action: PayloadAction<{ currentPassword: string, newPassword: string }>) {
      state.state = State.PENDING;
    },
    restoreValues(state) {
      return initialState;
    },
    setState(state, action: PayloadAction<State>) {
      state.state = action.payload
    }
  }
})

export const actionsProfile = slice.actions;
export default slice.reducer;
