import React from 'react';
import {Category} from '../../Models/Category';
import Document from '../../Models/Document';
import CategoryHeaderComponent from './CategoryHeaderComponent';
import DocumentComponent from './DocumentComponent';
import {useDispatch, useSelector} from "react-redux";
import {actionsCategory} from "../../Store/Category/Slice";
import {RootState} from "../../Store/Reducers";
import {State} from "../../Store/Category/Types";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

type Props = {
  documents: Array<Document>;
  category: Category;
}


const CategorySectionComponent: React.FC<Props> = ({documents, category}) => {
  const dispatch = useDispatch();
  const {state} = useSelector((state: RootState) => state.categoryReducer);
  const loading = state === State.PENDING;

  return <>
    <CategoryHeaderComponent category={category}/>
    <ul className="bg-white list-group">
      {!!documents.length && documents
        .map((document: Document) => (<li className="list-unstyled list-group-item document-container-list"
                                          key={document && document._KEY}>
            <DocumentComponent document={document}/>
          </li>
        ))}
    </ul>
    {!(category.length_documents === "all"
      || category.length_documents === "search"
      || documents.length < 3) && <footer className="text-center">
      <button
        disabled={loading}
        id={"showMore_" + category._KEY}
        className="btn btn-link text-primary" onClick={() => {
        dispatch(actionsCategory.getCategoriesDocs({
          page: 1,
          size: "all",
          keys: [category._KEY],
          search: "",
          documentsKeys: null
        }))
      }}>
        Ver más...
        {loading && <FontAwesomeIcon icon={faSpinner} spin/>}
      </button>
    </footer>}
  </>;
};

export default CategorySectionComponent;
