import React, {useState} from "react";
import ModalComponent from "./ModalComponent/ModalComponent";
import {useDispatch, useSelector} from "react-redux";
import {actionsModal} from "../../../Store/Modals/Slice";
import {typeModal} from "../../../Store/Modals/Types";
import {RootState} from "../../../Store/Reducers";
import {getFieldSignature, toast} from "../../../Utils";
import {FormGroup, Input} from "reactstrap";
import {sendEmailToUserSignature} from "../../../Services";
import {LabelComponent} from "sbx-ui-input-library";
import {InfoFieldPop} from "../../../Shared/PopoversComponent";

const SendEmailModal = ({data}: {
  data: {
    signatures: string[],
    preview: string,
    file: File | null,
    document_name: string,
    finishExecution: (prev: string, signatures: string[], emails: {
      email: string,
      field_signature: string
    }[], file: File | null) => void
  }
}) => {

  const dispatch = useDispatch()
  const [refEmails, setRefMails] = useState<{ [key: string]: any }>({});
  const [refNotes, setRefNotes] = useState<{ [key: string]: string }>({});
  const [loading, setLoading] = useState(false);
  const {
    authReducer: {user: {name, last_name, email: currentEmail}},
    formExecutionReducer: {task}
  } = useSelector((state: RootState) => state);

  function closeModal() {
    dispatch(actionsModal.closeModal({type: typeModal.SEND_EMAIL_MODAL}))
  }


  async function sendEmail(e: any) {
    e.preventDefault();
    if (task) {
      setLoading(true);
      let refArray: any = [];
      const emails = Object.keys(refEmails);
      const nameUser = name + " " + last_name;
      for await (let index of emails) {
        const res = await sendEmailToUserSignature({
          to: refEmails[index],
          name: nameUser,
          currentEmail,
          workflowId: task.process_execution_id,
          documentName: data.document_name,
          description: refNotes["description"],
          note: refNotes["note_" + index]
        });
        refArray.push(res);
      }

      if (!refArray.some((res: any) => !res.success)) {
        data.finishExecution(data.preview, ["$firma"], emails.map(e => ({
          email: refEmails[e],
          field_signature: data.signatures[parseInt(e)],
        })), data.file)
        closeModal()
      } else {
        toast("Error al enviar email", "error");
      }
      setLoading(false);
    }
  }

  function onsubmit() {
    document.getElementById("button_emails")?.click()
  }


  return (
    <ModalComponent isOpen={true}
                    zIndex={200}
                    size="lg"
                    title="Digite los correos correspondiente a los firmantes"
                    isLoading={loading}
                    toggle={closeModal}
                    labelCancel="Cerrar"
                    labelSuccess="Enviar"
                    onCancel={closeModal}
                    onSuccess={onsubmit}>
      <form onSubmit={sendEmail}>
        {data.signatures.map((s, index) => (
          <div className="d-flex" key={index}>
            <FormGroup className="col">
              <label>
                {"Correo electrónico para "} <strong>{getFieldSignature(s.replace("firma_", ""))}</strong>
              </label>
              <Input
                type="email"
                required
                value={refEmails[index] || ""}
                onChange={e => setRefMails({...refEmails, [index]: e.target.value})}/>
            </FormGroup>
            <FormGroup className="col">
              <LabelComponent
                className="section-inputs-label"
                children="Nota:"
                icon={
                  <InfoFieldPop
                    className="text-dark"
                    text={<small>Esta nota solo la podrá ver el destinatario al cual se le envía el correo, la ruta para
                      firmar el documento.</small>}
                  />
                }
              />
              <textarea
                className="form-control"
                value={refNotes["note_" + index] || ""}
                onChange={e => setRefNotes({...refNotes, ["note_" + index]: e.target.value})}
              />
            </FormGroup>
          </div>
        ))}
        <hr/>
        <FormGroup>
          <LabelComponent
            className="section-inputs-label"
            children="Nota general:"
            icon={
              <InfoFieldPop
                className="text-dark"
                text={<small>Esta nota la podran ver todos los destinatarios.</small>}
              />
            }
          />
          <textarea
            className="form-control"
            value={refNotes.description || ""}
            onChange={e => setRefNotes({...refNotes, description: e.target.value})}
          />
        </FormGroup>
        <button id="button_emails" style={{opacity: 0}}/>
      </form>
    </ModalComponent>
  )
}

export default SendEmailModal;
