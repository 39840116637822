import {all, call, put, takeEvery} from 'redux-saga/effects';
import {GET_CURRENT_FORM, GET_DOCUMENT, GET_DOCUMENTS, GET_DOCUMENTS_CREATED, GET_FILE, GET_SECTIONS} from './Types';
import * as Services from '../../Services/index';
import {getDocumentUsersByUser, getFileDownloaded} from '../../Services/index';
import {sortByKey, toast} from '../../Utils';
import {actionsDocument} from './Slice';
import {ResponseCS} from '../../Models/ResponseCS';
import Response from '../../Models/Response';
import {RootState} from '../Reducers';
import Form from '../../Models/Form';
import {PayloadAction} from "@reduxjs/toolkit";
import MixPanelActions from "../MixPanelActions";

export const document = (state: RootState) => state.documentReducer;

function* getDocumentsSaga() {
  try {
    const res: ResponseCS = yield call(Services.getDocumentsByCategory);
    if (res.success) {
      yield put(actionsDocument.setDocuments(res.results));
    } else {
      const message = "No se pudo obtener el documento";
      toast(message, "error");
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.rejectAction())
    toast(e.message, 'error');
  }
}

function* getFileSaga({payload}: PayloadAction<string>) {
  try {
    const res = yield call(getFileDownloaded, payload);
    if (res) {
      yield put(actionsDocument.setFile(res))
    } else {
      const message = "No se pudo obtener el archivo";
      toast(message, "error");
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.setFile(null));

  }
}

function* getSectionsSaga({payload}: { payload: string, type: string }) {
  try {
    const message = "No se pudo cargar el formulario de esta sección";
    const res: ResponseCS = yield call(Services.getSortDocumentService, payload);
    if (res.success) {
      if (res.results.length) {
        const formRes: Response<Form> = yield call(Services.getForm, res.results[0].form_id);
        const getFirSection: ResponseCS = yield call(Services.getSection, res.results[0]._KEY);
        if (formRes.success && getFirSection.success) {
          yield put(actionsDocument.setCurrentForm(formRes.item));
          yield put(actionsDocument.setActiveSection(getFirSection.results[0]));
          yield put(actionsDocument.setSections(res.results));
        } else {
          toast(message, 'error');
          throw new Error(message)
        }
      } else {
        yield put(actionsDocument.setSections(sortByKey('name', res.results)));
      }
    } else {
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.rejectAction())
    console.error(e.message);
  }
}

function* getDocumentSaga({payload}: { payload: string, type: string }) {

  try {
    const res = yield call(Services.getDocumentPreview, payload);
    if (res.success) {
      yield put(actionsDocument.setDocument(res.item));
    } else {
      const message = 'No se pudo obtener el documento';
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.rejectAction());
    console.error(e.message);
  }
}


function* getCurrentFormSaga({payload}: { payload: string | number, type: string | number }) {
  try {
    const res: Response<Form> = yield call(Services.getForm, payload);
    if (res && res.success) {
      yield put(actionsDocument.setCurrentForm(res.item));
    } else {
      const message = 'No existe ningun formulario en las secciones que tiene este documento.';
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.rejectAction());
  }
}

function* getDocumentsCreatedSaga({
                                    payload: {
                                      pageSize,
                                      page,
                                      user_key,
                                      folder_key
                                    }
                                  }: PayloadAction<{ folder_key: string, user_key: string, page: number, pageSize: number }>) {
  try {
    const res = yield  call(getDocumentUsersByUser, user_key, page, pageSize, folder_key);
    if (res.success) {
      yield put(actionsDocument.setDocumentsCreated(res.item))
    } else {
      const message = "No se pudo obtener la lista de archivos";
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsDocument.rejectAction());
  }
}

export default function* AllSagas() {

  yield all([
    //Auth sagas
    takeEvery(GET_DOCUMENTS, getDocumentsSaga),
    takeEvery(GET_DOCUMENT, getDocumentSaga),
    takeEvery(GET_SECTIONS, getSectionsSaga),
    takeEvery(GET_CURRENT_FORM, getCurrentFormSaga),
    takeEvery(GET_DOCUMENTS_CREATED, getDocumentsCreatedSaga),
    takeEvery(GET_FILE, getFileSaga)
  ]);
}
