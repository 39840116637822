import React, {useState} from "react";
import {Col, Progress, Row} from "reactstrap";
import FooterControlComponent from "./FooterControlComponent";
import Section from "../../Models/Section";
import Form from "../../Models/Form";
import ProcessExecutionTask, {Action} from "../../Execution/Models/ProcessExecutionTask";
import {capitalize} from "../../Utils";
import FieldsComponent from "../Shared/FieldsComponent";
import {Button,} from "antd";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faInfoCircle} from "@fortawesome/free-solid-svg-icons";
import ModalComponent from "../Shared/Modals/ModalComponent/ModalComponent";

interface IProps {
  sections: Section[];
  preview: () => void;
  form: Form;
  task: ProcessExecutionTask;
  fieldsValues: { [key: string]: any };
  getValues: (data: any) => void;
  section?: Section;
  loading: boolean;
  actions: Action[];
}

const FormContentComponent = ({
                                sections,
                                preview,
                                fieldsValues,
                                form,
                                getValues,
                                section,
                                loading,
                                actions
                              }: IProps) => {

  const index = sections.findIndex(s => section?._KEY === s._KEY) + 1;
  const percentage = (index / sections.length) * 100;
  const [OpenDescription, setOpenDescription] = useState(false);

  const Description = ({className}: { className?: string }) => (
    <div className={"section-active-descriptions " + className}
         dangerouslySetInnerHTML={
           {
             __html: section?.description || "No hay descripción"
           }
         }/>
  );

  return (
    <>
      <Row className="h-100 h-sm-90 overflow-auto px-xl-5 pb-5">
        <Col
          lg={7}
          sm={12}>
          <h2 className="section-active-inputs">
            {capitalize(section?.name)}
            <span className="d-lg-none">
              <Button onClick={() => setOpenDescription(true)} className="pl-2" type="link">
                <FontAwesomeIcon className="text-dark" icon={faInfoCircle}/>
              </Button>
            </span>
          </h2>
          {!!sections.length && (
            <>
              <Progress className="d-flex d-md-none" value={percentage}/>
              <div className="d-flex d-md-none">
                <span className="font-weight-bold">Paso {index} </span>
                <span className="section-mobile-steps ml-1"> de {sections.length}</span>
              </div>
            </>
          )}
          <FieldsComponent
            getValues={getValues}
            valuesFields={fieldsValues}
            formFields={form.fields}/>
        </Col>
        <Col
          lg={5}
          className="d-none d-lg-inline px-xl-5">
          <Description/>
        </Col>
      </Row>
      <FooterControlComponent
        style={{bottom: 0, left: 0, minHeight: "max-content"}}
        className="d-flex d-md-none position-fixed w-100"
        actions={actions}
        getPreview={preview}
        loading={loading}
        size={7}/>
      <ModalComponent
        title="Descripción"
        size="xl"
        footer={<button className="btn btn-light" onClick={() => setOpenDescription(false)}>Cerrar</button>}
        isOpen={OpenDescription}
        toggle={() => setOpenDescription(false)}>
        <Description className="section-active-descriptions-overflow"/>
      </ModalComponent>
    </>
  )
}

export default FormContentComponent;
