import React, {useCallback, useEffect, useState} from 'react';
import sync from '../../../Assets/Icons/sync.png';
import download from '../../../Assets/Icons/download.png';
import documentIcon from '../../../Assets/Icons/document.png';
import pdf from '../../../Assets/Icons/pdf.png';
import eye from '../../../Assets/Icons/eye.png';
import {useHistory, useParams} from 'react-router-dom';
import './documentPreview.scss';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {RootState} from "../../../Store/Reducers";
import {actionsDocument} from "../../../Store/Document/Slice";
import {State} from "../../../Store/Document/Types";
import {actionsAuth} from "../../../Store/Auth/Slice";
import {Roles} from "../../../Models/roles";
import {convertDate, getlength, goToCreateDocument} from "../../../Utils";
import IconComponent from "../../Shared/IconComponent";
import SpinnerComponent from "../../../Shared/SpinnerComponent";


const  DocumentPreviewComponent = () => {
  const {key} = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    documentReducer: {currentDocument: document, state},
    authReducer: {state: stateAuth, user: {role}}
  } = useSelector((state1: RootState) => state1);
  const token = localStorage.getItem("token");
  const [validate, setValidate] = useState(false);
  const loading = state === State.PENDING;
  const loadingAuth = stateAuth === State.PENDING;
  useEffect(() => {
    if (key) {
      dispatch(actionsDocument.getDocument(key));
    }
  }, [dispatch, key]);

  const goTo = (route = "/document/section/") => {
    if (key) {
      goToCreateDocument(key, history, route);
    }
  };

  function validateSession() {
    if (token) {
      goTo('/document/section/')
    } else {
      setValidate(true);
      dispatch(actionsAuth.loginAsGuest());
    }
  }

  const gotToCallback = useCallback(goTo, [goTo]);

  useEffect(() => {
    if (stateAuth === State.RESOLVED && role === Roles.GUEST && validate) {
      gotToCallback();
    }
  }, [stateAuth, validate, role, gotToCallback])


  return (
    <>
      {loading ?
        <SpinnerComponent
          loading={true}
          className="loading-spinner"
        />
        : (
          <>
            <div style={{background: '#F7F7F7'}}>
              <div className="d-flex pl-lg-5 col-lg-6 col-12  ">
                <div className="d-flex flex-column py-5">
                  <span className="title-22 mb-4">{document.name}</span>
                  <div className="d-flex flex-column flex-lg-row">
                    <div className="d-flex flex-column ">
                      <div className="d-flex mb-2">
                        <IconComponent classname="mr-2" icon={sync}/>
                        <div className="d-flex flex-column mr-5">
                          <span className="type-14">Última actualización</span>
                          <span className="type-13">
                          {document.last_updated &&
                          convertDate(document.last_updated)}
                        </span>
                        </div>
                      </div>

                      <div className="d-flex mt-3 mt-lg-0">
                        <IconComponent classname="mr-2" icon={pdf}/>
                        <div className="d-flex flex-column">
                          <span className="type-14">Formatos disponibles</span>
                          <span className="type-13">PDF / DOC</span>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex flex-column mt-3 mt-lg-0">
                      <div className="d-flex mb-2">
                        <IconComponent classname="mr-2" icon={download}/>
                        <span>
                        <span className="type-13-bold">
                          {document.downloads && getlength(document.downloads)}
                        </span>{' '}
                          <span className="type-13-300">Descargas</span>
                      </span>
                      </div>

                      <span className="opacity-0 details-sub d-none d-lg-flex">
                      White Space
                    </span>

                      <div className="d-flex mt-3 mt-lg-0">
                        <IconComponent classname="mr-2" icon={documentIcon}/>
                        <span>
                        <span className="type-13-bold">
                          {document.pages}
                        </span>{' '}
                       <span className="type-13-300">Páginas</span>
                      </span>
                      </div>
                    </div>
                  </div>
                  <div className="text-center mt-4">
                    <button className="btn btn-primary btn-block text-white"
                            disabled={loadingAuth}
                            onClick={validateSession}>
                      Crear documento
                      {loadingAuth && <FontAwesomeIcon className="ml-2" icon={faSpinner} spin/>}
                    </button>
                    <span className="d-flex justify-content-center mt-2 actions-text">
                        <IconComponent classname="mr-2" icon={eye}/>
                        Vista previa
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-12 pl-lg-5 mt-2">
              <span className="title-17 m-0">Descripción</span>
              <br/>
              <div className="pt-2 pb-4 p-m-0" style={{color: 'black'}}
                   dangerouslySetInnerHTML={{__html: document.large_description || document.description || ""}}/>
            </div>
          </>
        )}
    </>
  );
};

export default DocumentPreviewComponent;
