import React from "react";
import {Routes} from "../../Routes";
import {Route} from "react-router";

const Main = () => {
  return (
    <div className="vh-90 overflow-auto scroll-smooth">
      {Routes.map(route => {
        return (
          <Route
            key={route.id}
            exact={route.exact}
            path={route.path}
            component={route.component}
          />
        );
      })}
    </div>
  );
};

export default Main;
