import {Category} from '../../Models/Category';

export const GET_CATEGORIES_DOCS = 'category/getCategoriesDocs';
export const GET_CATEGORIES = 'category/getCategories'

export enum State {
  PENDING = 'PENDING',
  RESOLVED = 'RESOLVED',
  REJECTED = 'REJECTED',
  IDLE = 'IDLE'
}


export interface CategoryState {
  categories: Category[];
  state: State;
}
