import React from "react";

const WelcomeComponent = () => {
  return (
    <div className="container text-center">
      <h1>logged</h1>
    </div>
  )
};

export default WelcomeComponent;
