import React, {useEffect, useState} from "react";
import {Button, FormGroup, ModalFooter} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
  faFileDownload,
  faFileImage,
  faFilePdf,
  faPencilAlt,
  faSpinner,
  faTrash
} from "@fortawesome/free-solid-svg-icons";
import {
  dataURLtoFile,
  getFieldSignature,
  getImageBase64,
  getImg,
  getImgCompressed,
  layout,
  replaceDefaultSignature
} from "../../Utils";
import Select from "react-select";
import SignatureModal from "../../Components/Shared/Modals/SignatureComponentModal/SignatureModal";
import ModalComponent from "../../Components/Shared/Modals/ModalComponent/ModalComponent";

interface Props {
  previewItem: string;
  typeModal?: true;
  title?: string;
  toggle?: (close: false) => void;
  processId: number,
  loading: boolean;
  download: (preview: string, signatures: string[]) => void;
  finish: (prev: string, signatures: string[], emails: { email: string, field_signature: string }[], file: File | null) => void;
  userPending?: boolean,
  setSignature: (e: string) => void;
}

const PreviewDocumentComponent = ({
                                    previewItem,
                                    loading,
                                    toggle,
                                    finish,
                                    download,
                                    title,
                                    userPending = false,
                                    processId,
                                    setSignature
                                  }: Props) => {

  const [previewDoc, setPreviewDoc] = useState("");
  const [modalSignature, setModalSignature] = useState(false);
  const [signatures, setSignatures] = useState<string[]>([]);
  const [images, setImages] = useState<{ img: string, field: string }[]>([]);
  const [signatureSelected, setSignatureSelected] = useState<any>(null)
  const [file, setFile] = useState<File | null>(null)
  const [confirm, setConfirm] = useState< boolean >( false);

  async function signature(e: any) {
    let file = e.target.files[0];
    if (file) {
      compressImage(file);
      getImageBase64(file,
        data => {
          addImageToSignature(data);
        })
    }
  }

  function compressImage(file: Blob) {
    getImgCompressed(file).then(file => {
      setFile(new File([file], `${processId}_${getFieldSignature(signatureSelected.value)}.png`))
    });
  }

  function addImageToSignature(url: string) {
    if (signatureSelected) {
      const img = getImg(url);
      const nImage = {img, field: signatureSelected.value};
      const existImg = images.find(i => i.field === signatureSelected.value);

      setPreviewDoc(() =>
        previewDoc.replace(existImg ? existImg.img : layout(signatureSelected.value), img));
      setModalSignature(false);
      if (existImg) {
        setImages(i => i.map(g => {
          if (g.field === existImg.field) {
            return nImage;
          }
          return g;
        }));
      } else {
        setImages(e => [...e, nImage])
      }
    }
  }

  function deleteImage() {
    let newPrev = previewDoc;
    images.forEach(i => newPrev = newPrev.replace(i.img, layout(i.field)));
    setPreviewDoc(newPrev);
    setImages([]);
    setFile(null);
  }

  useEffect(() => {
    if (previewItem) {
      const {text, signatures} = replaceDefaultSignature(previewItem);
      setSignatures(signatures);
      setPreviewDoc(text);
    }
  }, [previewItem]);

  const exist = images.find(i => i.field === signatureSelected.value);

  function onFinishFlow() {
    !images.length && signatures.length ?
      setConfirm(true) :
      finish(previewDoc, signatures, [], file)
  }

  function onDownload() {
    download(previewDoc, signatures);
  }

  return (
    <>
      <div>
        {loading ? (
          <div className="text-center">
            <FontAwesomeIcon icon={faSpinner} spin size="3x"/>
            <h6 className="mt-2">Creando vista previa...</h6>
          </div>
        ) : (
          <div style={{color: 'black'}} className="bg-white rounded shadow p-4 mb-5 container__preview_doc">
            <div id="preview_pdf" dangerouslySetInnerHTML={{__html: previewDoc}}/>
          </div>
        )}

        <input type="file" className="d-none" accept="image/*" id="file_signature" onChange={signature}/>
        {!loading && !!signatures.length && (
          <>
            <label>Campos de firmas:</label>
            <div className="d-flex">
              <FormGroup className="w-50">
                <Select
                  options={signatures.map(s => ({label: getFieldSignature(s), value: s}))}
                  value={signatureSelected}
                  onChange={e => {
                    setSignature(e.value);
                    setSignatureSelected(e);
                    deleteImage();
                  }}/>
              </FormGroup>
              {signatureSelected && (
                <div className="w-50 pl-2">
                  <Button color="primary"
                          onClick={() => document.getElementById("file_signature")?.click()}>
                    <FontAwesomeIcon icon={faFileImage}/>
                  </Button>
                  <Button color="primary"
                          className="mx-2"
                          onClick={() => setModalSignature(true)}>
                    <FontAwesomeIcon icon={faPencilAlt}/>
                  </Button>
                  {exist && < Button color="light" onClick={deleteImage}>
                    <FontAwesomeIcon icon={faTrash}/>
                  </Button>}
                </div>
              )}
            </div>
          </>
        )}
      </div>
      <ModalFooter className="d-flex justify-content-end">
        <>
          {!userPending && <Button
            disabled={loading}
            color="primary"
            className="mr-2"
            onClick={() => toggle && toggle(false)}>
            Seguir Editando
          </Button>}

          <Button
            color="primary"
            disabled={loading}
            className="mr-2"
            onClick={onDownload}>
            <FontAwesomeIcon className="mr-2" icon={faFileDownload}/>
            Descargar
          </Button>

          <Button
            color="primary"
            disabled={loading}
            className="mr-2"
            onClick={onFinishFlow}>
            <FontAwesomeIcon className="mr-2" icon={faFilePdf}/>
            Finalizar
          </Button>
        </>
      </ModalFooter>
      <SignatureModal open={modalSignature} setOpen={setModalSignature} getImageUrl={urlBase64 => {
        const nFile = dataURLtoFile(urlBase64, "new file");
        compressImage(nFile);
        addImageToSignature(urlBase64);
      }}/>

      <ModalComponent
        isOpen={confirm}
        labelSuccess={"Sí"}
        labelCancel={"No"}
        onSuccess={() => {
          finish(previewDoc, signatures, [], file);
          setConfirm( false);
        }}
        toggle={() => setConfirm(false)}>
        <div>
          {"¿Está seguro que desea finalizar el documento sin haber sido firmado?"}
        </div>
      </ModalComponent>
    </>
  )
}

export default PreviewDocumentComponent;
