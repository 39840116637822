import * as React from "react";
import * as Styles from "../styles";
import {LabelProps} from "../Models/LabelProps";


const LabelComponent: React.FC<LabelProps> = ({
                          inputId,
                          icon,
                          children,
                          information,
                          className
                        }) => {
  return <label style={Styles.p_relative} className={className || ""} htmlFor={inputId}>
    {children}
    <div title={information} style={Styles.iconToolTip}>{icon}</div>
  </label>
};

export default LabelComponent;
