import WelcomeComponent from "../Components/WelcomeComponent";
import LandingComponent from "../Components/Landing/LandingComponent";
import LogInComponent from "../Components/AuthComponent/LogInComponent";
import SignUpComponent from "../Components/AuthComponent/SignUpComponent";
import RestorePasswordComponent from "../Components/AuthComponent/RestorePasswordComponent";
import ForgotPasswordComponent from "../Components/AuthComponent/ForgotPasswordComponent";

import DocumentsComponent from '../Components/Documents/DocumentsComponent';
// import SectionStepsComponent from "../Components/DocumentFlowComponent/SectionSteps/SectionStepsComponent";
import ProfileComponent from "../Components/ProfileComponent/ProfileComponent";
import DocumentsCreatedComponent from "../Components/Documents/DocumentsCreatedComponent/DocumentsCreatedComponent";
import SignatureDetailComponent from "../Components/Documents/SignatureTemplateComponent/SignatureDetailComponent";
import FlowComponent from "../Components/FlowComponent";
import DocumentPreviewComponent from "../Components/Documents/DocumentPreviewComponent/DocumentPreviewComponent";
import PreviewFileComponent from "../Components/Shared/PreviewFileComponent";
import TermComponent from "../Components/TermComponent/TermComponent";
import PrivacyComponent from "../Components/PrivacyComponent/PrivacyComponent";

export const Routes = [
  {id: 1, path: "/home", component: WelcomeComponent},
  {id: 2, path: "/", exact: true, component: LandingComponent},
  {id: 3, path: "/login", component: LogInComponent},
  {id: 4, path: "/sign_up", component: SignUpComponent},
  {id: 5, path: "/forgot_password", component: ForgotPasswordComponent},
  {id: 6, path: "/restore_password", component: RestorePasswordComponent},
  {id: 7, path: "/document/section/:key", exact: true, component: FlowComponent},
  {id: 8, path: "/document/:key", exact: true, component: DocumentPreviewComponent},
  {id: 9, path: "/documents/", exact: true, component: DocumentsComponent},
  {id: 10, path: "/profile", exact: true, component: ProfileComponent},
  {id: 11, path: "/documents_created/", exact: true, component: DocumentsCreatedComponent},
  {id: 12, path: "/document/detail/:workflowId/:email", exact: true, component: SignatureDetailComponent},
  {id: 13, path: "/download/:file_key", exact: true, component: PreviewFileComponent},
  {id: 14, path: "/terms", exact: true, component: TermComponent},
  {id: 15, path: "/privacy", exact: true, component: PrivacyComponent}
];
