import React, {useState} from "react";
import {DateRangePicker} from "react-dates";
import moment, {Moment} from "moment";

interface IProps {
  value: { startDate: Date | null, endDate: Date | null };
  id: string;
  required?: boolean;
  readonly?: boolean;
  disabled?: boolean;
  block?: boolean;
  placeholderStart?: string;
  placeholderEnd?: string;
  onFocusedChange?: (e: Boolean) => void;
  isOutsideRange?: (day?: Moment) => boolean;
  displayFormat?: string | (() => string);
  onChange?: (date: { startDate: Date | null, endDate: Date | null }) => void;
  orientation?: "vertical" | "horizontal"
}

const DateRangeComponent: React.FC<IProps> = (props) => {

  const {
    value,
    onChange,
    id,
    disabled,
    readonly,
    required,
    placeholderStart,
    placeholderEnd,
    block = true,
    displayFormat,
    isOutsideRange,
    onFocusedChange,
    orientation = "horizontal"
  } = props;
  const [focused, setFocused] = useState<any>(null);

  return (
    <div className="date-picker-component">
      <DateRangePicker
        disabled={disabled}
        readOnly={readonly}
        orientation={orientation}
        required={required}
        endDatePlaceholderText={placeholderEnd}
        startDatePlaceholderText={placeholderStart}
        block={block}
        displayFormat={displayFormat}
        isOutsideRange={isOutsideRange}
        startDateId={`start_date_id_` + (id || new Date().getDay())}
        endDateId={`end_date_id_` + (id || new Date().getDay())}
        startDate={value.startDate ? moment(value.startDate) : null}
        endDate={value.endDate ? moment(value.endDate) : null}
        onDatesChange={({startDate, endDate}) => onChange && onChange({
          startDate: startDate ? startDate.toDate() : null,
          endDate: endDate ? endDate.toDate() : null
        })}
        focusedInput={focused}
        onFocusChange={e => {
          setFocused(e)
          if (onFocusedChange) {
            onFocusedChange(Boolean(e))
          }
        }}
      />
    </div>
  );
};

export default DateRangeComponent;
