import {SbxCoreService, SbxSessionService} from "sbx-axios";
import environment from "../config/environment";
import env from "../config/environment";
import Axios, {AxiosError, AxiosResponse} from "axios";
import Response from "../Models/Response";
import Emitter from "../Services/Emitter";

const sbxCoreService = new SbxCoreService();
const sbxSessionService = new SbxSessionService(sbxCoreService);
sbxSessionService.initialize(
  parseInt(environment.domain.toString(), 10),
  environment.appKey
);

sbxCoreService.httpClient.interceptors.response.use(
  function (response: any) {
    // Do something with response data'
    if (!response.success && response.error === 'Invalid Token or App-Key provided.') {
      validateSession();
    }
    return response;
  },
  function (error) {
    // Do something with response error
  }
);


export function setHeaderToken(token: string) {
  httpProcess.defaults.headers = {Authorization: "Bearer " + token};
  http.defaults.headers = {Authorization: "Bearer " + token};
}


const http = Axios.create({
  baseURL: env.formUrl,
  headers: {
    'authorization': `Bearer ${localStorage.getItem("access_token")}`,
  }
});
export const httpProcess = Axios.create({
  baseURL: `${env.processUrl}/api/v2/`,
  headers: {
    'authorization': `Bearer ${localStorage.getItem("access_token")}`,
  }
});

http.interceptors.response.use(function (response: AxiosResponse) {
  return response;
}, errorResponse);


httpProcess.interceptors.response.use(function (response: AxiosResponse) {
  return response;
}, errorResponse);

function errorResponse(error: AxiosError) {
  if (!error.response?.status || !(error.response.status >= 200 && error.response.status <= 300)) {
    validateSession();
  }
}

function validateSession() {
  Emitter.emit("SESSION", "expired");
}

export function get(url: string, params?: any): Promise<Response<any>> {
  return http.get(url, {params}).then(res => res.data).catch(reason => Promise.reject(new Error(reason.statusText)));
}

export function post(url: string, params?: any): Promise<Response<any>> {
  return http.post(url, params).then(res => {
    return res.data ? res.data : res;
  }).catch(reason => {
    return Promise.reject(reason);
  });
}

export {sbxSessionService, sbxCoreService};
