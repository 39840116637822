import React, {useEffect} from 'react';
import './App.scss';
import {Router} from 'react-router';
import history from './Utils/History';
import {useDispatch, useSelector} from 'react-redux';
import Main from './Components/Main/Main';
import SpinnerComponent from './Shared/SpinnerComponent';
import {AuthToken} from './Utils';
import TopBarComponent from './Components/TopBarComponent/TopBarComponent';
import {actionsAuth} from './Store/Auth/Slice';
import {RootState} from './Store/Reducers';
import {State} from "./Store/Auth/Types";
import {AuthorityComponent} from "./Components/AuthorityComponent/AuthorityComponent";
import ModalContainer from "./Components/Shared/Modals/ModalContainer";

function App() {
  const dispatch = useDispatch();

  const {authenticating, state} = useSelector(
    (state: RootState) => state.authReducer,
  );

  useEffect(() => {
    if (AuthToken) {
      dispatch(actionsAuth.validateToken(AuthToken));
    }
  }, [dispatch]);


  return (
    <AuthorityComponent>
      <ModalContainer/>
      <Router history={history}>
        <TopBarComponent/>
        <SpinnerComponent
          loading={authenticating || state === State.PENDING}
          className="loading-spinner"
        />
        <Main/>
      </Router>
    </AuthorityComponent>
  );
}

export default App;
