import React, {useCallback, useEffect, useState} from 'react';
import {goToCreateDocument} from '../../Utils';
import './documentsComponent.scss';
import Document from '../../Models/Document';
import {useHistory} from 'react-router-dom';
import {History} from 'history';

import TextTruncate from 'react-text-truncate';
import {actionsAuth} from "../../Store/Auth/Slice";
import {State} from "../../Store/Document/Types";
import {useDispatch, useSelector} from "react-redux";
import {Roles} from "../../Models/roles";
import {RootState} from "../../Store/Reducers";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

type Props = {
  document: Document;
}

const DocumentComponent: React.FC<Props> = ({document}) => {

  const history = useHistory();
  const token = localStorage.getItem("token");
  const {
    authReducer: {state: stateAuth, user: {role}}
  } = useSelector((state1: RootState) => state1);
  const [showMore, setShowMore] = useState<number | false>(3);
  const goTo = (document: Document, history: History, route: string) => {
    goToCreateDocument(document._KEY, history, route);
  };
  const [validate, setValidate] = useState(false);
  const loadingAuth = stateAuth === State.PENDING;

  const dispatch = useDispatch();

  const gotToCallback = useCallback(goTo, [goTo]);

  useEffect(() => {
    if (stateAuth === State.RESOLVED && role === Roles.GUEST && validate) {
      gotToCallback(document, history, '/document/section/');
    }
  }, [stateAuth, validate, document, role,history, gotToCallback])


  function validateSession() {
    if (token) {
      gotToCallback(document, history, '/document/section/')
    } else {
      setValidate(true);
      dispatch(actionsAuth.loginAsGuest());
    }
  }

  return <div className="d-flex ">
    <div className="d-flex flex-column w-75 pr-3 pr-lg-0">
      <h3 className="title-22 pointer" onClick={() => validateSession()}>{document.name}</h3>
      <TextTruncate text={document.description || ""}
                    containerClassName="font-default"
                    textTruncateChild={
                      <span className="text-info pointer" onClick={() => setShowMore(false)}>
                        <small>Leer...</small>
                      </span>}
                    line={showMore} element="span"
                    truncateText="..."/>
    </div>
    <div className="w-25 my-auto d-flex justify-content-center">
      <button disabled={loadingAuth} type="button"
              className="btn btn-outline-primary document-container-button p-1 p-lg-2"
              onClick={() => goTo(document, history, '/document/')}>{loadingAuth && (<FontAwesomeIcon className="ml-2" icon={faSpinner} spin/> )}Ver Detalle</button>

    </div>
  </div>;
};

export default DocumentComponent;
