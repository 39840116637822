import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {ModalState, typeModal} from "../Modals/Types";

const defaultValue = {open: false, data: null}

const initialState: ModalState = {
  modals: {
    [typeModal.SIGN_UP_MODAL]: defaultValue,
    [typeModal.PREVIEW_ITEM_MODAL]: defaultValue,
    [typeModal.SEND_EMAIL_MODAL]: defaultValue
  }
};

const slice = createSlice({
  name: 'modals',
  initialState,
  reducers: {
    openModal(state, action: PayloadAction<{ type: typeModal, data?: any }>) {
      state.modals[action.payload.type] = {open: true, data: action.payload.data}
    },
    closeModal(state, action: PayloadAction<{ type: typeModal }>) {
      state.modals[action.payload.type] = {open: false, data: null}
    }
  }
});

export const actionsModal = slice.actions;
export default slice.reducer;
