import React, {CSSProperties} from 'react';
import NumberFormat, {NumberFormatValues} from "react-number-format";

interface IProps {
  id: string,
  name: string,
  value: string,
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  className?: string,
  onChange?: (value: string) => void
}

const DocumentComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder
  } = props;

  return <NumberFormat id={id} required={required} name={name}
                       style={style} className={`form-control ${className || ''} ${invalid ? 'invalid' : ''}`}
                       placeholder={placeholder} value={value} thousandSeparator="."
                       decimalSeparator="," decimalScale={0} allowNegative={false}
                       onValueChange={(values: NumberFormatValues) => onChange ? onChange(values.value) : null}
  />;
};

export default DocumentComponent;
