import {combineReducers} from "redux";
import authReducer from "../Auth/Slice";
import documentReducer from '../Document/Slice';
import categoryReducer from '../Category/Slice';
import formExecutionReducer from '../FormExecution/Slice';
import ProfileReducer from '../Profile/Slice';
import modalReducer from "../Modals/Slice";

const RootReducer = combineReducers({
  authReducer,
  documentReducer,
  formExecutionReducer,
  categoryReducer,
  modalReducer,
  ProfileReducer
});

export default RootReducer;
export type RootState = ReturnType<typeof RootReducer>;
