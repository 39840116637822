import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Store/Reducers";
import {State} from "../../Store/Auth/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import {checkMail, toast} from "../../Utils";
import {actionsProfile} from "../../Store/Profile/Slice";
import {plainToClass} from "class-transformer";
import {User} from "../../Models/User";

class StateInfo {
  name!: string;
  email!: string;
  last_name!: string
}

export default () => {

  const {
    authReducer: {
      user: {
        name, email, last_name, _KEY
      }
    },
    ProfileReducer: {state}
  } = useSelector((state: RootState) => state);


  const loading = state === State.PENDING;
  const [edit, setEdit] = useState<boolean>(false);
  const [info, setInfo] = useState<StateInfo>(new StateInfo());
  const dispatch = useDispatch();

  useEffect(() => {
    setInfo({name, email, last_name})
  }, [name, email, last_name])

  const saveInformation = () => {
    if (checkMail(info.email) && info.name &&
      (info.email !== email || info.name !== name || info.last_name !== last_name)) {
      dispatch(actionsProfile.updateUser(plainToClass(User, {name: info.name, last_name: info.last_name, _KEY})))
    } else {
      toast("Esta es tu informacion actual", "warning")
    }
  }


  useEffect(() => {
    if (state === State.RESOLVED) {
      setEdit(false);
    }
  }, [state])

  return (
    <div className="card border-light shadow-sm">
      <div className="card-header border-light">
        <h5>Información personal.</h5>
      </div>
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-lg-2 col-3 py-2 d-flex align-items-center">
              <label>
                <b>Name:</b>
              </label>
            </div>
            <div className="col-lg-10  col-9 py-2">
              {!edit ? name : (
                <input value={info.name} onChange={e => setInfo({...info, name: e.target.value})} type="text"
                       className="form-control"/>
              )}
            </div>
            <div className="col-lg-2 col-3 py-2 d-flex align-items-center">
              <label>
                <b>Apellido:</b>
              </label>
            </div>
            <div className="col-lg-10  col-9 py-2">
              {!edit ? last_name : (
                <input value={info.last_name} onChange={e => setInfo({...info, last_name: e.target.value})} type="text"
                       className="form-control"/>
              )}
            </div>
            <div className="col-lg-2  col-3  py-2 d-flex align-items-center">
              <label>
                <b>Email:</b>
              </label>
            </div>
            <div className="col-lg-10 col-9 py-2">
              {!edit ? email : (
                <input style={{cursor: "not-allowed"}} disabled value={info.email}
                       onChange={e => setInfo({...info, email: e.target.value})} type="email"
                       className="form-control "/>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer bg-transparent border-0 ">
        {!edit ? (
          <button onClick={() => setEdit(true)} className="btn btn-primary btn-sm">Editar perfil</button>
        ) : (
          <>
            <button disabled={loading} onClick={saveInformation} className="btn btn-primary btn-sm mr-2">
              Guardar
              {loading && <FontAwesomeIcon className="ml-2" icon={faSpinner} spin/>}
            </button>
            <button disabled={loading} onClick={() => setEdit(false)} className="btn btn-light btn-sm">
              Cancelar
            </button>
          </>
        )}
      </div>
    </div>
  )
}
