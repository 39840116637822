import React, {ReactElement, useEffect, useState} from 'react';
import {actions} from '../../Store/FormExecution/Slice';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../Store/Reducers';
import {FieldValue} from '../../Models/IField';
import {ChildrenProps} from '../Models/ChildrenProps';
import {State} from '../../Store/FormExecution/Types';
import ModalComponent from '../../Components/Shared/Modals/ModalComponent/ModalComponent';
import {actionsModal} from "../../Store/Modals/Slice";
import {typeModal} from "../../Store/Modals/Types";
import {convertStringToPdf, layout, replaceLayoutByDefaultLine, toast} from "../../Utils";
import PreviewDocumentComponent from "./PreviewDocumentComponent";
import SpinnerComponent from "../../Shared/SpinnerComponent";
import {incrementDownload} from "../../Services";


type Props = {
  onSubmit: (data: any) => void;
  onBack: () => void;
  onFinish: (tryDownload?: boolean) => void;
  values: any[];
  children(data: ChildrenProps): ReactElement;
  processId: number;
};

const FormExecutionComponent: React.FC<Props> = props => {
    const {onFinish} = props;
    const dispatch = useDispatch();
    const [openModal, setOpenModal] = useState(false);

    const [initializing, setInitializing] = useState(false);

    const {
      formExecutionReducer: {
        task, state, previewItem
      }
    } = useSelector(
      (state: RootState) => state
    );
    const {
      authReducer: {user: {role, _KEY: user_key, folder_key}},
      documentReducer: {currentDocument}
    } = useSelector((state: RootState) => state);

    const nextVal = task?.actions.find(i => i.name === 'NEXT');
    const finish = task?.actions.find(i => i.label === 'Finalizar');
    const backVal = task?.actions.find(i => i.name === 'BACK');

    const [signature, setSignature] = useState("");

    useEffect(() => {
      dispatch(actions.getLaunchProcess({processId: props.processId}));
      setInitializing(true)
    }, [dispatch, props.processId]);

    useEffect(() => {
      if (state === State.PROCESS_FINISHED && role !== "GUEST") {
        setOpenModal(true);
        onFinish();
      }
    }, [onFinish, role, state]);

    useEffect(() => {
      if (initializing && state === State.RESOLVED) {
        setInitializing(false)
      }
    }, [initializing, state])

    useEffect(() => {
      if (state === State.PROCESS_FINISHED && role === "GUEST") {
        dispatch(actionsModal.openModal({type: typeModal.SIGN_UP_MODAL}));
      }
    }, [dispatch, state, role]);

    const submit = (actionId: number, fields: FieldValue[], action?: "back") => {
      if (task) {
        if (!finish || action === "back") {
          dispatch(
            actions.getFinishTask({
              formId: task.attachments[0].target_item_id,
              actionId,
              fields,
              processExecId: task.id,
            }),
          );
        } else {
          dispatch(
            actions.getSaveTask({taskId: task.id, fields}),
          );
        }
      }
    };

    function finishExecution(prev: string, signatures: string[],
                             emails: {
                               email: string,
                               field_signature: string
                             }[], file: File | null) {
      const temp = signatures.filter(s => prev.includes(layout(s)));
      if (task && nextVal) {
        if (!temp.length) {
          convertStringToPdf(replaceLayoutByDefaultLine(prev, signatures), {
              name: `${currentDocument.name}_${new Date().getTime()}`,
              download: true
            },
            filePdf => {
              dispatch(
                actions.getFinishTask({
                  formId: task.attachments[0].target_item_id,
                  actionId: nextVal.id,
                  fields: props.values,
                  processExecId: task.id,
                  document_user: {
                    emails,
                    user: user_key,
                    document_key: currentDocument.document_key,
                    dataFile: previewItem,
                    signature: file ? {file, field_signature: signature} : null,
                    workflowId: task.process_execution_id,
                    file: filePdf,
                    folder_key
                  }
                }),
              );
            });
        } else {
          dispatch(actionsModal.openModal({
            type: typeModal.SEND_EMAIL_MODAL,
            data: {
              signatures: temp,
              preview: prev,
              finishExecution,
              file: file,
              document_name: currentDocument.name
            }
          }))
        }
      } else {
        toast("Falta agregar la firma", "warning")
      }
    }

    return task && !initializing ? (
      <>
        <form
          onSubmit={event => {
            event.preventDefault();
            nextVal && submit(nextVal.id, props.values);
          }}>
          {props.children({
            ...props,
            onSubmit: submit,
            task,
            form: task.attachments[0].form,
          })}
          <button type="submit"
                  style={{opacity: 0, position: 'absolute'}}
                  id="next_form_execution_button"
          />
        </form>
        <button
          onClick={() => {
            backVal && submit(backVal.id, props.values, "back");
          }}
          style={{opacity: 0, position: 'absolute'}}
          id="back_form_execution_button"
        />
        {role !== "GUEST" && <ModalComponent
          zIndex={100}
          isOpen={openModal}
          size="lg"
          title={"Vista previa y Finalización del documento"}
          noFooter
          toggle={() => null}>
          <PreviewDocumentComponent
            previewItem={previewItem}
            toggle={setOpenModal}
            setSignature={setSignature}
            processId={task.process_execution_id}
            loading={state === State.PENDING}
            download={(preview, signatures) => {
              const text = replaceLayoutByDefaultLine(preview, signatures)
              convertStringToPdf(text,
                {name: currentDocument.name, download: true}, () => {
                  incrementDownload(currentDocument._KEY, 1);
                })
            }}
            finish={finishExecution}/>
        </ModalComponent>}
      </>
    ) : (
      <SpinnerComponent
        loading={true}
        className="loading-spinner"/>
    );
  }
;

export default FormExecutionComponent;
