import React, {useState} from 'react';
import logo from '../../Assets/Images/logo.png';
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  UncontrolledDropdown
} from 'reactstrap';
import history from '../../Utils/History';
import {Link as NavLink} from 'react-router-dom';
import {sbxSessionService} from '../../Network';
import {useDispatch, useSelector} from 'react-redux';
import './topBarComponent.scss';
import {toggleClassWithClass} from '../../Utils';
import {actionsAuth} from '../../Store/Auth/Slice';
import {RootState} from '../../Store/Reducers';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCog, faSignOutAlt, faUserCircle} from "@fortawesome/free-solid-svg-icons";
import AutoSuggestDocComponent from "../Shared/AutoSuggestDocComponent/AutoSuggestDocComponent";
import {clearStorage} from "../../Services";

const TopBarComponent = () => {
  const dispatch = useDispatch();

  const {logged, user: {name, last_name, role}} = useSelector((state: RootState) => state.authReducer);

  const [isOpen, setIsOpen] = useState(false);
  const toggle: () => void = () => {
    toggleClassWithClass('body', 'modal-open');
    setIsOpen(prev => !prev);
  };

  const redirectTo = (route: string) => {
    history.push(route);
  };
  const [location, setLocation] = useState(history.location.pathname.replace('/', ''));
  history.listen(location => {
    setIsOpen(false);
    setLocation(location.pathname.replace('/', ''));
  });

  const validateSession = () => {
    if (logged) {
      sbxSessionService.logout();
      localStorage.clear();
      dispatch(actionsAuth.restoreValues());
      history.push('/');
    } else {
      history.push('/login');
    }
  };

  const onClose = () => {
    clearStorage({reload: true, pathHistory: "login"})
  }

  return (
    <Navbar color="light" light expand="lg" className={`vh-10 ${isOpen ? 'shadow' : ''}`}>
      <NavbarBrand href="http://simplelegalapp.co" className="pl-md-2 ">
        <img src={logo}
             className="d-none d-md-inline"
             style={{height: '50px'}}
             alt=""/>
        <img src={logo}
             className="d-inline d-md-none"
             style={{height: '40px'}}
             alt=""/>
      </NavbarBrand>
      <NavbarToggler className="mr-2" onClick={toggle}/>


      <div className={`${isOpen ? 'navbar-menu text-right p-4' : 'd-none'}`}>
        <span className="navbar-close-button" onClick={toggle}>X</span>
        <ul className="list-group">
          {/*<li className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3">*/}
          {/*  Guías*/}
          {/*</li>*/}
          <li className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3"
              onClick={() => redirectTo('/documents')}>
            Documentos
          </li>
          <li className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3"
              onClick={() => redirectTo('/documents_created')}>
            Documentos creados
          </li>
          <li className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3">
            Ayuda
          </li>
          <li onClick={() => redirectTo("/profile")}
              className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3">
            Configuración
          </li>
          <li className="list-unstyled">
            <svg className="mb-3" width="109" height="5" viewBox="0 0 109 5" fill="none"
                 xmlns="http://www.w3.org/2000/svg">
              <rect width="100" height="5" rx="2.5" fill="#5C6C7F"></rect>
            </svg>
          </li>
          <li className="list-unstyled navbar-menu-text navbar-menu-text-up mb-3">
            <span onClick={() => validateSession()}>{!logged ? 'Iniciar Sesión' : 'Salir'}</span>
          </li>
          <li
            className={`list-unstyled navbar-menu-text navbar-menu-text-up mb-3 ${logged ? 'd-none' : ''}`}>
            <span onClick={() => history.push('/sign_up')}>Registrarme</span>
          </li>
        </ul>
      </div>


      <Collapse navbar>
        <Nav className="ml-auto" navbar>
          <NavItem className="px-3">
            <NavLink className="nav-link" to={'/documents'}>Documentos</NavLink>
          </NavItem>
          {(logged && role !== "GUEST") && <NavItem className="px-3">
            <NavLink className="nav-link" to={'/documents_created'}>Documentos creados</NavLink>
          </NavItem>}
          {/*<NavItem className=" px-3">*/}
          {/*  <NavLink className="nav-link" to="">Guias</NavLink>*/}
          {/*</NavItem>*/}
          <NavItem className=" px-3">
            <form style={{width: '300px'}} className="form-inline my-2 my-lg-0">
              <AutoSuggestDocComponent className="form-control w-100"/>
            </form>
          </NavItem>
          <NavItem className=" px-3">
            {
              location === 'login' ?
                <button onClick={() => history.push('/sign_up')}
                        className="btn btn-primary">Registrarse</button> :
                location === 'home' || logged ?
                  <UncontrolledDropdown direction="left" setActiveFromChild>
                    <DropdownToggle tag="a" caret className="nav-link">
                      <FontAwesomeIcon icon={faUserCircle} size="2x"/>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem header>{`${name} ${last_name !== "undefined" ? last_name : ""}`}</DropdownItem>
                      <DropdownItem divider/>
                      <DropdownItem tag="a" onClick={() => redirectTo("/profile")}>
                        <FontAwesomeIcon icon={faCog}/> Configuración
                      </DropdownItem>
                      <DropdownItem tag="a" onClick={onClose}>
                        <FontAwesomeIcon icon={faSignOutAlt}/> Salir
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                  : <button onClick={() => history.push('/login')}
                            className="btn btn-primary">Iniciar
                    Sesión</button>}
          </NavItem>
        </Nav>

      </Collapse>
    </Navbar>
  );
};
export default TopBarComponent;
