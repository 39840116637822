import React, {useCallback, useEffect, useState} from "react";
import DocumentsListComponent from "./DocumentsListComponent";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../Store/Reducers";
import {actionsDocument} from "../../../Store/Document/Slice";
import {getFileDownloaded, incrementDownload} from "../../../Services";
import ModalComponent from "../../Shared/Modals/ModalComponent/ModalComponent";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faFileDownload, faFilePdf, faTimes} from "@fortawesome/free-solid-svg-icons";
import {capitalize, mobileCheck} from "../../../Utils";
import {CDocument} from "../../../Models/CDocument";
import {FormGroup, ModalFooter} from "reactstrap";
import {State} from "../../../Store/Document/Types";
import MixPanelActions from "../../../Store/MixPanelActions";

const pageSize = 9;

function searchDocs(items: CDocument[], value: string) {
  return items.filter(i => i.name.toLowerCase().indexOf(value.toLowerCase()) !== -1)
}

const DocumentsCreatedComponent = () => {
  const {
    authReducer: {user: {folder_key, _KEY: user_key}},
    documentReducer: {documentsCreated: {rows, row_count}, state}
  } = useSelector((state: RootState) => state);

  const dispatch = useDispatch();
  const [open, setOpen] = useState<any>({open: false, data: null});
  const [loading, setLoading] = useState(false);
  const [value, setValue] = useState("");

  async function getFile(data: any, download?: true) {
    setLoading(true);
    const file = await getFileDownloaded(data.file.key);
    if (mobileCheck() || download) {
      directDownload(file, data.name);
      if (data.document_user.document) {
        await incrementDownload(data.document_user.document, 1);
      }
    } else {
      const newData = {url: URL.createObjectURL(file), ...data, file}
      setOpen({open: true, data: newData});
    }
    setLoading(false);
  }

  function directDownload(file: File, name: string) {
    let a = document.createElement("a");
    a.href = URL.createObjectURL(file);
    a.setAttribute("download", name);
    a.click();
    MixPanelActions.downloadDocument(name);
  }

  useEffect(() => {
    if (folder_key && user_key) {
      dispatch(actionsDocument.getDocumentsCreated({folder_key, user_key, page: 1, pageSize}));
    }
  }, [dispatch, folder_key, user_key]);

  const loadingDocuments = state === State.PENDING;


  const changePage = useCallback((p: number) => {
    dispatch(actionsDocument.getDocumentsCreated({folder_key, user_key, page: p, pageSize}));
  }, [dispatch, folder_key, user_key])

  return (
    <>
      <div className="container mt-2 vh-90">
        <FormGroup className="col-lg-5 col-12 p-0 md-form">
          <input className="form-control"
                 type="search"
                 placeholder="Buscar documento..."
                 value={value}
                 onChange={e => setValue(e.target.value)}
                 aria-label="Search"/>
        </FormGroup>
        <DocumentsListComponent list={searchDocs(rows, value)}
                                loadingItem={loading}
                                pageSize={pageSize}
                                onChangePage={changePage}
                                count={row_count}
                                loadingDocuments={loadingDocuments}
                                events={[
                                  {
                                    type: "primary",
                                    event: getFile,
                                    icon: "file"
                                  },
                                  {
                                    type: "success",
                                    event: e => getFile(e, true),
                                    icon: "download"
                                  }
                                ]}/>

      </div>

      {open.data && <ModalComponent isOpen={open.open}
                                    size="xl"
                                    header={<span><FontAwesomeIcon icon={faFilePdf}
                                                                   className="mr-2"/>{capitalize(open.data.name)}</span>}
                                    noFooter
                                    toggle={() => setOpen({open: false, data: null})}>
        <object style={{height: '85vh'}} data={open.data.url} type="application/pdf" width='100%'
                aria-labelledby="file_pdf" height='100%'/>
        <ModalFooter>
          <button className="btn btn-primary" onClick={() => directDownload(open.data.file, open.data.name)}>
            <FontAwesomeIcon className="mr-2" icon={faFileDownload}/> Descargar
          </button>
          <button onClick={() => setOpen({open: false, data: null})} className="btn btn-light">
            <FontAwesomeIcon className="mr-2" icon={faTimes}/> Cerrar
          </button>
        </ModalFooter>
      </ModalComponent>}
    </>
  );
}

export default DocumentsCreatedComponent;
