import React, {useEffect, useState} from 'react';
import {isValid} from '../../Utils';
import {useDispatch, useSelector} from 'react-redux';
import history from '../../Utils/History';
import {InfoFieldPop} from '../../Shared/PopoversComponent';
import FooterComponent from '../FooterComponent';
import {actionsAuth} from '../../Store/Auth/Slice';
import {RootState} from '../../Store/Reducers';
import {EmailComponent, LabelComponent, PasswordComponent, SmallTextComponent} from "sbx-ui-input-library";
import {Roles} from "../../Models/roles";
import {State} from "../../Store/Auth/Types";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";

const initialUser = {
  user: '',
  name: '',
  email: '',
  password: '',
  cPassword: ''
};

const SignUpComponent = ({modal = false, logIn}: { modal?: boolean, logIn?: () => void }) => {
  const dispatch = useDispatch();
  const {logged, user: {role, _KEY}, state} = useSelector((state: RootState) => state.authReducer);
  const [cred, setCred] = useState(initialUser);
  const token = localStorage.getItem("token") || "";

  const loading = state === State.PENDING;

  function signUp(event: any) {
    event.preventDefault();
    if (isValid(cred.password, cred.cPassword)) {
      if (guest) {
        return dispatch(actionsAuth.signUpTheGuest({...cred, token, key: _KEY}))
      } else {
        return dispatch(actionsAuth.authSignUp(cred));
      }
    } else return
  }

  const guest = (role === Roles.GUEST);

  function goToHistoryEvent(e: any, path: string) {
    e.preventDefault();
    history.push(path);
  }

  useEffect(() => {
    if (logged && !modal) {
      history.push('/documents');
    }
  }, [logged, guest, modal]);

  const isValidPassword = !isValid(cred.password, cred.cPassword) ? 'is-invalid' : 'is-valid'

  return (
    <>
      <div
        className={"bg-waves container-fluid " + (guest ? "" : " vh-90 d-flex justify-content-center align-items-center")}>
        <form onSubmit={e => signUp(e)}
              className={guest ? "w-100" : "col-lg-4 col-md-8 col-12 p-3"}>
          <div className="form-group">
            <LabelComponent children="Nombre completo"
                            inputId="create_name"
                            icon={<InfoFieldPop text="Nombre completo"/>}/>
            <SmallTextComponent id="create_name"
                                name="create_name"
                                value={cred.name}
                                onChange={e => setCred({...cred, name: e})}
                                placeholder="Nombre"/>
          </div>

          <div className="form-group">
            <LabelComponent inputId="create_email"
                            children="Correo electrónico"
                            icon={<InfoFieldPop
                              text="Con el correo electrónico podras iniciar sesión"/>}/>
            <EmailComponent id="create_email"
                            name="create_email"
                            required
                            value={cred.email}
                            onChange={e => setCred({...cred, user: e.split("@")[0], email: e})}
                            placeholder="Correo electrónico"/>
          </div>
          <div className="form-group">
            <LabelComponent inputId="create_password"
                            children="Contraseña"
                            icon={<InfoFieldPop text="Digita una contraseña "/>}/>
            <PasswordComponent id="create_password"
                               name="create_password"
                               required
                               className={isValidPassword}
                               value={cred.password}
                               onChange={e => setCred({...cred, password: e})}
                               placeholder="Contraseña"/>
            <small className="text-danger">{isValid(cred.password, cred.cPassword, true)}</small>
          </div>
          <div className="form-group">
            <LabelComponent inputId="confirm_create_password"
                            children="Contraseña"
                            icon={<InfoFieldPop text="Confirma la contraseña "/>}/>
            <PasswordComponent id="confirm_create_password"
                               name="confirm_create_password"
                               required
                               className={isValidPassword}
                               value={cred.cPassword}
                               onChange={e => setCred({...cred, cPassword: e})}
                               placeholder="Confirma la contraseña"/>
            <small className="text-danger">{isValid(cred.password, cred.cPassword, true)}</small>
          </div>

          <div style={{height: '40px'}} className="text-center  d-flex align-items-center ">
            <small>
              <input required type="checkbox"/>
              {' '}
              Aceptar {' '}
              <a href="/" className="text-primary" onClick={e => goToHistoryEvent(e, "/terms")}>
                Términos & Condiciones
              </a>, y {' '}
              <a href="/" className="text-primary" onClick={e => goToHistoryEvent(e, "/privacy")}>
                Politicas de Privacidad
              </a>
            </small>

          </div>
          <div className="form-group">
            <button disabled={loading} className="btn btn-primary btn-block">
              Registrarse {loading && <FontAwesomeIcon icon={faSpinner} spin/>}
            </button>
          </div>

          <div className="text-center">
            <small>¿Tienes una cuenta? <a href="/" className="text-primary" onClick={e => {
              e.preventDefault();
              if (modal) {
                if (logIn) logIn();
              } else {
                history.push('login');
              }
            }}>Iniciar sesión.</a></small>
          </div>
        </form>
      </div>
      {!guest && <FooterComponent/>}
    </>
  );
};

export default SignUpComponent;
