import React from "react";
import useRequired from "../Hooks/useRequired";
import TextProps from "../Models/TextProps";

const SmallTextComponent: React.FC<TextProps> = ({
  id,
  name,
  value,
  invalid,
  required,
  readonly,
  disabled,
  style,
  placeholder,
  className,
  onChange
}) => {

  const error = useRequired(value, Boolean(required));

  return <input id={id}
                name={name}
                value={value}
                required={required}
                readOnly={readonly}
                disabled={disabled}
                style={style}
                type="text"
                placeholder={placeholder}
                className={`form-control ${className || ''} ${invalid || error ? 'invalid' : ''}`}
                onChange={e => onChange ? onChange(e.target.value) : null}/>;
};

export default SmallTextComponent;
