import {sbxCoreService} from "../Network";
import Axios from "axios";

export const createFolder = (parentKey: string, name: string) => {
  return sbxCoreService.createFolder(parentKey, name);
};

export const uploadFile = (folder_key: string, file: File) => {
  return sbxCoreService.uploadFile(folder_key, file)
}

export const getFolderList = (folder_key: string) => {
  return sbxCoreService.listFolder(folder_key);
}

export const downloadFile = (key: string) => {
  return sbxCoreService.downloadFile(key);
}

export async function downloadFileByUrl(url: string) {
  return Axios.get(url, {responseType: "blob"});
}

export async function getFileDownloaded(key: string) {
  const res = await downloadFile(key);
  if (res.url) {
    const resFile = await downloadFileByUrl(res.url);
    resFile.data.name = res.name;
    return resFile.data;
  }
  return null;
}
