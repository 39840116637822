import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {State} from './Types';
import {Auth, signUp} from '../../Models/Auth';

const initialState = {
  user: {role: "", _KEY: "", name: "", email: "", last_name: "", folder_key: ""},
  logged: false,
  authenticating: false,
  sendingEmail: false,
  EmailSended: false,
  passwordRestored: false,
  changingPassword: false,
  state: State.IDLE,
  session: {oldEvent: "", currentEvent: ""}
};

const slice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setSession(state, action: PayloadAction<string>) {
      state.session = {
        currentEvent: action.payload,
        oldEvent: state.session.currentEvent !== action.payload ? state.session.currentEvent : state.session.oldEvent
      };
    },
    authLogin(state, action: PayloadAction<Auth>) {
      state.state = State.PENDING;
      state.authenticating = true;
    },
    signUpTheGuest(state, action: PayloadAction<signUp>) {
      state.state = State.PENDING;
      state.authenticating = true;
    },
    authSignUp(state, action: PayloadAction<signUp>) {
      state.state = State.PENDING;
      state.authenticating = true;
    },
    authLogged(state, action: PayloadAction<any>) {
      const {user, logged} = action.payload.data;
      state.state = logged ? State.RESOLVED : State.REJECTED;
      state.user = logged ? {...state.user, ...user} : state.user;
      state.logged = logged;
      state.authenticating = false;
    },
    restoreValues(state) {
      return initialState;
    },
    sendedForgotPassword(state, action: PayloadAction<any>) {
      const {success} = action.payload;
      state.state = success ? State.RESOLVED : State.REJECTED;
      state.EmailSended = action.payload.EmailSended;
      state.sendingEmail = false;
    },
    forgotPassword(state, action: PayloadAction<any>) {
      state.state = State.PENDING;
      state.sendingEmail = true;
      state.EmailSended = false;
    },
    restorePassword(state, action: PayloadAction<any>) {
      state.state = State.PENDING;
      state.changingPassword = true;
    },
    passwordRestored(state, action: PayloadAction<any>) {
      const {success} = action.payload;
      state.state = success ? State.RESOLVED : State.REJECTED;
      state.changingPassword = false;
      state.passwordRestored = action.payload.success;
    },
    loginAsGuest(state) {
      state.state = State.PENDING;
    },
    validateToken(state, action: PayloadAction<any>) {
      state.authenticating = true;
      state.state = State.PENDING;
    },
    setState(state, action: PayloadAction<State>) {
      state.state = action.payload
    }
  }
})

export const actionsAuth = slice.actions;
export default slice.reducer;
