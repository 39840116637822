import {httpProcess} from "../../Network";
import Response from "../../Models/Response";
import {Task} from "../Models/Task";
import env from "../../config/environment";

export async function getTaskDetail(taskId: number, processId: number) {
  const res: Response<Task> = await httpProcess.get(`${env.domain}/wf/process/model/${processId}/task/${taskId}`)
    .then(value => value.data);
  return Promise.resolve(res);
}


export function getProcessModel(process_id: number): Promise<any> {
  return httpProcess.get(`/${env.domain}/wf/process/model/${process_id}`)
    .then(e => e.data)
    .catch(reason => {
      return {success: false};
    });
}


