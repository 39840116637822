import React, {useCallback, useEffect, useState} from "react";
import {Col, Row} from "reactstrap";
import SectionsListComponent from "./SectionsListComponent";
import "./sectionSteps.scss";
import FooterControlComponent from "./FooterControlComponent";
import FormContentComponent from "./FormContentComponent";
import {actionsDocument} from "../../Store/Document/Slice";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Store/Reducers";
import {actions} from "../../Store/FormExecution/Slice";
import {actionsModal} from "../../Store/Modals/Slice";
import {typeModal} from "../../Store/Modals/Types";
import FormExecutionComponent from "../../Execution/Components/FormExecutionComponent";
import {getValuesFields} from "../../Utils";
import {FieldValue} from "../../Models/IField";
import {State} from "../../Store/FormExecution/Types";
import SpinnerComponent from "../../Shared/SpinnerComponent";

const FlowComponent = () => {

  const {key}: { [key: string]: string } = useParams();
  const [values, setValues] = useState<{ [key: string]: FieldValue }>({});
  // const [sectionName, setSectionName] = useState("");
  const dispatch = useDispatch();

  const {
    documentReducer: {
      currentDocument: {
        name: documentName,
        _KEY: documentKey,
        workflow_id
      },
      currentForm,
      sections,
      activeSection
    },
    formExecutionReducer: {task, state: stateForm},
  } = useSelector((state1: RootState) => state1);

  const getValues = useCallback(
    e => setValues((v: any) => ({...v, ...e})),
    [],
  );

  useEffect(() => {
    if (key) {
      dispatch(actionsDocument.getSections(key));
      if (!documentKey) {
        dispatch(actionsDocument.getDocument(key));
      }
    }
  }, [dispatch, key, documentKey]);

  useEffect(() => {
    if (task?.task_ref) {
      const section = sections.find(sec => sec.task_id === task.task_ref.task_id)
      if (section) {
        dispatch(actionsDocument.setActiveSection(section))
      }
    }
  }, [task, sections, dispatch])


  useEffect(() => {
    if (workflow_id && task) {
      dispatch(
        actions.getProperties({
          process_id: workflow_id,
          execution_id: task?.process_execution_id || 0,
        }),
      );
    }
  }, [dispatch, task, workflow_id, currentForm]);

  function getPreviewItem() {
    dispatch(actionsModal.openModal({type: typeModal.PREVIEW_ITEM_MODAL}));
    dispatch(
      actions.getPreviewItem({
        documentKey: documentKey,
        processId: workflow_id,
        executionId: task?.process_execution_id || 0,
      }),
    );
  }


  return (
    <>
      <SpinnerComponent
        loading={stateForm === State.PENDING}
        className="loading-spinner"/>
      {workflow_id && (
        <FormExecutionComponent
          values={getValuesFields(values)}
          processId={workflow_id}
          onSubmit={data => {
            console.log(data);
          }}
          onBack={() => {
            console.log('back');
          }}
          onFinish={() => {
            if (task?.process_execution_id) {
              dispatch(
                actions.getPreviewItem({
                  documentKey: documentKey,
                  processId: workflow_id,
                  executionId: task.process_execution_id,
                }),
              );
            }
          }}>
          {props => {
            return (
              <div className="vh-90 col-12">
                <Row className="h-100">
                  <Col
                    xl={3}
                    lg={4}
                    md={5}
                    className="section-bg d-none p-0 d-md-inline shadow-sm h-100">
                    <SectionsListComponent
                      title={documentName}
                      size={93}
                      sections={sections}
                      selected={activeSection}/>
                    <FooterControlComponent
                      actions={props.task.actions}
                      size={7}
                      getPreview={getPreviewItem}
                      loading={stateForm === State.PENDING}/>
                  </Col>
                  <Col
                    xl={9}
                    lg={8}
                    md={7}
                    sm={12}
                    className="h-100 pt-2 pt-md-5">
                    <FormContentComponent
                      loading={stateForm === State.PENDING}
                      section={activeSection}
                      fieldsValues={values}
                      form={props.form}
                      getValues={getValues}
                      task={props.task}
                      preview={getPreviewItem}
                      actions={props.task.actions}
                      sections={sections}/>
                  </Col>
                </Row>
              </div>
            );
          }}
        </FormExecutionComponent>
      )}
    </>
  )
}

export default FlowComponent;
