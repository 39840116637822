import React, {useEffect, useState} from 'react';
import {useLocation} from 'react-router-dom';
import {toast} from '../../Utils';
import {useDispatch, useSelector} from 'react-redux';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faCheckCircle, faSpinner} from '@fortawesome/free-solid-svg-icons';
import history from '../../Utils/History';
import {actionsAuth} from '../../Store/Auth/Slice';
import {RootState} from '../../Store/Reducers';
import {LabelComponent, PasswordComponent} from "sbx-ui-input-library";

export default () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const [info, setInfo] = useState({user_id: '', code: '', email: ''});
  const [values, setValues] = useState({np: '', cp: ''});
  const {passwordRestored, changingPassword} = useSelector(
    (state: RootState) => state.authReducer);

  useEffect(() => {
    if (location) {
      let arr = location.search
        .replace('?', '')
        .replace('user_id=', '')
        .replace('code=', '')
        .replace('email=', '')
        .split('&');
      setInfo({user_id: arr[0], code: arr[1], email: arr[2]});
    }
  }, [location]);

  function sendPassword(e: any) {
    e.preventDefault();
    if (values.cp !== values.np) {
      toast('Las contraseñas no coinciden', 'error');
    } else {
      dispatch(
        actionsAuth.restorePassword({
          user_id: info.user_id,
          code: info.code,
          password: values.np,
          email: info.email,
        }),
      );
    }
  }

  return (
    <div className="container-fluid vh-90 d-flex justify-content-center align-items-center">
      {passwordRestored ? (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="text-success fa-4x mb-2"
          />
          <p>
            <b>Se ha modificado tu contraseña exitosamente</b>
          </p>
          <a
            href="/"
            onClick={e => {
              e.preventDefault();
              dispatch(actionsAuth.restoreValues());
              history.push('/login');
            }}>
            <small>Iniciar sesión</small>
          </a>
        </div>
      ) : (
        <form className="col-lg-4 col-md-8 col-12" onSubmit={sendPassword}>
          <div className="form-group">
            <LabelComponent inputId="new_password" children="Nueva contraseña"/>
            <PasswordComponent id="new_password"
                               name="new_password"
                               required
                               placeholder="Nueva contraseña"
                               value={values.np}
                               onChange={e => setValues({...values, np: e})}
                               className={values.np !== values.cp ? 'is-invalid' : 'is-valid'}
            />

          </div>

          <div className="form-group">

            <LabelComponent inputId="confirm_password" children="Nueva contraseña"/>
            <PasswordComponent id="confirm_password"
                               name="confirm_password"
                               required
                               placeholder="confirma la contraseña"
                               value={values.cp}
                               onChange={e => setValues({...values, cp: e})}
                               className={values.np !== values.cp ? 'is-invalid' : 'is-valid'}
            />

          </div>
          <div className="text-center">
            <button
              disabled={changingPassword}
              className="btn btn-primary btn-block">
              {changingPassword && <FontAwesomeIcon icon={faSpinner} spin/>}{' '}
              Cambiar contraseña
            </button>
          </div>
        </form>
      )}
    </div>
  );
};
