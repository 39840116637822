/* add css styles here (optional) */

import {CSSProperties} from "react";

export const iconToolTip: CSSProperties = {
  position: "absolute",
  top: "-3px",
  right: "-18px",
  cursor: "in"
};


export const p_relative: CSSProperties = {
  position: "relative"
};

export const d_flex: CSSProperties = {
  display: "flex"
};

export const w_10: CSSProperties = {
  width: "10%"
};

export const w_20: CSSProperties = {
  width: "20%"
};


export const w_30: CSSProperties = {
  width: "30%"
};

export const w_40: CSSProperties = {
  width: "40%"
};

export const w_50: CSSProperties = {
  width: "50%"
};

export const w_60: CSSProperties = {
  width: "60%"
};


export const w_70: CSSProperties = {
  width: "70%"
};

export const w_80: CSSProperties = {
  width: "80%"
};

export const w_90: CSSProperties = {
  width: "90%"
};

export const w_100: CSSProperties = {
  width: "100%"
};

export const jcc: CSSProperties = {
  justifyContent: "center"
};

export const jcs: CSSProperties = {
  justifyContent: "start"
};

export const jce: CSSProperties = {
  justifyContent: "end"
};

export const pl_1: CSSProperties = {
  paddingLeft: "10px"
};

export const pl_2: CSSProperties = {
  paddingLeft: "20px"
};

export const pl_3: CSSProperties = {
  paddingLeft: "30px"
};

export const pl_4: CSSProperties = {
  paddingLeft: "40px"
};

export const pl_5: CSSProperties = {
  paddingLeft: "50px"
};

export const oFlowV: CSSProperties = {
  overflow: "visible"
};
