import React from 'react';
import inmobiliario from '../../Assets/Icons/inmobiliario.svg';
import {Category} from '../../Models/Category';
import ImageComponent from "../../Shared/ImageComponent";

type Props = {
  category: Category;
}

const CategoryHeaderComponent: React.FC<Props> = ({category}) => {


  return <div className="card primary-bg">
    <div className="d-flex flex-wrap flex-lg-nowrap align-items-center justify-content-between p-4">
      <div className="order-2 order-lg-0">
        <h2 className="text-white category-title">{category && category.name}</h2>
        <p
          className="category-description font-default">{category.description || 'If you’re running a business,\n' +
        ' you will require legal documents. Find any\n' +
        ' business legal form you need,\n' +
        ' and let our software help you write it in a matter of minutes.'}</p>
      </div>
      <ImageComponent src={`${category.icon}`}
                      errorSrc={inmobiliario}
                      alt="inmobiliario" className="ml-1 br-5" style={{width: "80px"}}/>
    </div>
  </div>
}

export default CategoryHeaderComponent;
