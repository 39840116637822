/* eslint no-eval: 0 */
import {default as iziToast} from 'izitoast';
import {
  faBuilding,
  faBullhorn,
  faHandHoldingUsd,
  faHandshake,
  faHardHat,
  faWarehouse,
} from '@fortawesome/free-solid-svg-icons';
import {History} from 'history';
import IField, {FieldValue} from '../Models/IField';
import {ChildType, FieldType} from '../Models/FieldTypes';
import {CONDITIONS} from './Constants';
import Compressor from 'compressorjs';
import {convertStringToPdfService} from "../Services";
import {Category} from "../Models/Category";

declare var document: Document;
declare var window: any;
export const AuthToken = localStorage.getItem('token');

export const checkMail = (mail: string) => {
  return mail.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
};

export const categoryImgUrl =
  'https://sbxcloud.com/www/simplelegaldev/images/category/category_';

export const signatureUrl = 'https://sbxcloud.com/www/simplelegaldev/signatures/';

export function toast(message: string, type: string = 'success'): void {
  const toast: any = {
    message: message,
    position: 'topRight',
    displayMode: 2,
    closeOnClick: true,
  };
  switch (type) {
    case 'error':
      iziToast.error(toast);
      break;
    case 'info':
      iziToast.info(toast);
      break;
    case 'warning':
      iziToast.warning(toast);
      break;
    default:
      const config = {...toast, backgroundColor: "#383EB3", messageColor: "#FFF", iconColor: "#FFF"};
      iziToast.success(config);
      break;
  }
}

export function isValid(value: any, compare: string, message?: boolean) {
  if (message) {
    if (value !== compare && value.length && compare.length) {
      return "Las contraseñas no coinciden";
    } else if (value.length && compare.length && value.length < 8 && compare.length < 8) {
      return "La contraseña deben contener al menos 8 caracteres";
    } else {
      return "";
    }
  }
  return value === compare && value.length >= 8 && compare.length >= 8;
}

function toggleClass(element: any, className: string) {
  if (element.classList) {
    element.classList.toggle(className);
  } else {
    // For IE9
    const classes = element.className.split(' ');
    const i = classes.indexOf(className);

    if (i >= 0) classes.splice(i, 1);
    else classes.push(className);
    element.className = classes.join(' ');
  }
}

export function toggleClassWithId(idName: string, classNameToggle: string) {
  const element = document.getElementById(idName);
  toggleClass(element, classNameToggle);
}

export function toggleClassWithClass(
  className: string,
  classNameToggle: string,
) {
  const element = document.getElementsByTagName(className)[0];
  toggleClass(element, classNameToggle);
}

export function convertDate(date: string, hour?: true) {
  const months = [
    'Enero',
    'Febrero',
    'Marzo',
    'Abril',
    'Mayo',
    'Junio',
    'Julio',
    'Agosto',
    'Septiembre',
    'Octubre',
    'Noviembre',
    'Diciembre',
  ];
  const new_date = new Date(date);
  const year = new_date.getFullYear();
  const month = new_date.getMonth();
  const day = new_date.getDate();
  const h = new_date.getHours() > 9 ? new_date.getHours() : `0${new_date.getHours()}`;
  const m = new_date.getMinutes() > 9 ? new_date.getMinutes() : `0${new_date.getMinutes()}`;

  return `${day} de ${months[month]} de ${year} ${hour ? `${h}:${m}` : ""}`;
}

export function getlength(number: number) {
  const length = number.toString().length;
  if (length === 5) {
    return `${number.toString().slice(0, 2)}K`;
  } else if (length === 6) {
    return `${number.toString().slice(0, 3)}k`;
  } else if (length === 7) {
    return `${number.toString().slice(0, 1)}M`;
  } else if (length === 8) {
    return `${number.toString().slice(0, 2)}M`;
  } else {
    return number;
  }
}

export const goToCreateDocument = (
  key: string = '',
  history: History,
  route: string,
) => {
  history.push(route + key);
};

export const sortByKey = (key: any, arr: Array<any> = []) => {
  return key && arr && arr.sort((a, b) => a[key].localeCompare(b[key]));
};

export const setIcon = (category: string = '') => {
  switch (category) {
    case 'Bienes Raices':
      return faBuilding;
    case 'Familia':
      return faWarehouse;
    case 'Negocios':
      return faHandHoldingUsd;
    case 'Peticiones':
      return faBullhorn;
    case 'Sociedades':
      return faHandshake;
    case 'Laboral':
      return faHardHat;
    default:
      return faBuilding;
  }
};

export const checkUndefinedObject = (obj: any) => {
  return Object.values(obj).every(value => value === undefined);
};

const crypto = (window.crypto as any) || {
  getRandomValues: (array: Array<any>) => {
    for (let i = 0, l = array.length; i < l; i++) {
      array[i] = Math.floor(Math.random() * 256);
    }
    return array;
  },
};

export function uuidV4() {
  return ('' + 1e7 + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c: any) =>
    (
      ((c ^ crypto.getRandomValues(new Uint8Array(1))[0]) & 15) >>
      (c / 4)
    ).toString(16),
  );
}

export function getValuesFields(fields: { [key: string]: FieldValue }) {
  return Object.keys(fields)
    .map(f => getValueField(fields[f]))
    .filter(e => e);
}

export function downloadFile(file: File, name: string) {
  let a = document.createElement("a");
  a.href = URL.createObjectURL(file);
  a.setAttribute("download", `${name}.pdf`);
  a.click();
}

export function removeNullAndUndefinedFromName(name: string) {
  return name
    .replace(/ null/g, "")
    .replace(/ undefined/g, "");
}

export const lineSignature = "__________________";

export function convertStringToPdf(text: string,
                                   {name, download}: { name: string, download?: boolean },
                                   callBack?: (file: File) => void) {

  const {
    top,
    bottom,
    left,
    right
  } = {
    top: 94,
    left: 113,
    right: 113,
    bottom: 94
  };

  const html = (
    `<html>
        <head>
            <style>
            p{
            margin: 0;
            }
            body{font-family:'Noto Sans'}
            @page{
              -fs-page-orientation: portrait; 
              margin-left: ${left}px; 
              margin-right: ${right}px;
              margin-top: ${top}px;
              margin-bottom: ${bottom}px;
            }
            </style>    
        </head>
        <body>
            ${text}
        </body>
    </html>`);

  convertStringToPdfService(html)
    .then((file: Blob) => {
      const nFile = new File([file], name + ".pdf");
      if (callBack) callBack(nFile);
      if (download) downloadFile(nFile, name);
    });
}

export const layout = (signature: string) => (`<span class="container-signature"><b>${getFieldSignature(signature)}</b></span>`);

export function getImg(url: string) {
  return `<img style="max-width: 175px;max-height: 120px" src="${url}"/>`
}


export const getFieldSignature = (signature: string) => {
  return capitalize(signature
    .replace("$(", "")
    .replace(");", ""))
    .split("_")
    .join(" ");
}

export function getImageBase64(file: File, callback: (base64: any) => void) {
  let reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onloadend = () => {
    callback(reader.result)
  };
}

export async function getImageBase64Async(file: File) {
  return new Promise(resolve => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      return resolve(reader.result)
    };
  })
}

export function capitalize(value: string = "") {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

export function getValueField(valueField: FieldValue) {
  const {
    field: {field_type, sub_type, single_value, name, id, detail_form},
    value,
  } = valueField;

  const fields = detail_form?.fields || [];

  switch (field_type) {
    case FieldType.smallText:
    case FieldType.largeText:
      const val = {
        id,
        name,
        value,
        single_value,
      };
      switch (sub_type) {
        case ChildType.number:
          return {...val, value: parseInt(val.value)}
        default:
          return val
      }

    case FieldType.date:
      return {
        id,
        name,
        value: value ? new Date(value).toISOString() : '',
        single_value,
      };

    case FieldType.dateRange:
      return {
        id,
        name,
        value: (value && value.startDate && value.endDate) ?
          `${new Date(value.startDate).toISOString()} -> ${new Date(value.endDate).toISOString()}` : '',
        single_value,
      };

    case FieldType.formGroup:
      const resultForm: any = Object.keys(value).map(r => {
        const subField = fields.find(f => f.id === parseInt(r))
        if (subField) {
          return getValueField({value: value[r], field: subField})
        } else return undefined;
      });
      return {
        id,
        name,
        value: resultForm,
        single_value,
      };

    case FieldType.table:
      const result: any = Object.keys(value).map(e => {
        const row = value[e];
        return Object.keys(row).map(r => {
          const subField = fields.find(f => f.id === parseInt(r))
          if (subField) {
            return getValueField({value: row[r], field: subField})
          } else return undefined;
        });
      }).slice();
      return {
        id,
        name,
        value: result,
        single_value,
      };

    case FieldType.options:
      switch (sub_type) {
        case 'SELECT':
        case 'TOGGLE':
          return value ? {
            id,
            name,
            single_value,
            value: single_value ? value.value : value.map((val: any) => val.value),
          } : null;
        default:
          return null;
      }

    default:
      return null;
  }
}

export function arrayToMap(data: any[], field: string) {
  return data.reduce((a: any, item: any) => {
    a[item[field]] = item;
    return a
  }, {});
}


export const splitRule = (rule: string) => {
  const defaultValues: any[] = [];

  if (rule) {
    const vArrOr = rule.split(' || ');
    vArrOr.forEach(a => {
      const vArrAnd = a.split(' && ');
      vArrAnd.forEach(a => defaultValues.push(a));
    });
  }

  const sp = defaultValues.map(a => {
    const d = a.split(CONDITIONS.find(c => a.includes(c)));
    const f = d[0].split('_');
    const field = d[0].replace(`${f[0]}_${f[1]}_`, '');
    return {
      formId: f[0],
      fieldId: f[1],
      value: d[1],
      field,
      rule,
      defaultField: d[0],
    };
  });
  return sp;
};

function getValueToRule(value: FieldValue, name: string) {
  if (value.field.detail_form) {
    const fields = arrayToMap(value.field.detail_form.fields || [], "name");
    const _v = fields[name];
    if (_v) {
      return {value: value.value[_v.id], field: _v}
    }
  }
  return null
}

export const filterFields = (
  array: IField[],
  val: { [key: string]: any },
  properties: { [key: string]: any },
) => {
  function getRuleValueField(name: string) {
    let vr: any = Object.keys(val).reduce((a: any, v: any) => {
      const typeField = array.find(f => f.id.toString() === v);

      if (typeField?.field_type === FieldType.formGroup) {
        a = getValueToRule(val[v], name);
      }

      if (typeField?.field_type !== FieldType.formGroup &&
        typeField?.field_type !== FieldType.table &&
        val[v].field.name === name) {
        a = val[v];
      }
      return a;
    }, null);

    if (vr) {
      vr = getValueField(vr)?.value;
    } else {
      vr = properties[name]?.value;
      if (!vr) {
        const _props = Object.keys(properties)
          .reduce((a: any, p) => {
            if (properties[p].type === "ARRAY" && !Array.isArray(properties[p].value[0])) {
              properties[p].value.forEach((b: any) => {
                a[b.name] = b.value;
              })
            }
            return a;
          }, {})
        vr = _props[name];
      }
    }
    return typeof vr === "string" ? `"${vr}"` : vr || `""`;
  }

  const b = array.filter(a => {
    let visible = a.visible_when || 'true';
    let rules = splitRule(a.visible_when || "");
    rules.forEach(r => {
      const value = getRuleValueField(r.field);
      if (Array.isArray(value)) {
        const val = value.find((e: string) => `"${e}"` === r.value);
        visible = visible.replace(r.defaultField, `"${val}"`);
      } else {
        visible = visible.replace(r.defaultField, value);
      }
    });

    return eval(visible);
  });
  return b;
};

export function mobileCheck() {
  const isMobile = {
    Android: function () {
      return navigator.userAgent.match(/Android/i);
    },
    BlackBerry: function () {
      return navigator.userAgent.match(/BlackBerry/i);
    },
    iOS: function () {
      return navigator.userAgent.match(/iPhone|iPad|iPod/i);
    },
    Opera: function () {
      return navigator.userAgent.match(/Opera Mini/i);
    },
    Windows: function () {
      return navigator.userAgent.match(/IEMobile/i);
    },
    any: function () {
      return (isMobile.Android() || isMobile.BlackBerry() || isMobile.iOS() || isMobile.Opera() || isMobile.Windows());
    }
  };

  return isMobile.any();
}


export const getImgCompressed = function (file: any): Promise<any> {
  return new Promise(resolve => {
    new Compressor(file, {
      quality: .6,
      maxWidth: 300,
      success(fileCompressed) {
        resolve(fileCompressed);
      },
      error(error) {
        resolve(file);
      }
    });
  });
};

export function dataURLtoFile(dataUrl: string, filename: string) {
  let arr: any[], mime: string, bstr: string, n: number, u8arr: Uint8Array;
  arr = dataUrl.split(',');
  mime = arr[0].match(/:(.*?);/)[1];
  bstr = atob(arr[1]);
  n = bstr.length;
  u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, {type: mime});
}


export function scrollToView(id: string) {
  const e = document.getElementById(id);
  if (e) {
    setTimeout(() => e.scrollIntoView(true), 200);
  }
}

export function addImageToCategory(categories: Category[], type: "png" | "jpg") {
  return categories.map(category => {
    if (category.icon) {
      const url = categoryImgUrl + category._KEY + "." + type;
      category.icon = url;
    }
    return category;
  })
}

export function replaceDefaultSignature(text: string, replaceBy?: string) {
  const array = text.split("$(").map(sign => {
    const complement = sign.split(");")[0];
    return complement ? `$(${complement});` : "";
  }).slice(1);
  let prev = text;
  array.forEach(f => prev = prev.replace(f, (replaceBy !== undefined ? replaceBy : layout(f))));
  return {text: prev, signatures: array}
}

export function replaceLayoutByDefaultLine(prev: string, signatures: string[]) {
  signatures.forEach(signature => prev = prev.replace(layout(signature), lineSignature));
  return prev;
}


export function existTokens() {
  return localStorage.getItem("token") && localStorage.getItem("access_token");
}
