import React, {useEffect} from "react";
import Pagination from '@material-ui/lab/Pagination';
import {createStyles, makeStyles} from '@material-ui/core/styles';

interface Props {
  className?: string;
  onChange?: (page: number) => void;
  count: number;
  position?: "left" | "right" | "center";
  pageSize: number;
}

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      '& > * + *': {
        marginTop: theme.spacing(2),
        marginLeft: theme.spacing(2)
      },
    },
  }),
);

const PaginationComponent = ({onChange, position = "left", count, pageSize}: Props) => {

  const classes = useStyles();
  const [page, setPage] = React.useState(1);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setPage(value);
  };

  const newCount: number = (count / pageSize);

  useEffect(() => {
    if (onChange) {
      onChange(page);
    }
  }, [page, onChange]);

  return (
    <div className={classes.root}>
      <Pagination className={"d-flex justify-content-" + position}
                  count={Number.isInteger(newCount) ? newCount : Math.floor((newCount + 1))}
                  page={page}
                  onChange={handleChange}/>
    </div>
  );
}


export default PaginationComponent;
