import React, {CSSProperties} from "react";

import logo from "../Assets/Images/logo.png";
import flagCOL from "../Assets/Images/colombia_flag.svg";

export default () => {
  const font: CSSProperties = {
    fontFamily: "inherit",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "16px",
    verticalAlign: "Top",
    letterSpacing: " 1.17px"
  };
  return (
    <div className="text-center py-5">
      <img className="mb-4 " style={{height: "50px"}} src={logo} alt=""/>
      <p style={font}>Documentos legales</p>
      {/*<p style={font}>Guias legales</p>*/}
      <p style={font}>Ayuda</p>
      <p style={font}>Términos y Condiciones</p>
      <div>
        <i style={{fontSize: "20.19px"}} className="mx-1 fa fa-instagram"/>
        <i style={{fontSize: "20.19px"}} className="mx-1 fa fa-facebook"/>
        <i style={{fontSize: "20.19px"}} className="mx-1 fa fa-linkedin-square"/>
        <i style={{fontSize: "20.19px"}} className="mx-1 fa fa-youtube"/>
      </div>
      <div className="text-center py-2">
        <img src={flagCOL} height="22px" alt=""/>
      </div>
    </div>
  )
}
