import React from "react";


const PrivacyComponent = () => {
  return (
    <div className="container py-5">

      <h3><b>POLÍTICA DE PRIVACIDAD </b></h3>

      <p className="text-justify">
        Simple Legal S.A.S (en adelante “Simple Legal”), consciente y comprometida con la privacidad de sus usuarios y
        con el adecuado manejo de su información personal, ha implementado la siguiente “Política de privacidad” (en
        adelante “Política), la cual cubre todo el tratamiento de datos personales de los usuarios que hagan uso de su
        sitio web <a target="blank" href="http://simplelegalapp.co">simplelegalapp.co</a> (en adelante “el sitio”) y que
        se encuentren registrados en cualquier base de
        datos que los haga susceptibles de tratamiento, siempre y cuando se encuentren en poder de Simple Legal.
      </p>
      <p className="text-justify">
        Le invitamos a leer detenidamente la siguiente información, toda vez que, se entenderá aceptada por usted al
        momento de hacer uso del sitio.
      </p>

      <h3><b>1. Principios generales</b></h3>

      <p className="text-justify">
        Simple Legal garantiza la protección de derechos como el habeas data, la privacidad, la intimidad, el buen
        nombre, la imagen y la autonomía, por lo tanto, sus actuaciones se basarán en los principios de buena fe,
        legalidad y autodeterminación informática.
      </p>

      <p className="text-justify">
        Cualquier persona que suministre cualquier tipo de información o dato personal en o a través del sitio, podrá
        conocerla, actualizarla o rectificarla.
      </p>

      <h3><b>2. Definiciones</b></h3>

      <p className="text-justify">
        Para efectos de la aplicación de esta Política, se tomarán en cuenta las siguientes definiciones:
      </p>

      <ul>
        <li>
          <p className="text-justify">
            <b>Autorización:</b> consentimiento previo, expreso e informado del titular para llevar a cabo el
            tratamiento de
            datos personales.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Base de Datos:</b> conjunto organizado de datos personales que sea objeto de tratamiento.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Dato Personal:</b> cualquier información vinculada o que pueda asociarse a una o varias personas
            naturales determinadas o determinables.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Encargado del Tratamiento:</b> persona natural o jurídica, pública o privada, que por sí misma o en
            asocio con
            otros, realice el tratamiento de datos personales por cuenta del responsable del Tratamiento.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Responsable del Tratamiento:</b> persona natural o jurídica, pública o privada, que por sí misma o en
            asocio
            con otros, decida sobre la base de datos y/o el tratamiento de los datos.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Titular:</b> persona natural cuyos datos personales sean objeto de tratamiento.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Tratamiento:</b> cualquier operación o conjunto de operaciones sobre datos personales, tales como la
            recolección, almacenamiento, uso, circulación o supresión.
          </p>
        </li>
      </ul>

      <h3><b>3. Principios rectores</b></h3>

      <p className="text-justify">
        Simple Legal se basa en los siguientes principios rectores, los cuales constituyen las reglas aplicables en el
        tratamiento de datos personales:
      </p>

      <ul>
        <li>
          <p className="text-justify">
            <b>Principio de legalidad en materia de Tratamiento de datos:</b> el tratamiento a que se refiere la Ley
            1581 de
            2012 es una actividad reglada que debe sujetarse a lo establecido en ella y en las demás disposiciones que
            la desarrollen.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de finalidad:</b> el tratamiento debe obedecer a una finalidad legítima de acuerdo con la
            Constitución y la Ley, la cual debe ser informada al titular.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de libertad:</b> el tratamiento sólo puede ejercerse con el consentimiento, previo, expreso e
            informado del titular. Los datos personales no podrán ser obtenidos o divulgados sin previa autorización, o
            en ausencia de mandato legal o judicial que releve el consentimiento.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de veracidad o calidad:</b> la información sujeta a tratamiento debe ser veraz, completa,
            exacta,
            actualizada, comprobable y comprensible. Se prohíbe el tratamiento de datos parciales, incompletos,
            fraccionados o que induzcan a error.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de transparencia:</b> en el tratamiento debe garantizarse el derecho del titular a obtener del
            responsable del tratamiento o del encargado del tratamiento, en cualquier momento y sin restricciones,
            información acerca de la existencia de datos que le conciernan.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de acceso y circulación restringida:</b> el tratamiento se sujeta a los límites que se derivan
            de la
            naturaleza de los datos personales, de las disposiciones de la Ley 1581 de 2012 y la Constitución. En este
            sentido, el tratamiento sólo podrá hacerse por personas autorizadas por el titular y/o por las personas
            previstas en la mencionada ley;
            Los datos personales, salvo la información pública, no podrán estar disponibles en Internet u otros medios
            de divulgación o comunicación masiva, salvo que el acceso sea técnicamente controlable para brindar un
            conocimiento restringido sólo a los titulares o terceros autorizados conforme a la Ley 1581 de 2012.

          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de seguridad:</b> la información sujeta a tratamiento por el responsable del tratamiento o
            encargado
            del tratamiento a que se refiere la Ley 1581 de 2012, se deberá manejar con las medidas técnicas, humanas y
            administrativas que sean necesarias para otorgar seguridad a los registros evitando su adulteración,
            pérdida, consulta, uso o acceso no autorizado o fraudulento.
          </p>
        </li>
        <li>
          <p className="text-justify">
            <b>Principio de confidencialidad:</b> todas las personas que intervengan en el tratamiento de datos
            personales
            que no tengan la naturaleza de públicos están obligadas a garantizar la reserva de la información, inclusive
            después de finalizada su relación con alguna de las labores que comprende el tratamiento, pudiendo sólo
            realizar suministro o comunicación de datos personales cuando ello corresponda al desarrollo de las
            actividades autorizadas en la Ley 1581 de 2012 y en los términos de la misma.
          </p>
        </li>
      </ul>

      <h3><b>4. Tratamiento y finalidades</b></h3>

      <p className="text-justify">
        Los datos personales de los usuarios serán tratados exclusivamente por Simple Legal, por lo cual, ningún
        tercero, podrá tener acceso a los mismos. Es decir, que Simple Legal actúa como responsable y encargado del
        tratamiento.
      </p>

      <p className="text-justify">
        Además, los datos personales de los usuarios se someterán estrictamente a las finalidades propias del uso del
        sitio, tales como:
      </p>

      <ul>
        <li>
          <p className="text-justify">
            Identificación de los usuarios al momento de registrarse en el sitio.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Creación de documentos.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Gestionar toda la información necesaria para el cumplimiento de las obligaciones tributarias y de
            registros comerciales, corporativos y contables de Simple Legal.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Realizar estudios estadísticos que permitan diseñar mejoras en los servicios prestados.
          </p>
        </li>
        <li>
          <p className="text-justify">
            El control y la prevención del fraude y de lavado de activos, incluyendo, pero sin limitarse a la consulta
            en listas restrictivas, y toda la información necesaria requerida para el SARLAFT.
          </p>
        </li>
        <li>
          <p className="text-justify">
            El proceso de archivo, de actualización de los sistemas, de protección y custodia de información y bases
            de datos de Simple Legal.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Procesos al interior de Simple Legal, con fines de desarrollo operativo y/o de administración de sistemas.
          </p>
        </li>
        <li>
          <p className="text-justify">
            ● Mantener y procesar por computadora u otros medios, cualquier tipo de información relacionada con los
            intereses del usuario titular con el fin de brindar los servicios y productos pertinentes.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Informar por cualquier medio, las promociones, productos y servicios actuales y futuros; los eventos que
            les generen valores agregados a los usuarios.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Las demás finalidades que determine Simple Legal en procesos de obtención de datos personales para su
            tratamiento y que sean comunicadas a los titulares en el momento de la recolección de los datos personales.
          </p>
        </li>
      </ul>

      <h3><b>5. Información autorizada</b></h3>

      <p className="text-justify">
        En el momento que los usuarios realicen su proceso de registro en el sitio, autorizan expresamente a Simple
        Legal para consultar, verificar o cruzar con bases de datos propias o de terceros los datos suministrados por el
        usuario.
      </p>

      <p className="text-justify">
        De la misma manera, cuando Simple Legal solicite a los usuarios el diligenciamiento de información adicional a
        la que inicialmente se requirió para su registro, que incluya datos personales, cuando los usuarios la
        proporcionen, se entenderá como autorización a Simple Legal para darle el tratamiento explicado en la presente
        Política.
      </p>

      <p className="text-justify">
        Es importante destacar que, en los casos que se solicite información que verse sobre datos sensibles o datos de
        niños(as) y adolescentes, será facultativo por parte del usuario responder a las preguntas que le sean hechas.
        Sin embargo, se entiende que el usuario autoriza el tratamiento de dichos datos cuando decide dar respuesta a
        ellas.
      </p>

      <h3><b>6. Revocatoria de autorización</b></h3>

      <p className="text-justify">
        En el momento que el usuario titular de los datos personales desee revocar la autorización del tratamiento de
        sus datos a Simple Legal, deberá manifestarlo de manera expresa a través de una comunicación enviada a <a
        href="mailto:  soporte@simplelegalapp.com">soporte@simplelegalapp.com</a>.
      </p>

      <p className="text-justify">
        De la misma manera, deberá indicar si su revocación es total o parcial. Será total, cuando de ninguna manera
        permita a Simple Legal el tratamiento de su información, mientras que será parcial cuando autorice a Simple
        Legal a tratar sus datos personales exclusivamente para alguna de las finalidades establecidas en estos
        Términos.
      </p>

      <h3><b>7. Derechos de los usuarios titulares</b></h3>

      <p className="text-justify">
        El usuario titular de los datos personales suministrados por o a través del sitio, tendrá derecho a:
      </p>

      <ul>
        <li>
          <p className="text-justify">
            Conocer, actualizar y rectificar sus datos personales frente a Simple Legal. Este derecho se podrá
            ejercer, entre otros, frente a datos parciales, inexactos, incompletos, fraccionados, que induzcan a error,
            o aquellos cuyo tratamiento esté expresamente prohibido o no haya sido autorizado.
          </p>
        </li>
        <li>
          <p className="text-justify">
            • Solicitar prueba de la autorización otorgada a Simple Legal, salvo cuando expresamente se exceptúe como
            requisito para el tratamiento, de conformidad con el artículo 10 de la Ley 1581 de 2012.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Ser informado por Simple Legal, previa solicitud, respecto del uso que les ha dado a sus datos personales.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Presentar ante la Superintendencia de Industria y Comercio quejas por infracciones a lo dispuesto en la
            Ley 1581 de 2012 y las demás normas que la modifiquen, adicionen o complementen.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Revocar la autorización y/o solicitar la supresión del dato cuando en el tratamiento no se respeten los
            principios, derechos y garantías constitucionales y legales. La revocatoria y/o supresión procederá cuando
            la Superintendencia de Industria y Comercio haya determinado que, en el tratamiento, Simple Legal ha
            incurrido en conductas contrarias a la Ley 1581 de 2012 y a la Constitución.
          </p>
        </li>
        <li>
          <p className="text-justify">
            Acceder en forma gratuita a sus datos personales que hayan sido objeto de tratamiento.
          </p>
        </li>
      </ul>


      <h3><b>8. Procedimiento para ejercer los derechos de los usuarios titulares</b></h3>

      <p className="text-justify">
        A continuación, se mencionan lo procedimientos que pueden realizar los usuarios directamente ante Simple Legal
        para ejercer sus derechos como titulares de los datos personales.
      </p>

      <h3><b>8.1 Consulta</b></h3>

      <p className="text-justify">
        Los titulares o sus causahabientes podrán consultar la información personal del titular que repose en cualquier
        base de datos de Simple Legal, quien les suministrará toda la información contenida en el registro individual o
        que esté vinculada con la identificación del titular.
      </p>

      <p className="text-justify">
        La consulta se formulará enviando un correo electrónico a <a
        href="mailto: soporte@simplelegalapp.com">soporte@simplelegalapp.com</a>
      </p>

      <p className="text-justify">
        La consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo
        de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado,
        expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso
        podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.
      </p>

      <h3><b>8.2 Reclamos</b></h3>

      <p className="text-justify">
        El titular o sus causahabientes que consideren que la información contenida en una base de datos debe ser objeto
        de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los
        deberes contenidos en la ley 1581 de 2012, podrán presentar un reclamo ante Simple Legal el cual será tramitado
        bajo las siguientes reglas:
      </p>

      <ol>
        <li>
          <p className="text-justify">
            1. El reclamo se formulará mediante solicitud dirigida a Simple Legal a través <a
            href="mailto: soporte@simplelegalapp.com">soporte@simplelegalapp.com</a>,
            con la identificación del titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y
            acompañando los documentos que se quiera hacer valer. Si el reclamo resulta incompleto, se requerirá al
            interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas.
          </p>

          <p className="text-justify">
            Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información
            requerida, se entenderá que ha desistido del reclamo.
          </p>
          <p className="text-justify">
            En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda
            en un término máximo de dos (2) días hábiles e informará de la situación al interesado.
          </p>

        </li>
        <li>
          <p className="text-justify">
            2. Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en
            trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá
            mantenerse hasta que el reclamo sea decidido.
          </p>
        </li>
        <li>
          <p className="text-justify">
            3. El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día
            siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se
            informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún
            caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.
          </p>
        </li>
      </ol>

      <h3><b>8.3 Requisito de procedibilidad</b></h3>

      <p className="text-justify">
        El titular o causahabiente sólo podrá elevar queja ante la Superintendencia de Industria y Comercio una vez haya
        agotado el trámite de consulta o reclamo ante Simple Legal.
      </p>

      <h3><b>9. Jurisdicción y legislación aplicable</b></h3>

      <p className="text-justify">
        Esta Política se regirá, interpretará y aplicará según las leyes de la República de Colombia.
      </p>

    </div>
  )
}

export default PrivacyComponent;
