import {
  forgotPassword,
  loginAsGuestService,
  loginService,
  restorePasswordService,
  signUpService,
  signUpTheGuestService,
  validateToken
} from '../../Services';
import {Roles} from '../../Models/roles';
import {all, call, put, takeEvery} from 'redux-saga/effects';
import {toast} from '../../Utils';
import {
  AUTH_LOG_IN,
  AUTH_SIGN_UP,
  FORGOT_PASSWORD,
  LOGIN_AS_GUEST,
  RESTORE_PASSWORD,
  SIGN_UP_THE_GUEST,
  VALIDATE_TOKEN
} from './Types';
import {actionsAuth} from './Slice';
import {sbxSessionService, setHeaderToken} from "../../Network";
import MixPanelActions from "../MixPanelActions";
import Emitter from "../../Services/Emitter";


function* loginSaga({payload}: { payload: { user: string, password: string }, type: string }) {
  try {
    const res = yield loginService(payload.user, payload.password);
    if (res.success) {
      const {item: {role, token, access_token, user_id, name, email, last_name}} = res;
      MixPanelActions.setAuthUser(`${name} ${last_name || ""}`, email, role, user_id, "Inicio de sesion");
      switch (role) {
        case Roles.ADMIN:
          window.open(`http://admin-dev.simplelegalapp.co/#/?token=${token}&access_token=${access_token}`, '_self');
          window.close();
          break;
        case Roles.CUSTOMER:
          Emitter.emit("SESSION", "authenticated");
          localStorage.setItem("guest_session", "false");
          sbxSessionService.updateToken(token);
          setHeaderToken(access_token);
          localStorage.setItem('token', token);
          localStorage.setItem("access_token", access_token);
          yield put(actionsAuth.authLogged({data: {user: res.item, logged: true}}));
          break;
      }
    } else {
      const message = res.error === "Invalid Credentials" ? "Credenciales invalidas" : "No se pude iniciar sesión";
      toast(message, 'error');
      throw  Error(message);
    }
  } catch (e) {
    console.log(e.message)
    MixPanelActions.setErrors(e.message);
    yield put(actionsAuth.authLogged({data: {guest: false}}));
  }
}

function* validateTokenSaga({payload}: any) {
  try {
    const res = yield validateToken(payload);
    if (res.success) {
      if (res.response.user.role === "ADMIN") {
        window.location.href = `http://admin-dev.simplelegalapp.co/#/?token=${res.response.token}`;
      } else {
        Emitter.emit("SESSION", "authenticated");
        yield put(actionsAuth.authLogged({data: {...res.response, logged: true}}));
      }
      // history.push("/home");
    } else {
      throw Error("Sesión expirada");
    }
  } catch (e) {
    Emitter.emit("SESSION", "expired");
    MixPanelActions.setErrors(e.message);
    yield put(actionsAuth.authLogged({data: {logged: false}}));
  }
}

function* signUpTheGuestSaga({payload}: {
  payload: {
    name: string,
    email: string,
    password: string,
    user: string,
    key: string,
    token: string
  },
  type: string
}) {
  try {
    const res = yield call(signUpTheGuestService, payload.name, payload.email, payload.password, payload.token, payload.key);
    if (res.success) {
      sbxSessionService.updateToken(res.item.token);
      localStorage.setItem('token', res.item.token);
      yield put(actionsAuth.authLogged({
        data:
          {
            user: res.item,
            logged: true
          }
      }));
      toast('Cuenta creada.');
    } else {
      const message = 'No se pudo crear la cuenta intenta de nuevo';
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    yield put(actionsAuth.authLogged({data: {logged: false}}));
    MixPanelActions.setErrors(e.message);
  }
}


function* signUpSaga({payload}: {
  payload: {
    name: string,
    email: string,
    password: string,
    user: string
  },
  type: string
}) {
  try {
    const res = yield call(signUpService, payload.name, payload.email, payload.password, payload.user);
    if (res.success) {
      localStorage.setItem('token', res.item.token);
      sbxSessionService.updateToken(res.item.token);
      const {item: {role, user_id, name, email, last_name}} = res;
      MixPanelActions.setAuthUser(`${name} ${last_name || ""}`, email, role, user_id, "Registro de usuario");
      yield put(actionsAuth.authLogged({data: {user: res.item, logged: true}}));
    } else {
      const message = 'No se pudo crear la cuenta intenta de nuevo';
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsAuth.authLogged({data: {logged: false}}));
  }
}

function* forgotPasswordSaga({payload: {email}}: any) {
  const message = 'No pudimos enviar la información a tu correo';
  try {
    const res = yield call(forgotPassword, email);
    if (res.success) {
      yield put(actionsAuth.sendedForgotPassword({...res, EmailSended: true}));
    } else {
      yield put(actionsAuth.sendedForgotPassword(res));
      toast(message, 'error');
      MixPanelActions.setErrors(message);
    }
  } catch (e) {
    yield put(actionsAuth.sendedForgotPassword({success: false}));
    toast(message, 'error');
  }
}


function* restorePasswordSaga({payload: {user_id, code, password, email}}: any) {
  try {
    const res = yield call(restorePasswordService, user_id, code, password, email);
    if (res.success) {
      yield put(actionsAuth.passwordRestored(res));
    } else {
      const message = res.error;
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsAuth.passwordRestored({success: false}));
  }
}

function* loginAsGuestSaga(reload?: boolean) {
  try {
    const res = yield call(loginAsGuestService);
    if (res.success) {
      const {item: {token, access_token}} = res;
      sbxSessionService.updateToken(token)
      setHeaderToken(access_token);
      localStorage.setItem('token', token);
      localStorage.setItem("access_token", access_token);
      localStorage.setItem("guest_session", "true");
      Emitter.emit("SESSION", "authenticated");
      yield put(actionsAuth.authLogged({data: {user: res.item, logged: true}}));
    } else {
      const message = res.error;
      toast(message, 'error');
      throw Error(message);
    }
  } catch (e) {
    MixPanelActions.setErrors(e.message);
    yield put(actionsAuth.authLogged({data: {logged: false}}));
  }
}


export default function* AllSagas() {
  yield all([
    //Auth sagas
    takeEvery(VALIDATE_TOKEN, validateTokenSaga),
    takeEvery(AUTH_LOG_IN, loginSaga),
    takeEvery(AUTH_SIGN_UP, signUpSaga),
    takeEvery(FORGOT_PASSWORD, forgotPasswordSaga),
    takeEvery(RESTORE_PASSWORD, restorePasswordSaga),
    takeEvery(LOGIN_AS_GUEST, loginAsGuestSaga),
    takeEvery(SIGN_UP_THE_GUEST, signUpTheGuestSaga),
  ]);
}
