import React, {CSSProperties} from 'react';
import TimePicker from 'react-time-picker';

interface IProps {
  id: string,
  name: string,
  value: string,
  invalid?: boolean,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  style?: CSSProperties,
  placeholder?: string,
  locale?: string,
  format?: string,
  className?: string,
  onChange?: (value: string) => void
}

const TimeComponent = (props: IProps) => {

  const {
    id,
    name,
    value,
    required,
    onChange,
    className,
    style,
    invalid,
    placeholder,
    locale,
    disabled,
    format = 'hh:mm a'
  } = props;

  return <TimePicker id={id}
                     required={required}
                     disabled={disabled}
                     name={name}
                     value={value}
                     style={style}
                     locale={locale}
                     disableClock={true}
                     format={format}
                     className={`form-control ${className || ''} ${invalid ?
                       'invalid' :
                       ''}`}
                     placeholder={placeholder}
                     onChange={onChange}/>;
};

export default TimeComponent;
