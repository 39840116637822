import Document from '../../Models/Document';
import {DocumentState, State} from './Types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import Section from '../../Models/Section';
import Form from '../../Models/Form';
import {CDocument} from "../../Models/CDocument";

 const initialStateDocument: DocumentState = {
  documents: [],
  sections: [],
  documentsCreated: {rows: [], row_count: 0},
  currentDocument: new Document(),
  state: State.IDLE,
  currentFile: null
};

const slice = createSlice({
  name: 'document',
  initialState: initialStateDocument,
  reducers: {
    getDocuments(state) {
      state.state = State.PENDING;
    },
    getDocumentsCreated(state, action: PayloadAction<{ folder_key: string, user_key: string, page: number, pageSize: number }>) {
      state.state = State.PENDING
    },
    setDocumentsCreated(state, action: PayloadAction<{ rows: CDocument[], row_count: number }>) {
      state.documentsCreated = {...action.payload, rows: action.payload.rows.sort((a, b) => b.time - a.time)}
      state.state = State.RESOLVED;
    },
    setDocuments(state, action: PayloadAction<Document[]>) {
      state.state = State.RESOLVED;
      state.documents = action.payload;
    },
    getCurrentDocument(state) {
      state.state = State.PENDING;
    },
    getDocument(state, action: PayloadAction<string>) {
      state.state = State.PENDING;
    },
    setDocument(state, action: PayloadAction<Document>) {
      state.state = State.RESOLVED;
      state.currentDocument = action.payload;
    },
    getSections(state, action: PayloadAction<string>) {
      state.state = State.PENDING;
    },
    setSections(state, action: PayloadAction<Section[]>) {
      state.state = State.RESOLVED;
      state.sections = action.payload;
    },
    setActiveSection(state, action: PayloadAction<Section>) {
      state.state = State.RESOLVED;
      state.activeSection = action.payload;
    },
    getCurrentForm(state, action: PayloadAction<string | number>) {
      state.state = State.PENDING;
    },
    setCurrentForm(state, action: PayloadAction<Form>) {
      state.state = State.RESOLVED
      state.currentForm = action.payload;
    },
    getFile(state, action: PayloadAction<string>) {
      state.state = State.PENDING;
    },
    setFile(state, action: PayloadAction<File | null>) {
      state.state = State.RESOLVED;
      state.currentFile = action.payload
    },
    rejectAction(state) {
      state.state = State.REJECTED;
    },
    resetState(state) {
      const {sections, activeSection, currentForm, currentFile, currentDocument, } = initialStateDocument;
      state.sections = sections;
      state.currentFile = currentFile;
      state.activeSection = activeSection;
      state.currentForm = currentForm;
      state.currentDocument = currentDocument;
    }
  }
});

export const actionsDocument = slice.actions;
export default slice.reducer;
