import React, {useEffect, useState} from "react";
import {State} from "../../Store/Auth/Types";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faExclamationCircle, faSpinner} from "@fortawesome/free-solid-svg-icons";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../Store/Reducers";
import {actionsProfile} from "../../Store/Profile/Slice";

const stateCredentials = {currentPassword: "", newPassword: ""}


export default () => {
  const {state} = useSelector((state: RootState) => state.ProfileReducer)
  const loading = state === State.PENDING;
  const [credentials, setCredentials] = useState(stateCredentials);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [validate, setValidate] = useState<boolean | "no-validate">("no-validate");
  const isValid = (!validate ? "is-invalid" : validate === "no-validate" ? " " : "is-valid");
  const dispatch = useDispatch();

  useEffect(() => {
    if (state === State.RESOLVED) {
      setCredentials(stateCredentials);
      setConfirmPassword("");
    }
  }, [state])

  const savePassword = () => {
    if (credentials.currentPassword &&
      credentials.newPassword === confirmPassword &&
      confirmPassword.length > 3) {

      setValidate(true);
      dispatch(actionsProfile.changePassword(credentials));
    } else {
      setValidate(false);
    }
  }

  return (
    <div  className="card border-light shadow-sm">
      <div className="card-header border-light">
        <h5>Contraseña</h5>
      </div>
      <div className="card-body">
        <div className="form-group">
          <div className="row">
            <div className="col-lg-3 d-flex align-items-center">
              <label><b>Contraseña actual:</b></label>
            </div>
            <div className="col-lg-8 col-12 py-2">
              <input type="password"
                     value={credentials.currentPassword}
                     onChange={e => setCredentials({...credentials, currentPassword: e.target.value})}
                     className={"form-control " + isValid}
                     placeholder="Contraseña actual"/>
              {state === State.REJECTED && <small className="text-danger">Contraseña invalida </small>}
            </div>
            <div className="col-lg-3 d-flex align-items-center">
              <label><b>Nueva contraseña:</b></label>
            </div>
            <div className="col-lg-8 col-12 py-2 ">
              <input type="password"
                     value={credentials.newPassword}
                     onChange={e => setCredentials({...credentials, newPassword: e.target.value})}
                     className={"form-control " + isValid}
                     placeholder="Nueva contraseña"/>
              {isValid === "is-invalid" && <small className="text-danger">Contraseñas no coinciden </small>}
            </div>
            <div className="col-lg-3   d-flex align-items-center">
              <label><b>Confirmar contraseña:</b></label>
            </div>
            <div className="col-lg-8 col-12 py-2">
              <input type="password"
                     value={confirmPassword}
                     onChange={e => setConfirmPassword(e.target.value)}
                     className={"form-control mb-2 " + isValid}
                     placeholder="Confirmar contraseña"/>
              {isValid === "is-invalid" && <small className="text-danger">Contraseñas no coinciden </small>}
            </div>
            <div className="col-3"/>
            <div className="col-8 text-center">
              <Link to="/forgot_password" className="text-primary">
                Olvidé mi contraseña
                <FontAwesomeIcon className="ml-2" icon={faExclamationCircle}/>
              </Link>
            </div>
          </div>
        </div>
      </div>
      <div className="card-footer bg-transparent border-0 ">
        <button disabled={loading}
                onClick={savePassword}
                className="btn btn-sm btn-primary">
          Editar contraseña
          {loading && <FontAwesomeIcon className="ml-2" icon={faSpinner} spin/>}
        </button>
      </div>
    </div>
  )
}
