import React, {Fragment, useCallback, useEffect, useRef, useState} from 'react';
import './documentsComponent.scss';
// import {sortByKey} from '../../Utils';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from '../../Store/Reducers';
import {Category} from '../../Models/Category';
import CategorySidebar from './CategorySidebar';
import CategorySectionComponent from './CategorySectionComponent';
import SpinnerComponent from '../../Shared/SpinnerComponent';
import {actionsCategory} from "../../Store/Category/Slice";
import {State} from "../../Store/Category/Types";
import {useLocation} from 'react-router-dom';
import queryString from "querystring";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faTimes} from "@fortawesome/free-solid-svg-icons";
import {sbxSearchKeys} from "../../Services";

export function getNewResult(categories: Category[], search: string) {
  const result = categories.reduce((c: Array<any>, n: Category) => {
    const docs = n.documents
      .filter(d => d.name.toLocaleLowerCase()
        .indexOf(search.toLowerCase()) !== -1);
    if (docs.length) {
      c.push({...n, documents: docs})
    }
    return c
  }, []);

  return result
}

const DocumentsComponent = () => {

  const dispatch = useDispatch();
  const {search: searchFilter} = useLocation();
  const search = useRef<HTMLInputElement>(null);
  const [filterDocuments, setFilterDocuments] = useState<Array<Category>>([]);
  const {categories, state} = useSelector((state: RootState) => state.categoryReducer);
  const loading = state === State.PENDING

  useEffect(() => {
    const {
      search: searchParam,
      category: categoryParam,
      show_more: showMoreParam
    } = queryString.parse(searchFilter.replace("?", ""));

    if (!searchParam) {
      const sm = showMoreParam === "true";
      dispatch(actionsCategory.getCategoriesDocs({
        page: 1,
        size: sm ? "all" : 1,
        keys: categoryParam && sm ? [categoryParam.toString()] : [],
        search: "",
        documentsKeys: null
      }));
    }
  }, [dispatch, searchFilter])

  const searchSubmit = useCallback(async (filter: string) => {
    const {success, results} = await sbxSearchKeys(encodeURI(filter), "document");
    if (success) {
      dispatch(actionsCategory.getCategoriesDocs({
        page: 1,
        size: 1,
        keys: [],
        search: "",
        documentsKeys: results
      }));
    }
  }, [dispatch])

  useEffect(() => {
    if (searchFilter) {
      const {
        search: searchParam
      } = queryString.parse(searchFilter.replace("?", ""));

      if (searchParam) {
        searchSubmit(searchParam.toString());
      }
    }
  }, [searchFilter, dispatch, searchSubmit])


  useEffect(() => {
    setFilterDocuments(categories);
  }, [categories]);

  const searching = categories.some(c => c.length_documents === "search");

  return <div className="vh-90 overflow-auto">
    {!filterDocuments.length && loading ?
      <SpinnerComponent loading={true} className="loading-spinner"/> :
      <div className="container">
        <div className="row">
          <div className="col-3 d-none d-lg-block">
            <div className="d-flex flex-column sidebar-sticky">
              <CategorySidebar categories={categories}/>
            </div>
          </div>
          <div className="col-12 col-lg-9">
            <div className="form-group mt-3">
              <form onSubmit={e => {
                e.preventDefault();
                searchSubmit(search?.current?.value + "");
              }} className="input-group md-form form-sm form-2 pl-0">
                <input className="form-control my-0 py-1 amber-border outline-none"
                       type="search"
                       placeholder="Buscar documento..."
                       ref={search}
                       aria-label="Search"/>
                <div className="input-group-append">
                  {searching && (
                    <button style={{background: "#eeeeee"}}
                            className="btn br-none"
                            type="button"
                            onClick={() => {
                              dispatch(actionsCategory.getCategoriesDocs({
                                page: 1,
                                size: 1,
                                keys: [],
                                search: "",
                                documentsKeys: null
                              }));
                              if (search?.current?.value) search.current.value = "";
                            }}><FontAwesomeIcon icon={faTimes}/></button>
                  )
                  }
                  <button className="input-group-text btn btn-light br-none amber lighten-3" id="basic-text1">
                    <FontAwesomeIcon icon={faSearch}/>
                  </button>
                </div>
              </form>
            </div>
            <div id="container-categories"
                 className="d-flex flex-column mt-4 mt-lg-2">
              {!!filterDocuments.length && filterDocuments.map((category: Category) => (
                <Fragment key={category._KEY}>
                  <div className="d-flex flex-column mb-5 document-container"
                       id={category._KEY}>
                    {!!category.documents.length &&
                    <CategorySectionComponent documents={category.documents} category={category}/>}
                  </div>
                </Fragment>
              ))}
            </div>
          </div>
        </div>
      </div>
    }
  </div>;
};

export default DocumentsComponent;
