// export const OPEN_MODAL = "modals/openModal";
// export const CLOSE_MODAL = "modals/closeModal";

export enum typeModal {
  SIGN_UP_MODAL = "SIGN_UP_MODAL",
  PREVIEW_ITEM_MODAL = "PREVIEW_ITEM_MODAL",
  SEND_EMAIL_MODAL = "SEND_EMAIL_MODAL",
}

interface actionModal {
  open: boolean,
  data: any
}

export interface ModalState {
  modals: {
    [typeModal.SIGN_UP_MODAL]: actionModal,
    [typeModal.PREVIEW_ITEM_MODAL]: actionModal,
    [typeModal.SEND_EMAIL_MODAL]: actionModal,
  }
}
