import {CategoryState, State} from './Types';
import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {Category} from '../../Models/Category';

const initialState: CategoryState = {
  categories: [],
  state: State.IDLE
};

const slice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    getCategories(state) {
      state.state = State.PENDING;
    },
    setCategories(state, action: PayloadAction<Category[]>) {
      state.state = State.RESOLVED;
      state.categories = action.payload;
    },
    getCategoriesDocs(state, action: PayloadAction<{ page: number, size: number | "all", keys: string[], search: string, documentsKeys: Array<any> | null }>) {
      state.state = State.PENDING;
    },
    setCategoriesDocs(state, action: PayloadAction<Category[]>) {
      state.state = State.RESOLVED;
      state.categories = action.payload;
    },
    rejectAction(state) {
      state.state = State.REJECTED;
    },

  }
});

export const actionsCategory = slice.actions;
export default slice.reducer;

