import React from 'react';
import {capitalize} from "../../Utils";

type Props = {
  name: string;
  index: number;
  isActive: boolean;
};

const StepComponent: React.FC<Props> = ({isActive, index, name}) => {
  return (
    <>
      <span
        className={`d-flex border rounded-circle justify-content-center align-items-center mr-3   
      ${
          isActive
            ? 'section-round-number-active'
            : 'section-round-number-inactive'
        }`}>
        {index + 1 + '  '}
      </span>
      <span
        className={`${isActive ? 'section-name-active' : 'section-name'}`}>
        {capitalize(name)}
      </span>
    </>
  );
};

export default StepComponent;
