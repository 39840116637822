export enum FieldType {
  smallText = "SMALL_TEXT",
  largeText = "LARGE_TEXT",
  select = "SELECT",
  toggle = "TOGGLE",
  title = "TITLE",
  options = "OPTIONS",
  date = "DATE",
  table = "TABLE",
  formGroup = "FORM_GROUP",
  dateRange = "DATE_RANGE"
}

export enum ChildType {
  email = "EMAIL",
  phone = "PHONE",
  password = "PASSWORD",
  number = "NUMBER",
  time = "TIME"
}

