import React, {useState} from "react";
import {SingleDatePicker} from "react-dates";
import moment, {Moment} from "moment";

interface Props {
  id: string,
  value: Date,
  required?: boolean,
  readonly?: boolean,
  disabled?: boolean,
  placeholder?: string,
  onChange?: (value: Date | null) => void,
  isOutsideRange?: (day?: Moment) => boolean,
  onFocusedChange?: (e: Boolean) => void;
  displayFormat?: string | (() => string);
  orientation?: "vertical" | "horizontal"
}

const DateComponent: React.FC<Props> = ({
                                          id,
                                          value,
                                          required,
                                          readonly,
                                          disabled,
                                          placeholder,
                                          onChange,
                                          displayFormat,
                                          isOutsideRange,
                                          onFocusedChange,
                                          orientation = "horizontal"
                                        }) => {

  const [focused, setFocused] = useState(false);

  return (
    <div className="date-picker-component">
      <SingleDatePicker id={id}
                        block
                        orientation={orientation}
                        required={required}
                        readOnly={readonly}
                        disabled={disabled}
                        placeholder={placeholder}
                        isOutsideRange={isOutsideRange}
                        focused={focused}
                        onFocusChange={e => {
                          setFocused(e.focused)
                          if (onFocusedChange) {
                            onFocusedChange(Boolean(e.focused))
                          }
                        }}
                        onDateChange={e => onChange && onChange(e ? e.toDate() : null)}
                        date={value ? moment(value) : null}
                        displayFormat={displayFormat}
      />
    </div>
  )
};

export default DateComponent;
