import React, {CSSProperties} from 'react';
import IconComponent from '../Shared/IconComponent';
import back from '../../Assets/Icons/back_arrow.png';
import eye from '../../Assets/Icons/eye.png';
import next from '../../Assets/Icons/next_arrow.png';
import {Action} from '../../Execution/Models/ProcessExecutionTask';

type Props = {
  actions: Action[];
  className?: string;
  getPreview: () => void;
  loading: boolean;
  size: number;
  style?: CSSProperties;
}

const FooterControlComponent: React.FC<Props> = ({
                                                   actions,
                                                   className,
                                                   getPreview,
                                                   loading,
                                                   size,
                                                   style
                                                 }) => {
  const backVal = actions.find(i => i.name === 'BACK');
  const nextVal = actions.find(i => i.name === 'NEXT');

  return (
    <div style={{...style, height: size + "%",}}
         className={"d-flex justify-content-between  align-items-center bg-white shadow px-2 " + className}>
      <button type="button"
              disabled={!backVal || loading}
              className="actions-text btn btn-light btn-sm"
              onClick={e => {
                e.preventDefault();
                const backSection = document.getElementById("back_form_execution_button");
                if (backSection) {
                  backSection.click();
                }
              }}>
        <IconComponent classname="mr-1" icon={back}/>
        <span> Atrás</span>
      </button>
      <span onClick={getPreview} className="d-flex align-items-center justify-content-center actions-text ">
        <IconComponent classname="mr-1" icon={eye}/>
        <span>Vista previa</span>
      </span>
      <button type="submit"
              disabled={!nextVal || loading}
              className="btn btn-sm btn-primary"
              onClick={e => {
                e.preventDefault();
                const nextSection = document.getElementById("next_form_execution_button");
                if (nextSection) {
                  nextSection.click();
                }
              }}>
        {nextVal?.label || "Siguiente"}
        <IconComponent icon={next}/>
      </button>
    </div>
  );
};

export default FooterControlComponent;
