import React from 'react';
import {useDropzone} from 'react-dropzone';
import {Button, Collapse, Input, InputGroup, InputGroupAddon, UncontrolledTooltip} from "reactstrap";
import {megabyteToByte, stopPropagation, uuidV4} from "../Utils";
import SpinnerComponent from "../Shared/SpinnerComponent";
import {useState} from "react";
import {eachLimit} from 'async';

interface IProps {
  maxSize?: number;
  readOnly?: boolean;
  value: File[];
  invalid?: boolean;
  onChange: (e: File[]) => void;
  onDropRejected: (files: File[]) => void;
  onDownloadFile?: (file: File) => Promise<any>;
  onUploadFile?: (formData: FormData) => Promise<any>;
}

const MultiFileComponent: React.FC<IProps> = (props: IProps) => {
  const {
    maxSize, readOnly, value, invalid, onChange,
    onDropRejected, onDownloadFile, onUploadFile
  } = props;

	const message = 'Haga click o arrastre para subir documentos anexos.';

	const [showFiles, setShowFiles] = useState(false);
	const [files, setFiles] = useState<File[]>(value || []);
  const [uploadingFiles, setUploadingFiles] = useState(false);
	const [downloadingFiles, setDownloadingFiles] = useState(false);
  const [isInvalid, setIsInvalid] = useState(invalid);

	const {getRootProps, getInputProps} = useDropzone({
		disabled: readOnly || uploadingFiles,
		maxSize: megabyteToByte(maxSize),
		multiple: true,
		onDropRejected,
		onDropAccepted: acceptedFiles => {
      if (!acceptedFiles.length) {
        return;
      }
      setUploadingFiles(true);
      setIsInvalid(false);
      eachLimit(acceptedFiles, 3, (file, callback) => {
        if (onUploadFile) {
          const formData = new FormData();
          formData.append('file', file);
          onUploadFile(formData).then(_ => callback(null));
        } else {
          callback(null)
        }
      }, (_) => {
        const newValue = [...files, ...acceptedFiles];
        setFiles(newValue);
        onChange(newValue);
        setUploadingFiles(false);
      });
    }
	});

	const downloadFile = async (event: any, file: File) => {
		event.stopPropagation();
		if (onDownloadFile) {
      setDownloadingFiles(true);
      await onDownloadFile(file);
      setDownloadingFiles(false);
    }
	};

	const toggle = function (event: any) {
		event.stopPropagation();
		if (!files.length) {
			return;
		}
		setShowFiles(!showFiles);
	};

	const removeFile = function (event: any, index: number) {
		event.stopPropagation();
		const newValue = files.filter((_, i) => i !== index);
		setFiles(newValue);
    onChange(newValue);
		if (!newValue.length) {
			setShowFiles(!showFiles);
		}
	};

	return (
		<div {...getRootProps({className: `dropzone position-relative ${isInvalid ? 'invalid' : ''}`})}>
			<input {...getInputProps()} />
			{
				uploadingFiles ? <SpinnerComponent className="" style={{marginBottom: 12}}/> : <p> {message} </p>
			}
			<aside className="dropzone-files-container px-3" onClick={stopPropagation}>
				<div className={`bg-white px-2 ${showFiles ? '' : 'border-top'}`}>
					<div className="d-flex justify-content-between">
						<h6 className="mb-0">Archivos ({files.length})</h6>
						<i className={`${files.length ? 'cursor-pointer' : 'cursor-not-allowed'} fa-1x fa ${showFiles ? 'fa-angle-up' : 'fa-angle-down'} `}
							 onClick={toggle}/>
					</div>
					<Collapse isOpen={showFiles}>
						<div className="row">
							{
								files.map((file: File, index: number) => {
								  const key = uuidV4();
                  return <InputGroup size="sm" className="col-4 mb-1" key={key}>
                    <Input value={file.name} readOnly={true} className="p-1 h-100" id={`FileNameInput-${key}`}/>
                    <UncontrolledTooltip placement="top" target={`FileNameInput-${key}`}>
                      {file.name}
                    </UncontrolledTooltip>
                    <InputGroupAddon addonType="append">
                      <Button color="info"
                              onClick={(event: any) => downloadFile(event, file)}
                              className="h-100"
                              style={{lineHeight: 1}}
                              disabled={downloadingFiles}>
                        <i className={`cursor-pointer fa ${downloadingFiles ? 'fa-circle-o-notch fa-spin' : 'fa-download' }`}/>
                      </Button>
                      <Button color="danger" onClick={(event: any) => removeFile(event, index)} className="h-100" style={{lineHeight: 1}}>
                        <i className="cursor-pointer fa fa-trash"/>
                      </Button>
                    </InputGroupAddon>
                  </InputGroup>;
                })
							}
						</div>
					</Collapse>
				</div>
			</aside>
		</div>
	);
};

export default MultiFileComponent;
