import React, {useCallback, useEffect, useState} from "react";
import {useHistory} from "react-router-dom";
import {downloadFile, downloadFileByUrl, finishAndSaveDocService} from "../../../Services";
import {faSpinner, faTimesCircle, faUpload} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Button, Card, CardBody, CardHeader, FormGroup} from "reactstrap";
import {
  convertStringToPdf,
  dataURLtoFile,
  getFieldSignature,
  getImageBase64,
  getImageBase64Async,
  getImg,
  getImgCompressed,
  layout,
  toast
} from "../../../Utils";
import SignatureComponent from "../../Shared/Modals/SignatureComponentModal/SignatureComponent";
import {DocumentUser} from "../../../Models/DocumentUser";
import {UserSignature} from "../../../Models/UserSignature";


interface IProps {
  document_user: DocumentUser,
  user_signature: UserSignature,
  folder_key: string,
  users: UserSignature[],
  remaining: UserSignature[]
}

const SignatureTemplateComponent = ({document_user, user_signature, folder_key, remaining, users}: IProps) => {
  const [previewDoc, setPreviewDoc] = useState(document_user.data_file);
  const [imgUrl, setImgUrl] = useState<string>("");
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  // const [signatures, setSignatures] = useState<string[]>([]);
  const [file, setFile] = useState<File | null>(null)

  function compressImage(file: Blob) {
    getImgCompressed(file).then(file => {
      setFile(new File([file], `${document_user.workflow_id}_${getFieldSignature(user_signature.field_signature)}.png`))
    });
  }

  function getFileUrl(e: any) {
    let file = e.target.files[0];
    if (file) {
      compressImage(file);
      getImageBase64(file, data => {
        addImageToSignature(data);
      })
    }
  }

  useEffect(() => {
    const array = document_user.data_file.split("$(").map(sign => {
      const complement = sign.split(");")[0];
      return complement ? `$(${complement});` : "";
    }).slice(1);

    let prev = document_user.data_file;
    array.forEach(f => prev = prev.replace(f, layout(f)))
    setPreviewDoc(prev);
  }, [document_user]);

  const loadImages = useCallback(async () => {
    let signatures = users.filter(s => s.state);
    let loadImages: any[] = []
    for await (let sig of signatures) {
      const res = await downloadFile(sig.image_key);
      if (res) {
        const resBlob = await downloadFileByUrl(res.url);
        if (resBlob) {
          const base64 = await getImageBase64Async(resBlob.data);
          loadImages.push({...sig, url: base64})
        }
      }
    }
    setPreviewDoc(prevState => {
      loadImages.forEach(sig => prevState = prevState.replace(layout(sig.field_signature),
        getImg(sig.url)))
      return prevState;
    })
  }, [users])

  useEffect(() => {
    loadImages().then();
  }, [loadImages])

  function removeSignature() {
    setPreviewDoc(doc => doc.replace(getImg(imgUrl), layout(user_signature.field_signature)));
    setImgUrl("");
  }


  function addImageToSignature(url: string) {
    const img = getImg(url);
    setImgUrl(url);
    setPreviewDoc(() =>
      previewDoc.replace(imgUrl ? getImg(imgUrl) : layout(user_signature.field_signature), img));
  }

  async function finishAndSaveDoc() {
    setLoading(true);
    const resFile = await downloadFile(document_user.document_key);
    if (resFile.success && file) {
      convertStringToPdf(previewDoc, {name:  resFile.name.replace(".pdf", ""), download: true},
        async (filePdf: File) => {
          const res = await finishAndSaveDocService(
            user_signature,
            document_user,
            filePdf,
            folder_key,
            remaining.length,
            file,
            users);
          if (res.success) {
            toast("Documento firmado.");
            history.push("/documents");
          } else {
            toast("No se pudo actualizar la firma intente nuevamente.", "error");
            setLoading(false);
          }
        })
    }
  }

  return (
    <div className="container-fluid">
      <input type="file" className="d-none" accept="image/*" id="file_signature_detail" onChange={getFileUrl}/>
      <div className="row my-2">
        <div className="col-lg-5 col-md-4 col-12 pt-4">
          <FormGroup className="text-right">
            {imgUrl && <Button color="light"
                               disabled={loading}
                               className="mr-2"
                               onClick={removeSignature}
                               size="sm">
              <FontAwesomeIcon icon={faTimesCircle}/> Remover
            </Button>}
            <Button
              disabled={loading}
              onClick={() => document.getElementById("file_signature_detail")?.click()}
              color="primary"
              size="sm">
              <FontAwesomeIcon
                className="mr-1"
                icon={faUpload}/>
              Subir
            </Button>
          </FormGroup>
          <div className="bg-white">
            {!imgUrl ? (
              <Card>
                <CardHeader>
                  <h5>Firmar el documento</h5>
                </CardHeader>
                <CardBody>
                  <SignatureComponent getImage={urlBase64 => {
                    const nFile = dataURLtoFile(urlBase64, "new file");
                    compressImage(nFile);
                    addImageToSignature(urlBase64);
                  }}/>
                </CardBody>
              </Card>
            ) : (
              <>
                <div className="bg-light p-4">
                  <div className="bg-white shadow p-3">
                    <img src={imgUrl} className="w-75" alt=""/>
                  </div>
                </div>
                <div className="text-right">
                  <button
                    disabled={loading}
                    onClick={finishAndSaveDoc}
                    className="btn btn-info">
                    {loading && <FontAwesomeIcon className="mr-2" icon={faSpinner} spin/>}
                    Guardar y finalizar
                  </button>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="col-lg-7 col-md-8 col-12 py-5 d-flex justify-content-center border border-light bg-light">
          <div className="bg-white rounded border border-light col-12 col-lg-10 shadow p-2 p-lg-5">
            <div dangerouslySetInnerHTML={{__html: previewDoc}}/>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SignatureTemplateComponent;
