import React, {useCallback, useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {actionsDocument} from "../../Store/Document/Slice";
import {RootState} from "../../Store/Reducers";
import {downloadFile} from "../../Utils";
import {actionsAuth} from "../../Store/Auth/Slice";
import {State} from "../../Store/Auth/Types";
import SpinnerComponent from "../../Shared/SpinnerComponent";

const PreviewFileComponent = () => {

  const {file_key} = useParams();
  const [url, setUrl] = useState("");
  const dispatch = useDispatch();
  const [validate, setValidate] = useState(false);
  const token = localStorage.getItem("token");

  const validateSession = useCallback(() => {
    if (token) {
      setValidate(true);
    } else {
      dispatch(actionsAuth.loginAsGuest());
    }
  }, [dispatch, token])

  const {
    documentReducer: {
      currentFile,
      state
    }
  } = useSelector((state: RootState) => state)

  useEffect(() => {
    validateSession();
  }, [validateSession])


  useEffect(() => {
    if (file_key && validate) {
      dispatch(actionsDocument.getFile(file_key))
    }
  }, [file_key, validate, dispatch]);

  useEffect(() => {
    if (currentFile) {
      const nUrl = URL.createObjectURL(currentFile);
      downloadFile(currentFile, currentFile.name);
      setUrl(nUrl);
    }
  }, [currentFile]);

  return (
    <div className="pdf_file_preview">
      <SpinnerComponent className="d-flex justify-content-center align-items-center h-100" loading={state === State.PENDING}/>
      <object
        data={url}
        type="application/pdf"
        width='100%'
        aria-labelledby="file_pdf"
        height='100%'/>
    </div>
  )
}

export default PreviewFileComponent;
